import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'vue-cookies'

import Home from '@/views/Home/Home.vue'
import Transfer from '@/views/Transfer/Transfer'
import FastPass from '@/views/FastPass/FastPass'
import Donat from '@/views/Donat/Donat.vue'
import DonatTest from '@/views/Donat/DonatTest.vue'
import History from '@/views/History/History.vue'
import FAQ from '@/views/FAQ/FAQ.vue'
import Premium from '@/views/Premium/Premium.vue'
import LogPass from '@/views/LogPass/LogPass.vue'
import Apple from '@/views/Apple/Apple.vue'
import VKAuth from '@/views/VKAuth/VKAuth.vue'
import Referal from '@/views/Referal/Referal.vue'
import Suppliers from '@/views/Suppliers/Suppliers.vue'
import SuppliersLogIn from '@/views/SuppliersLogIn/SuppliersLogIn'
import Error404 from '@/views/Error404/Error404'
import News from '@/views/News/News'
import SelectNews from '@/views/SelectNews/SelectNews'
import Calculator from '@/views/Calculator/Calculator'
import GroupMethod from '@/views/GroupMethod/GroupMethod'

import LogPassTest from '@/views/LogPassTest/LogPassTest.vue'
import PremiumTest from '@/views/PremiumTest/Premium.vue'

import Other from '@/views/Other/Other'

import store from './../vuex/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'rbxtree.pro — Сайт дешевых робуксов / робаксов'

const routes = [
  {
    path: '/vkauth',
    name: 'VKAuth',
    component: VKAuth,
    props: (route) => ({ query: route.query.q }),
    meta: {
      auth: -1
    }
  },
  {
    path: '/ref/:code',
    name: 'Referal',
    component: Referal,
    meta: {
      auth: -1
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: -1
    }
  },
  {
    path: '/logpass',
    name: 'LogPass',
    component: LogPassTest,
    meta: {
      auth: -1,
      title: 'RBXTREE - Метод LOG+PASS'
    },
    beforeEnter: (to, from, next) => {
      console.log(navigator.userAgent)
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) store.state.isLogPassMobile = true
      next()
    }
  },
  {
    path: '/premium',
    name: 'Premium',
    component: PremiumTest,
    meta: {
      auth: -1
    },
    beforeEnter: (to, from, next) => {
      console.log(navigator.userAgent)
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) store.state.isPremiumMobile = true
      next()
    }
  },
  {
    path: '/suppliers-test',
    name: 'Suppliers',
    component: Suppliers,
    meta: {
      auth: 1
    }
  },
  {
    path: '/group-method-test',
    name: 'groupMethod',
    component: GroupMethod,
    meta: {
      auth: -1
    }
  },
  {
    path: '/logIn-test',
    name: 'SuppliersLogIn',
    component: SuppliersLogIn,
    meta: {
      auth: 1
    }
  },
  {
    path: '/code',
    name: 'Apple',
    component: Apple,
    meta: {
      auth: -1
    }
  },
  {
    path: '/payment-forbidden',
    name: 'DonatTest',
    component: Donat,
    meta: {
      auth: 1,
      title: 'RBXTREE - Пополнение баланса'
    }
  },
  {
    path: '/payment',
    name: 'Donat',
    component: DonatTest,
    meta: {
      auth: 1,
      title: 'RBXTREE - Пополнение баланса'
    }
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
    meta: {
      auth: -1,
      title: 'RBXTREE - Метод Трансфер'
    }
  },
  {
    path: '/fastpass',
    name: 'FastPass',
    component: FastPass,
    meta: {
      auth: -1,
      title: 'RBXTREE - Метод Fast Pass'
    }
  },
  {
    path: '/logpass-forbidden',
    name: 'LogPassTest',
    component: LogPass,
    meta: {
      auth: -1,
      title: 'RBXTREE - Метод LOG+PASS'
    }
    // beforeEnter: (to, from, next) => {
    //   console.log(navigator.userAgent)
    //   if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) store.state.isLogPassMobile = true
    //   next()
    // }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      auth: 1
    }
  },
  {
    path: '/premium-forbidden',
    name: 'PremiumTest',
    component: Premium,
    meta: {
      auth: -1
    }
    // beforeEnter: (to, from, next) => {
    //   console.log(navigator.userAgent)
    //   if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) store.state.isPremiumMobile = true
    //   next()
    // }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      auth: -1
    }
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: Calculator,
    meta: {
      auth: -1
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Other,
    meta: {
      auth: -1,
      component: 'contacts'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: Other,
    meta: {
      auth: -1,
      component: 'userTerms'
    }
  },
  {
    path: '/privacy',
    name: 'privacyPolicy',
    component: Other,
    meta: {
      auth: -1,
      component: 'PrivacyPolicy'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      auth: -1,
      component: 'News'
    }
  },
  {
    path: '/news/:num',
    name: 'SelectNews',
    component: SelectNews,
    meta: {
      auth: -1,
      component: 'SelectNews'
    }
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      auth: -1
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (localStorage.getItem('apple') === null && to.name !== 'Apple') {
  //   return next('/code')
  // }

  const accessToken = cookie.get('access_token') || store.state.account.accessToken

  if (accessToken && !store.state.account.userId) {
    store.state.syncCompleted = false
    store.dispatch('authorization', accessToken)
  } else {
    store.state.syncCompleted = true
  }

  if (to.meta.auth === 1) {
    if (accessToken) {
      return next()
    } else {
      return next('/')
    }
  } else if (to.meta.auth === -1) {
    return next()
  }

  next('/')
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
