<template>
  <div class="news">
    <AsideMenu />
    <div class="news__wrapper">
      <Navigation />
      <div class="news__wrapper__main">
        <SliderForNews />
        <HotNews v-if="width > 570"/>
        <MiniHotNews v-if="width <= 570"/>
        <AboutRoblox />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import './news.scss'

import AsideMenu from '@/components/asideMenu/AsideMenu'
import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer/Footer'
import SliderForNews from '@/components/SliderForNews/SliderForNews'
import HotNews from '@/components/HotNews/HotNews'
import MiniHotNews from '@/components/MiniHotNews/MiniHotNews'
import AboutRoblox from '@/components/AboutRoblox/AboutRoblox'

export default {
  name: 'News',
  components: {
    AsideMenu,
    Navigation,
    Footer,
    SliderForNews,
    HotNews,
    MiniHotNews,
    AboutRoblox
  },
  metaInfo: {
    title: 'Блог интернет-магазина робкусов «Rbxtree»: полезные статьи и материалы',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Полезные статьи и новости в мире Roblox от «Rbxtree»'
    }]
  },
  data () {
    return {
      width: document.documentElement.clientWidth
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.$forceUpdate()
      this.width = document.documentElement.clientWidth
    })
  }
}
</script>
