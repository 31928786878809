<template>
  <div class="main__form fastpass__wrapper">
    <div v-if="loading.length > 0" class="transfer-loader">
      <Loader />
    </div>
    <div class="hint">
      <div class="hint__border">
        <div class="hint__block">
          <p class="wrong">Внимание!</p>
          <p>Актуальное время ожидания робуксов - 5 дней (120 часов).</p>
        </div>
      </div>
    </div>
    <div class="fastpass__form">
      <div class="fastpass__form-logo">
        <img src="../../../assets/images/transfer/logo.svg" alt="">
      </div>
      <div class="fastpass__form-count">
        <p>В наличии:</p>
        <small>{{ rbxAmount }} R$</small>
      </div>
      <div class="fastpass__form-content">
        <label class="fastpass__form-content__label">кол-во робуксов</label>
        <input
          v-model="totalRobux"
          class="fastpass__form-content__input"
          type="text"
          @input="validatePrice"
          @keyup.enter="changeModal"
        >
        <p
          v-if="error.show === false"
          class="fastpass__form-content__hint"
        >
          Минимум от 50 R$
        </p>
        <p
          v-if="error.show && error.status === false"
          class="fastpass__form-content__error"
          v-html="error.message"
        >
        </p>
        <div class="fastpass__form-content__bg">
          <p>R$</p>
        </div>
        <div class="select">
          <p @click="selectItem(100, $event)" class="select__item" >100 R$</p>
          <p @click="selectItem(250, $event)" class="select__item" >250 R$</p>
          <p @click="selectItem(400, $event)" class="select__item" >400 R$</p>
          <p @click="selectItem(600, $event)" class="select__item" >600 R$</p>
        </div>
      </div>
      <button
        class="fastpass__form-btn"
        @click="changeModal"
      >
        Продолжить
      </button>
      <div class="fastpass__form-footer">
        <div class="count">
          <p>итог: <span class="green">{{ totalRobuxText }} R$</span></p>
        </div>
        <div class="total">
          <p >к оплате: <span class="green">{{ rubTotal.toFixed(2) }} ₽</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/LoaderBar/LoaderBar.vue'

import './mainForm.scss'

export default {
  name: 'FastPassMainForm',
  components: {
    Loader
  },
  data () {
    return {
      totalRobux: 600,
      totalRobuxText: '600.00',
      rubTotal: 20,
      beforeRate: 0.4,
      rbxAmount: null,
      error: {
        status: false,
        show: false,
        message: ''
      },
      loading: []
    }
  },
  created () {
    const userId = localStorage.getItem('user_id') || 'default'

    this.loading.push(true)
    this.loading.push(true)

    fetch(`https://rates.backend-tree.com/get_rates/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        this.beforeRate = data.before.rate
        this.rubTotal = this.totalRobux * this.beforeRate
        this.loading.pop(true)
      })
      .catch((err) => console.log(err))

    fetch('https://pending.backend-tree.com/in_stock')
      .then((res) => res.json())
      .then((data) => {
        this.loading.pop(true)
        if (data.status) {
          this.rbxAmount = data.robux_amount
          this.validatePrice()
        }
      })
      .catch((err) => console.log(err))
  },
  methods: {
    changeModal () {
      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      this.$emit('changeModal', {
        modal: 'CharactersForm',
        data: [this.totalRobux, this.rubTotal]
      })
    },
    selectItem (price, event) {
      const target = event.target

      const selected = document.querySelector('.select__item.sel')
      if (selected) {
        selected.classList.remove('sel')
      }

      this.totalRobux = Number(price)
      this.totalRobuxText = Number(price).toFixed(2)
      this.rubTotal = this.totalRobux * this.beforeRate

      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      target.classList.add('sel')
    },
    validatePrice (event) {
      if (isNaN(this.totalRobux)) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Цена должна быть числом'

        return
      } else if (this.totalRobux < 50) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Нельзя заказать менее 50 робаксов'

        return
      } else if (this.totalRobux > (this.rbxAmount / 2)) {
        this.error.status = false
        this.error.show = true
        this.error.message = `Нельзя заказать больше половины баланса: ${(this.rbxAmount / 2).toFixed(2)} робаксов`

        return
      } else if (this.totalRobux > 9000) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'The maximum purchase amount is temporarily limited to 9k R$'

        return
      }

      this.error.status = true
      this.error.show = false
      this.error.message = ''

      this.rubTotal = Number((this.totalRobux * this.beforeRate).toFixed(2))
      if (event) {
        this.totalRobuxText = parseFloat(event.target.value).toFixed(2)
      }
    },
    isInteger (num) {
      return (num ^ 0) === num
    }
  }
  // mounted () {
  //   const hint = document.querySelector('.main__form.fastpass__wrapper .hint')

  //   if (this.loading.length > 0) {
  //     setTimeout(() => {
  //       if (document.body.clientWidth > 1505) hint.style.right = '0'
  //       if (document.body.clientWidth <= 1505) hint.style.bottom = '0'
  //     }, 500)
  //   }
  // }
}
</script>
