<template>
  <div class="contact-block">
    <h1 class="contact-block__title">Контакты</h1>
    <p v-if="!isCountryFromRu($store.state.ipCountry)">
    </p>
    <div class="contact-block__block">
      <a class="contact-block__block-link l-web" href="https://rbxtree.pro/">rbxtree.pro</a>
      <a class="contact-block__block-link l-email" href="mailto:rbxtree@gmail.com">rbxtree@gmail.com</a>
      <a class="contact-block__block-link l-vk" href="https://vk.com/rbxtree">vk.com/rbxtree</a>
    </div>
    <img v-if="isCountryRu($store.state.ipCountry)" class="footer-address" src="../../assets/images/footer/address.svg" alt="">
  </div>
</template>

<script>
import './contact.scss'
import ipUtil from './../../utils/ip'

export default {
  name: 'Contacts',
  metaInfo: {
    title: 'Контактные данные магазина робуксов «RBXTREE»',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Группа «Вконтакте», Discord-сервер магазина дешевых робуксов «RBXTREE».'
    }]
  },
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    },
    isCountryRu (c) {
      return ipUtil.isCountryRu(c)
    }
  }
}
</script>
