<template>
  <div
    v-if="$store.state.showPreloader || $store.state.syncCompleted === false || $store.state.additionalSync.length !== 0"
    class="preloader"
  >
    <div
      class="preloader__loader"
    >
      <div class="preloader__loader__bar"></div>
      <div class="preloader__loader__bar"></div>
      <div class="preloader__loader__bar"></div>
    </div>
  </div>
</template>

<script>
import './preloader.scss'

export default {
  name: 'PreLoader'
}
</script>
