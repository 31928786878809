<template>
  <div class="recomendednews">
    <h3 class="recomendednews__title">РЕКОМЕНДУЕМ К ПРОЧТЕНИЮ</h3>
    <div class="recomendednews__wrapper">
      <div class="recomendednews__blocks" id="wrap">
        <div class="recomendednews__blocks__block">
          <img src="../../assets/images/news/main.png" alt="">
          <p class="recomendednews__blocks__block__title">Светлый лик правового взаимодействия связывает нас с нашим прошлым</p>
          <div class="recomendednews__blocks__block__states">
            <div class="states">
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1875 8.5C19.8948 8.14859 19.5285 7.86571 19.1146 7.67132C18.7006 7.47693 18.249 7.37578 17.7916 7.375H13.0416L13.625 5.88541C13.8676 5.23321 13.9484 4.53188 13.8605 3.84158C13.7725 3.15128 13.5184 2.49263 13.12 1.92211C12.7215 1.35159 12.1906 0.886242 11.5728 0.565978C10.955 0.245714 10.2688 0.0800956 9.5729 0.0833289C9.37252 0.083747 9.17652 0.141949 9.00839 0.250957C8.84026 0.359965 8.70713 0.515152 8.62498 0.697912L5.65623 7.375H3.20831C2.37951 7.375 1.58466 7.70424 0.998604 8.29029C0.412553 8.87634 0.083313 9.6712 0.083313 10.5V17.7917C0.083313 18.6205 0.412553 19.4153 0.998604 20.0014C1.58466 20.5874 2.37951 20.9167 3.20831 20.9167H16.4687C17.1998 20.9164 17.9076 20.6599 18.4691 20.1917C19.0305 19.7235 19.4101 19.0733 19.5416 18.3542L20.8646 11.0625C20.9464 10.6118 20.9282 10.1487 20.8111 9.70591C20.6941 9.26309 20.4812 8.85141 20.1875 8.5ZM5.29165 18.8333H3.20831C2.93205 18.8333 2.66709 18.7236 2.47174 18.5282C2.27639 18.3329 2.16665 18.0679 2.16665 17.7917V10.5C2.16665 10.2237 2.27639 9.95878 2.47174 9.76343C2.66709 9.56808 2.93205 9.45833 3.20831 9.45833H5.29165V18.8333ZM18.8333 10.6875L17.5104 17.9792C17.466 18.2218 17.337 18.4409 17.1462 18.5973C16.9555 18.7537 16.7154 18.8374 16.4687 18.8333H7.37498V8.63541L10.2083 2.26041C10.5 2.34544 10.7708 2.49 11.0038 2.68498C11.2368 2.87995 11.4268 3.12111 11.5619 3.39322C11.6969 3.66533 11.7741 3.96249 11.7886 4.26593C11.803 4.56938 11.7544 4.87253 11.6458 5.15625L11.0937 6.64583C10.9761 6.96068 10.9364 7.29932 10.978 7.63284C11.0196 7.96637 11.1412 8.28487 11.3326 8.56118C11.524 8.83749 11.7794 9.0634 12.077 9.21962C12.3746 9.37584 12.7055 9.45774 13.0416 9.45833H17.7916C17.9447 9.45808 18.0959 9.49155 18.2345 9.55637C18.3731 9.62118 18.4958 9.71574 18.5937 9.83333C18.6941 9.94929 18.7676 10.086 18.809 10.2336C18.8504 10.3813 18.8587 10.5363 18.8333 10.6875Z" fill="#2F2F2F"/>
                </svg>
                <p>657</p>
              </div>
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5416 9.45834H5.29165C5.01538 9.45834 4.75043 9.56809 4.55508 9.76344C4.35973 9.95879 4.24998 10.2237 4.24998 10.5C4.24998 10.7763 4.35973 11.0412 4.55508 11.2366C4.75043 11.4319 5.01538 11.5417 5.29165 11.5417H11.5416C11.8179 11.5417 12.0829 11.4319 12.2782 11.2366C12.4736 11.0412 12.5833 10.7763 12.5833 10.5C12.5833 10.2237 12.4736 9.95879 12.2782 9.76344C12.0829 9.56809 11.8179 9.45834 11.5416 9.45834ZM15.7083 5.29168H5.29165C5.01538 5.29168 4.75043 5.40142 4.55508 5.59677C4.35973 5.79212 4.24998 6.05708 4.24998 6.33334C4.24998 6.60961 4.35973 6.87456 4.55508 7.06991C4.75043 7.26526 5.01538 7.37501 5.29165 7.37501H15.7083C15.9846 7.37501 16.2495 7.26526 16.4449 7.06991C16.6402 6.87456 16.75 6.60961 16.75 6.33334C16.75 6.05708 16.6402 5.79212 16.4449 5.59677C16.2495 5.40142 15.9846 5.29168 15.7083 5.29168ZM17.7916 0.0833435H3.20831C2.37951 0.0833435 1.58466 0.412584 0.998604 0.998635C0.412553 1.58469 0.083313 2.37954 0.083313 3.20834V13.625C0.083313 14.4538 0.412553 15.2487 0.998604 15.8347C1.58466 16.4208 2.37951 16.75 3.20831 16.75H15.2812L19.1354 20.6146C19.2327 20.7111 19.3482 20.7875 19.4751 20.8394C19.602 20.8912 19.7379 20.9175 19.875 20.9167C20.0116 20.9202 20.1472 20.8917 20.2708 20.8333C20.461 20.7552 20.6239 20.6225 20.7388 20.4519C20.8537 20.2814 20.9156 20.0807 20.9166 19.875V3.20834C20.9166 2.37954 20.5874 1.58469 20.0014 0.998635C19.4153 0.412584 18.6204 0.0833435 17.7916 0.0833435ZM18.8333 17.3646L16.4479 14.9688C16.3506 14.8722 16.2351 14.7958 16.1082 14.744C15.9813 14.6922 15.8454 14.6659 15.7083 14.6667H3.20831C2.93205 14.6667 2.66709 14.5569 2.47174 14.3616C2.27639 14.1662 2.16665 13.9013 2.16665 13.625V3.20834C2.16665 2.93208 2.27639 2.66712 2.47174 2.47177C2.66709 2.27642 2.93205 2.16668 3.20831 2.16668H17.7916C18.0679 2.16668 18.3329 2.27642 18.5282 2.47177C18.7236 2.66712 18.8333 2.93208 18.8333 3.20834V17.3646Z" fill="#2F2F2F"/>
                </svg>
                <p>26</p>
              </div>
              <div class="state">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8333 8.08332C18.7292 3.19791 14.7708 0.166656 10.5 0.166656C6.22918 0.166656 2.27084 3.19791 0.166675 8.08332C0.109318 8.21474 0.0797119 8.35659 0.0797119 8.49999C0.0797119 8.64338 0.109318 8.78523 0.166675 8.91665C2.27084 13.8021 6.22918 16.8333 10.5 16.8333C14.7708 16.8333 18.7292 13.8021 20.8333 8.91665C20.8907 8.78523 20.9203 8.64338 20.9203 8.49999C20.9203 8.35659 20.8907 8.21474 20.8333 8.08332ZM10.5 14.75C7.19792 14.75 4.07292 12.3646 2.27084 8.49999C4.07292 4.63541 7.19792 2.24999 10.5 2.24999C13.8021 2.24999 16.9271 4.63541 18.7292 8.49999C16.9271 12.3646 13.8021 14.75 10.5 14.75ZM10.5 4.33332C9.67592 4.33332 8.87034 4.57769 8.18513 5.03553C7.49993 5.49337 6.96588 6.14411 6.65051 6.90547C6.33514 7.66683 6.25263 8.50461 6.4134 9.31286C6.57417 10.1211 6.97101 10.8635 7.55373 11.4463C8.13645 12.029 8.87888 12.4258 9.68713 12.5866C10.4954 12.7474 11.3332 12.6649 12.0945 12.3495C12.8559 12.0341 13.5066 11.5001 13.9645 10.8149C14.4223 10.1297 14.6667 9.32408 14.6667 8.49999C14.6667 7.39492 14.2277 6.33511 13.4463 5.55371C12.6649 4.77231 11.6051 4.33332 10.5 4.33332ZM10.5 10.5833C10.088 10.5833 9.68517 10.4611 9.34257 10.2322C8.99997 10.0033 8.73294 9.67792 8.57526 9.29724C8.41758 8.91656 8.37632 8.49768 8.45671 8.09355C8.53709 7.68942 8.73551 7.31821 9.02687 7.02685C9.31823 6.73549 9.68944 6.53707 10.0936 6.45669C10.4977 6.3763 10.9166 6.41756 11.2973 6.57524C11.6779 6.73292 12.0033 6.99995 12.2322 7.34255C12.4612 7.68515 12.5833 8.08794 12.5833 8.49999C12.5833 9.05252 12.3638 9.58243 11.9731 9.97313C11.5824 10.3638 11.0525 10.5833 10.5 10.5833Z" fill="#2F2F2F"/>
                </svg>
                <p>1606</p>
              </div>
            </div>
            <div class="date">21.03.2002</div>
          </div>
        </div>
        <div class="recomendednews__blocks__block">
          <img src="../../assets/images/news/main.png" alt="">
          <p class="recomendednews__blocks__block__title">Светлый лик правового взаимодействия связывает нас с нашим прошлым</p>
          <div class="recomendednews__blocks__block__states">
            <div class="states">
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1875 8.5C19.8948 8.14859 19.5285 7.86571 19.1146 7.67132C18.7006 7.47693 18.249 7.37578 17.7916 7.375H13.0416L13.625 5.88541C13.8676 5.23321 13.9484 4.53188 13.8605 3.84158C13.7725 3.15128 13.5184 2.49263 13.12 1.92211C12.7215 1.35159 12.1906 0.886242 11.5728 0.565978C10.955 0.245714 10.2688 0.0800956 9.5729 0.0833289C9.37252 0.083747 9.17652 0.141949 9.00839 0.250957C8.84026 0.359965 8.70713 0.515152 8.62498 0.697912L5.65623 7.375H3.20831C2.37951 7.375 1.58466 7.70424 0.998604 8.29029C0.412553 8.87634 0.083313 9.6712 0.083313 10.5V17.7917C0.083313 18.6205 0.412553 19.4153 0.998604 20.0014C1.58466 20.5874 2.37951 20.9167 3.20831 20.9167H16.4687C17.1998 20.9164 17.9076 20.6599 18.4691 20.1917C19.0305 19.7235 19.4101 19.0733 19.5416 18.3542L20.8646 11.0625C20.9464 10.6118 20.9282 10.1487 20.8111 9.70591C20.6941 9.26309 20.4812 8.85141 20.1875 8.5ZM5.29165 18.8333H3.20831C2.93205 18.8333 2.66709 18.7236 2.47174 18.5282C2.27639 18.3329 2.16665 18.0679 2.16665 17.7917V10.5C2.16665 10.2237 2.27639 9.95878 2.47174 9.76343C2.66709 9.56808 2.93205 9.45833 3.20831 9.45833H5.29165V18.8333ZM18.8333 10.6875L17.5104 17.9792C17.466 18.2218 17.337 18.4409 17.1462 18.5973C16.9555 18.7537 16.7154 18.8374 16.4687 18.8333H7.37498V8.63541L10.2083 2.26041C10.5 2.34544 10.7708 2.49 11.0038 2.68498C11.2368 2.87995 11.4268 3.12111 11.5619 3.39322C11.6969 3.66533 11.7741 3.96249 11.7886 4.26593C11.803 4.56938 11.7544 4.87253 11.6458 5.15625L11.0937 6.64583C10.9761 6.96068 10.9364 7.29932 10.978 7.63284C11.0196 7.96637 11.1412 8.28487 11.3326 8.56118C11.524 8.83749 11.7794 9.0634 12.077 9.21962C12.3746 9.37584 12.7055 9.45774 13.0416 9.45833H17.7916C17.9447 9.45808 18.0959 9.49155 18.2345 9.55637C18.3731 9.62118 18.4958 9.71574 18.5937 9.83333C18.6941 9.94929 18.7676 10.086 18.809 10.2336C18.8504 10.3813 18.8587 10.5363 18.8333 10.6875Z" fill="#2F2F2F"/>
                </svg>
                <p>657</p>
              </div>
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5416 9.45834H5.29165C5.01538 9.45834 4.75043 9.56809 4.55508 9.76344C4.35973 9.95879 4.24998 10.2237 4.24998 10.5C4.24998 10.7763 4.35973 11.0412 4.55508 11.2366C4.75043 11.4319 5.01538 11.5417 5.29165 11.5417H11.5416C11.8179 11.5417 12.0829 11.4319 12.2782 11.2366C12.4736 11.0412 12.5833 10.7763 12.5833 10.5C12.5833 10.2237 12.4736 9.95879 12.2782 9.76344C12.0829 9.56809 11.8179 9.45834 11.5416 9.45834ZM15.7083 5.29168H5.29165C5.01538 5.29168 4.75043 5.40142 4.55508 5.59677C4.35973 5.79212 4.24998 6.05708 4.24998 6.33334C4.24998 6.60961 4.35973 6.87456 4.55508 7.06991C4.75043 7.26526 5.01538 7.37501 5.29165 7.37501H15.7083C15.9846 7.37501 16.2495 7.26526 16.4449 7.06991C16.6402 6.87456 16.75 6.60961 16.75 6.33334C16.75 6.05708 16.6402 5.79212 16.4449 5.59677C16.2495 5.40142 15.9846 5.29168 15.7083 5.29168ZM17.7916 0.0833435H3.20831C2.37951 0.0833435 1.58466 0.412584 0.998604 0.998635C0.412553 1.58469 0.083313 2.37954 0.083313 3.20834V13.625C0.083313 14.4538 0.412553 15.2487 0.998604 15.8347C1.58466 16.4208 2.37951 16.75 3.20831 16.75H15.2812L19.1354 20.6146C19.2327 20.7111 19.3482 20.7875 19.4751 20.8394C19.602 20.8912 19.7379 20.9175 19.875 20.9167C20.0116 20.9202 20.1472 20.8917 20.2708 20.8333C20.461 20.7552 20.6239 20.6225 20.7388 20.4519C20.8537 20.2814 20.9156 20.0807 20.9166 19.875V3.20834C20.9166 2.37954 20.5874 1.58469 20.0014 0.998635C19.4153 0.412584 18.6204 0.0833435 17.7916 0.0833435ZM18.8333 17.3646L16.4479 14.9688C16.3506 14.8722 16.2351 14.7958 16.1082 14.744C15.9813 14.6922 15.8454 14.6659 15.7083 14.6667H3.20831C2.93205 14.6667 2.66709 14.5569 2.47174 14.3616C2.27639 14.1662 2.16665 13.9013 2.16665 13.625V3.20834C2.16665 2.93208 2.27639 2.66712 2.47174 2.47177C2.66709 2.27642 2.93205 2.16668 3.20831 2.16668H17.7916C18.0679 2.16668 18.3329 2.27642 18.5282 2.47177C18.7236 2.66712 18.8333 2.93208 18.8333 3.20834V17.3646Z" fill="#2F2F2F"/>
                </svg>
                <p>26</p>
              </div>
              <div class="state">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8333 8.08332C18.7292 3.19791 14.7708 0.166656 10.5 0.166656C6.22918 0.166656 2.27084 3.19791 0.166675 8.08332C0.109318 8.21474 0.0797119 8.35659 0.0797119 8.49999C0.0797119 8.64338 0.109318 8.78523 0.166675 8.91665C2.27084 13.8021 6.22918 16.8333 10.5 16.8333C14.7708 16.8333 18.7292 13.8021 20.8333 8.91665C20.8907 8.78523 20.9203 8.64338 20.9203 8.49999C20.9203 8.35659 20.8907 8.21474 20.8333 8.08332ZM10.5 14.75C7.19792 14.75 4.07292 12.3646 2.27084 8.49999C4.07292 4.63541 7.19792 2.24999 10.5 2.24999C13.8021 2.24999 16.9271 4.63541 18.7292 8.49999C16.9271 12.3646 13.8021 14.75 10.5 14.75ZM10.5 4.33332C9.67592 4.33332 8.87034 4.57769 8.18513 5.03553C7.49993 5.49337 6.96588 6.14411 6.65051 6.90547C6.33514 7.66683 6.25263 8.50461 6.4134 9.31286C6.57417 10.1211 6.97101 10.8635 7.55373 11.4463C8.13645 12.029 8.87888 12.4258 9.68713 12.5866C10.4954 12.7474 11.3332 12.6649 12.0945 12.3495C12.8559 12.0341 13.5066 11.5001 13.9645 10.8149C14.4223 10.1297 14.6667 9.32408 14.6667 8.49999C14.6667 7.39492 14.2277 6.33511 13.4463 5.55371C12.6649 4.77231 11.6051 4.33332 10.5 4.33332ZM10.5 10.5833C10.088 10.5833 9.68517 10.4611 9.34257 10.2322C8.99997 10.0033 8.73294 9.67792 8.57526 9.29724C8.41758 8.91656 8.37632 8.49768 8.45671 8.09355C8.53709 7.68942 8.73551 7.31821 9.02687 7.02685C9.31823 6.73549 9.68944 6.53707 10.0936 6.45669C10.4977 6.3763 10.9166 6.41756 11.2973 6.57524C11.6779 6.73292 12.0033 6.99995 12.2322 7.34255C12.4612 7.68515 12.5833 8.08794 12.5833 8.49999C12.5833 9.05252 12.3638 9.58243 11.9731 9.97313C11.5824 10.3638 11.0525 10.5833 10.5 10.5833Z" fill="#2F2F2F"/>
                </svg>
                <p>1606</p>
              </div>
            </div>
            <div class="date">21.03.2002</div>
          </div>
        </div>
        <div class="recomendednews__blocks__block">
          <img src="../../assets/images/news/main.png" alt="">
          <p class="recomendednews__blocks__block__title">Светлый лик правового взаимодействия связывает нас с нашим прошлым</p>
          <div class="recomendednews__blocks__block__states">
            <div class="states">
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1875 8.5C19.8948 8.14859 19.5285 7.86571 19.1146 7.67132C18.7006 7.47693 18.249 7.37578 17.7916 7.375H13.0416L13.625 5.88541C13.8676 5.23321 13.9484 4.53188 13.8605 3.84158C13.7725 3.15128 13.5184 2.49263 13.12 1.92211C12.7215 1.35159 12.1906 0.886242 11.5728 0.565978C10.955 0.245714 10.2688 0.0800956 9.5729 0.0833289C9.37252 0.083747 9.17652 0.141949 9.00839 0.250957C8.84026 0.359965 8.70713 0.515152 8.62498 0.697912L5.65623 7.375H3.20831C2.37951 7.375 1.58466 7.70424 0.998604 8.29029C0.412553 8.87634 0.083313 9.6712 0.083313 10.5V17.7917C0.083313 18.6205 0.412553 19.4153 0.998604 20.0014C1.58466 20.5874 2.37951 20.9167 3.20831 20.9167H16.4687C17.1998 20.9164 17.9076 20.6599 18.4691 20.1917C19.0305 19.7235 19.4101 19.0733 19.5416 18.3542L20.8646 11.0625C20.9464 10.6118 20.9282 10.1487 20.8111 9.70591C20.6941 9.26309 20.4812 8.85141 20.1875 8.5ZM5.29165 18.8333H3.20831C2.93205 18.8333 2.66709 18.7236 2.47174 18.5282C2.27639 18.3329 2.16665 18.0679 2.16665 17.7917V10.5C2.16665 10.2237 2.27639 9.95878 2.47174 9.76343C2.66709 9.56808 2.93205 9.45833 3.20831 9.45833H5.29165V18.8333ZM18.8333 10.6875L17.5104 17.9792C17.466 18.2218 17.337 18.4409 17.1462 18.5973C16.9555 18.7537 16.7154 18.8374 16.4687 18.8333H7.37498V8.63541L10.2083 2.26041C10.5 2.34544 10.7708 2.49 11.0038 2.68498C11.2368 2.87995 11.4268 3.12111 11.5619 3.39322C11.6969 3.66533 11.7741 3.96249 11.7886 4.26593C11.803 4.56938 11.7544 4.87253 11.6458 5.15625L11.0937 6.64583C10.9761 6.96068 10.9364 7.29932 10.978 7.63284C11.0196 7.96637 11.1412 8.28487 11.3326 8.56118C11.524 8.83749 11.7794 9.0634 12.077 9.21962C12.3746 9.37584 12.7055 9.45774 13.0416 9.45833H17.7916C17.9447 9.45808 18.0959 9.49155 18.2345 9.55637C18.3731 9.62118 18.4958 9.71574 18.5937 9.83333C18.6941 9.94929 18.7676 10.086 18.809 10.2336C18.8504 10.3813 18.8587 10.5363 18.8333 10.6875Z" fill="#2F2F2F"/>
                </svg>
                <p>657</p>
              </div>
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5416 9.45834H5.29165C5.01538 9.45834 4.75043 9.56809 4.55508 9.76344C4.35973 9.95879 4.24998 10.2237 4.24998 10.5C4.24998 10.7763 4.35973 11.0412 4.55508 11.2366C4.75043 11.4319 5.01538 11.5417 5.29165 11.5417H11.5416C11.8179 11.5417 12.0829 11.4319 12.2782 11.2366C12.4736 11.0412 12.5833 10.7763 12.5833 10.5C12.5833 10.2237 12.4736 9.95879 12.2782 9.76344C12.0829 9.56809 11.8179 9.45834 11.5416 9.45834ZM15.7083 5.29168H5.29165C5.01538 5.29168 4.75043 5.40142 4.55508 5.59677C4.35973 5.79212 4.24998 6.05708 4.24998 6.33334C4.24998 6.60961 4.35973 6.87456 4.55508 7.06991C4.75043 7.26526 5.01538 7.37501 5.29165 7.37501H15.7083C15.9846 7.37501 16.2495 7.26526 16.4449 7.06991C16.6402 6.87456 16.75 6.60961 16.75 6.33334C16.75 6.05708 16.6402 5.79212 16.4449 5.59677C16.2495 5.40142 15.9846 5.29168 15.7083 5.29168ZM17.7916 0.0833435H3.20831C2.37951 0.0833435 1.58466 0.412584 0.998604 0.998635C0.412553 1.58469 0.083313 2.37954 0.083313 3.20834V13.625C0.083313 14.4538 0.412553 15.2487 0.998604 15.8347C1.58466 16.4208 2.37951 16.75 3.20831 16.75H15.2812L19.1354 20.6146C19.2327 20.7111 19.3482 20.7875 19.4751 20.8394C19.602 20.8912 19.7379 20.9175 19.875 20.9167C20.0116 20.9202 20.1472 20.8917 20.2708 20.8333C20.461 20.7552 20.6239 20.6225 20.7388 20.4519C20.8537 20.2814 20.9156 20.0807 20.9166 19.875V3.20834C20.9166 2.37954 20.5874 1.58469 20.0014 0.998635C19.4153 0.412584 18.6204 0.0833435 17.7916 0.0833435ZM18.8333 17.3646L16.4479 14.9688C16.3506 14.8722 16.2351 14.7958 16.1082 14.744C15.9813 14.6922 15.8454 14.6659 15.7083 14.6667H3.20831C2.93205 14.6667 2.66709 14.5569 2.47174 14.3616C2.27639 14.1662 2.16665 13.9013 2.16665 13.625V3.20834C2.16665 2.93208 2.27639 2.66712 2.47174 2.47177C2.66709 2.27642 2.93205 2.16668 3.20831 2.16668H17.7916C18.0679 2.16668 18.3329 2.27642 18.5282 2.47177C18.7236 2.66712 18.8333 2.93208 18.8333 3.20834V17.3646Z" fill="#2F2F2F"/>
                </svg>
                <p>26</p>
              </div>
              <div class="state">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8333 8.08332C18.7292 3.19791 14.7708 0.166656 10.5 0.166656C6.22918 0.166656 2.27084 3.19791 0.166675 8.08332C0.109318 8.21474 0.0797119 8.35659 0.0797119 8.49999C0.0797119 8.64338 0.109318 8.78523 0.166675 8.91665C2.27084 13.8021 6.22918 16.8333 10.5 16.8333C14.7708 16.8333 18.7292 13.8021 20.8333 8.91665C20.8907 8.78523 20.9203 8.64338 20.9203 8.49999C20.9203 8.35659 20.8907 8.21474 20.8333 8.08332ZM10.5 14.75C7.19792 14.75 4.07292 12.3646 2.27084 8.49999C4.07292 4.63541 7.19792 2.24999 10.5 2.24999C13.8021 2.24999 16.9271 4.63541 18.7292 8.49999C16.9271 12.3646 13.8021 14.75 10.5 14.75ZM10.5 4.33332C9.67592 4.33332 8.87034 4.57769 8.18513 5.03553C7.49993 5.49337 6.96588 6.14411 6.65051 6.90547C6.33514 7.66683 6.25263 8.50461 6.4134 9.31286C6.57417 10.1211 6.97101 10.8635 7.55373 11.4463C8.13645 12.029 8.87888 12.4258 9.68713 12.5866C10.4954 12.7474 11.3332 12.6649 12.0945 12.3495C12.8559 12.0341 13.5066 11.5001 13.9645 10.8149C14.4223 10.1297 14.6667 9.32408 14.6667 8.49999C14.6667 7.39492 14.2277 6.33511 13.4463 5.55371C12.6649 4.77231 11.6051 4.33332 10.5 4.33332ZM10.5 10.5833C10.088 10.5833 9.68517 10.4611 9.34257 10.2322C8.99997 10.0033 8.73294 9.67792 8.57526 9.29724C8.41758 8.91656 8.37632 8.49768 8.45671 8.09355C8.53709 7.68942 8.73551 7.31821 9.02687 7.02685C9.31823 6.73549 9.68944 6.53707 10.0936 6.45669C10.4977 6.3763 10.9166 6.41756 11.2973 6.57524C11.6779 6.73292 12.0033 6.99995 12.2322 7.34255C12.4612 7.68515 12.5833 8.08794 12.5833 8.49999C12.5833 9.05252 12.3638 9.58243 11.9731 9.97313C11.5824 10.3638 11.0525 10.5833 10.5 10.5833Z" fill="#2F2F2F"/>
                </svg>
                <p>1606</p>
              </div>
            </div>
            <div class="date">21.03.2002</div>
          </div>
        </div>
        <div class="recomendednews__blocks__block">
          <img src="../../assets/images/news/main.png" alt="">
          <p class="recomendednews__blocks__block__title">Светлый лик правового взаимодействия связывает нас с нашим прошлым</p>
          <div class="recomendednews__blocks__block__states">
            <div class="states">
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1875 8.5C19.8948 8.14859 19.5285 7.86571 19.1146 7.67132C18.7006 7.47693 18.249 7.37578 17.7916 7.375H13.0416L13.625 5.88541C13.8676 5.23321 13.9484 4.53188 13.8605 3.84158C13.7725 3.15128 13.5184 2.49263 13.12 1.92211C12.7215 1.35159 12.1906 0.886242 11.5728 0.565978C10.955 0.245714 10.2688 0.0800956 9.5729 0.0833289C9.37252 0.083747 9.17652 0.141949 9.00839 0.250957C8.84026 0.359965 8.70713 0.515152 8.62498 0.697912L5.65623 7.375H3.20831C2.37951 7.375 1.58466 7.70424 0.998604 8.29029C0.412553 8.87634 0.083313 9.6712 0.083313 10.5V17.7917C0.083313 18.6205 0.412553 19.4153 0.998604 20.0014C1.58466 20.5874 2.37951 20.9167 3.20831 20.9167H16.4687C17.1998 20.9164 17.9076 20.6599 18.4691 20.1917C19.0305 19.7235 19.4101 19.0733 19.5416 18.3542L20.8646 11.0625C20.9464 10.6118 20.9282 10.1487 20.8111 9.70591C20.6941 9.26309 20.4812 8.85141 20.1875 8.5ZM5.29165 18.8333H3.20831C2.93205 18.8333 2.66709 18.7236 2.47174 18.5282C2.27639 18.3329 2.16665 18.0679 2.16665 17.7917V10.5C2.16665 10.2237 2.27639 9.95878 2.47174 9.76343C2.66709 9.56808 2.93205 9.45833 3.20831 9.45833H5.29165V18.8333ZM18.8333 10.6875L17.5104 17.9792C17.466 18.2218 17.337 18.4409 17.1462 18.5973C16.9555 18.7537 16.7154 18.8374 16.4687 18.8333H7.37498V8.63541L10.2083 2.26041C10.5 2.34544 10.7708 2.49 11.0038 2.68498C11.2368 2.87995 11.4268 3.12111 11.5619 3.39322C11.6969 3.66533 11.7741 3.96249 11.7886 4.26593C11.803 4.56938 11.7544 4.87253 11.6458 5.15625L11.0937 6.64583C10.9761 6.96068 10.9364 7.29932 10.978 7.63284C11.0196 7.96637 11.1412 8.28487 11.3326 8.56118C11.524 8.83749 11.7794 9.0634 12.077 9.21962C12.3746 9.37584 12.7055 9.45774 13.0416 9.45833H17.7916C17.9447 9.45808 18.0959 9.49155 18.2345 9.55637C18.3731 9.62118 18.4958 9.71574 18.5937 9.83333C18.6941 9.94929 18.7676 10.086 18.809 10.2336C18.8504 10.3813 18.8587 10.5363 18.8333 10.6875Z" fill="#2F2F2F"/>
                </svg>
                <p>657</p>
              </div>
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5416 9.45834H5.29165C5.01538 9.45834 4.75043 9.56809 4.55508 9.76344C4.35973 9.95879 4.24998 10.2237 4.24998 10.5C4.24998 10.7763 4.35973 11.0412 4.55508 11.2366C4.75043 11.4319 5.01538 11.5417 5.29165 11.5417H11.5416C11.8179 11.5417 12.0829 11.4319 12.2782 11.2366C12.4736 11.0412 12.5833 10.7763 12.5833 10.5C12.5833 10.2237 12.4736 9.95879 12.2782 9.76344C12.0829 9.56809 11.8179 9.45834 11.5416 9.45834ZM15.7083 5.29168H5.29165C5.01538 5.29168 4.75043 5.40142 4.55508 5.59677C4.35973 5.79212 4.24998 6.05708 4.24998 6.33334C4.24998 6.60961 4.35973 6.87456 4.55508 7.06991C4.75043 7.26526 5.01538 7.37501 5.29165 7.37501H15.7083C15.9846 7.37501 16.2495 7.26526 16.4449 7.06991C16.6402 6.87456 16.75 6.60961 16.75 6.33334C16.75 6.05708 16.6402 5.79212 16.4449 5.59677C16.2495 5.40142 15.9846 5.29168 15.7083 5.29168ZM17.7916 0.0833435H3.20831C2.37951 0.0833435 1.58466 0.412584 0.998604 0.998635C0.412553 1.58469 0.083313 2.37954 0.083313 3.20834V13.625C0.083313 14.4538 0.412553 15.2487 0.998604 15.8347C1.58466 16.4208 2.37951 16.75 3.20831 16.75H15.2812L19.1354 20.6146C19.2327 20.7111 19.3482 20.7875 19.4751 20.8394C19.602 20.8912 19.7379 20.9175 19.875 20.9167C20.0116 20.9202 20.1472 20.8917 20.2708 20.8333C20.461 20.7552 20.6239 20.6225 20.7388 20.4519C20.8537 20.2814 20.9156 20.0807 20.9166 19.875V3.20834C20.9166 2.37954 20.5874 1.58469 20.0014 0.998635C19.4153 0.412584 18.6204 0.0833435 17.7916 0.0833435ZM18.8333 17.3646L16.4479 14.9688C16.3506 14.8722 16.2351 14.7958 16.1082 14.744C15.9813 14.6922 15.8454 14.6659 15.7083 14.6667H3.20831C2.93205 14.6667 2.66709 14.5569 2.47174 14.3616C2.27639 14.1662 2.16665 13.9013 2.16665 13.625V3.20834C2.16665 2.93208 2.27639 2.66712 2.47174 2.47177C2.66709 2.27642 2.93205 2.16668 3.20831 2.16668H17.7916C18.0679 2.16668 18.3329 2.27642 18.5282 2.47177C18.7236 2.66712 18.8333 2.93208 18.8333 3.20834V17.3646Z" fill="#2F2F2F"/>
                </svg>
                <p>26</p>
              </div>
              <div class="state">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8333 8.08332C18.7292 3.19791 14.7708 0.166656 10.5 0.166656C6.22918 0.166656 2.27084 3.19791 0.166675 8.08332C0.109318 8.21474 0.0797119 8.35659 0.0797119 8.49999C0.0797119 8.64338 0.109318 8.78523 0.166675 8.91665C2.27084 13.8021 6.22918 16.8333 10.5 16.8333C14.7708 16.8333 18.7292 13.8021 20.8333 8.91665C20.8907 8.78523 20.9203 8.64338 20.9203 8.49999C20.9203 8.35659 20.8907 8.21474 20.8333 8.08332ZM10.5 14.75C7.19792 14.75 4.07292 12.3646 2.27084 8.49999C4.07292 4.63541 7.19792 2.24999 10.5 2.24999C13.8021 2.24999 16.9271 4.63541 18.7292 8.49999C16.9271 12.3646 13.8021 14.75 10.5 14.75ZM10.5 4.33332C9.67592 4.33332 8.87034 4.57769 8.18513 5.03553C7.49993 5.49337 6.96588 6.14411 6.65051 6.90547C6.33514 7.66683 6.25263 8.50461 6.4134 9.31286C6.57417 10.1211 6.97101 10.8635 7.55373 11.4463C8.13645 12.029 8.87888 12.4258 9.68713 12.5866C10.4954 12.7474 11.3332 12.6649 12.0945 12.3495C12.8559 12.0341 13.5066 11.5001 13.9645 10.8149C14.4223 10.1297 14.6667 9.32408 14.6667 8.49999C14.6667 7.39492 14.2277 6.33511 13.4463 5.55371C12.6649 4.77231 11.6051 4.33332 10.5 4.33332ZM10.5 10.5833C10.088 10.5833 9.68517 10.4611 9.34257 10.2322C8.99997 10.0033 8.73294 9.67792 8.57526 9.29724C8.41758 8.91656 8.37632 8.49768 8.45671 8.09355C8.53709 7.68942 8.73551 7.31821 9.02687 7.02685C9.31823 6.73549 9.68944 6.53707 10.0936 6.45669C10.4977 6.3763 10.9166 6.41756 11.2973 6.57524C11.6779 6.73292 12.0033 6.99995 12.2322 7.34255C12.4612 7.68515 12.5833 8.08794 12.5833 8.49999C12.5833 9.05252 12.3638 9.58243 11.9731 9.97313C11.5824 10.3638 11.0525 10.5833 10.5 10.5833Z" fill="#2F2F2F"/>
                </svg>
                <p>1606</p>
              </div>
            </div>
            <div class="date">21.03.2002</div>
          </div>
        </div>
        <div class="recomendednews__blocks__block">
          <img src="../../assets/images/news/main.png" alt="">
          <p class="recomendednews__blocks__block__title">Светлый лик правового взаимодействия связывает нас с нашим прошлым</p>
          <div class="recomendednews__blocks__block__states">
            <div class="states">
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1875 8.5C19.8948 8.14859 19.5285 7.86571 19.1146 7.67132C18.7006 7.47693 18.249 7.37578 17.7916 7.375H13.0416L13.625 5.88541C13.8676 5.23321 13.9484 4.53188 13.8605 3.84158C13.7725 3.15128 13.5184 2.49263 13.12 1.92211C12.7215 1.35159 12.1906 0.886242 11.5728 0.565978C10.955 0.245714 10.2688 0.0800956 9.5729 0.0833289C9.37252 0.083747 9.17652 0.141949 9.00839 0.250957C8.84026 0.359965 8.70713 0.515152 8.62498 0.697912L5.65623 7.375H3.20831C2.37951 7.375 1.58466 7.70424 0.998604 8.29029C0.412553 8.87634 0.083313 9.6712 0.083313 10.5V17.7917C0.083313 18.6205 0.412553 19.4153 0.998604 20.0014C1.58466 20.5874 2.37951 20.9167 3.20831 20.9167H16.4687C17.1998 20.9164 17.9076 20.6599 18.4691 20.1917C19.0305 19.7235 19.4101 19.0733 19.5416 18.3542L20.8646 11.0625C20.9464 10.6118 20.9282 10.1487 20.8111 9.70591C20.6941 9.26309 20.4812 8.85141 20.1875 8.5ZM5.29165 18.8333H3.20831C2.93205 18.8333 2.66709 18.7236 2.47174 18.5282C2.27639 18.3329 2.16665 18.0679 2.16665 17.7917V10.5C2.16665 10.2237 2.27639 9.95878 2.47174 9.76343C2.66709 9.56808 2.93205 9.45833 3.20831 9.45833H5.29165V18.8333ZM18.8333 10.6875L17.5104 17.9792C17.466 18.2218 17.337 18.4409 17.1462 18.5973C16.9555 18.7537 16.7154 18.8374 16.4687 18.8333H7.37498V8.63541L10.2083 2.26041C10.5 2.34544 10.7708 2.49 11.0038 2.68498C11.2368 2.87995 11.4268 3.12111 11.5619 3.39322C11.6969 3.66533 11.7741 3.96249 11.7886 4.26593C11.803 4.56938 11.7544 4.87253 11.6458 5.15625L11.0937 6.64583C10.9761 6.96068 10.9364 7.29932 10.978 7.63284C11.0196 7.96637 11.1412 8.28487 11.3326 8.56118C11.524 8.83749 11.7794 9.0634 12.077 9.21962C12.3746 9.37584 12.7055 9.45774 13.0416 9.45833H17.7916C17.9447 9.45808 18.0959 9.49155 18.2345 9.55637C18.3731 9.62118 18.4958 9.71574 18.5937 9.83333C18.6941 9.94929 18.7676 10.086 18.809 10.2336C18.8504 10.3813 18.8587 10.5363 18.8333 10.6875Z" fill="#2F2F2F"/>
                </svg>
                <p>657</p>
              </div>
              <div class="state">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5416 9.45834H5.29165C5.01538 9.45834 4.75043 9.56809 4.55508 9.76344C4.35973 9.95879 4.24998 10.2237 4.24998 10.5C4.24998 10.7763 4.35973 11.0412 4.55508 11.2366C4.75043 11.4319 5.01538 11.5417 5.29165 11.5417H11.5416C11.8179 11.5417 12.0829 11.4319 12.2782 11.2366C12.4736 11.0412 12.5833 10.7763 12.5833 10.5C12.5833 10.2237 12.4736 9.95879 12.2782 9.76344C12.0829 9.56809 11.8179 9.45834 11.5416 9.45834ZM15.7083 5.29168H5.29165C5.01538 5.29168 4.75043 5.40142 4.55508 5.59677C4.35973 5.79212 4.24998 6.05708 4.24998 6.33334C4.24998 6.60961 4.35973 6.87456 4.55508 7.06991C4.75043 7.26526 5.01538 7.37501 5.29165 7.37501H15.7083C15.9846 7.37501 16.2495 7.26526 16.4449 7.06991C16.6402 6.87456 16.75 6.60961 16.75 6.33334C16.75 6.05708 16.6402 5.79212 16.4449 5.59677C16.2495 5.40142 15.9846 5.29168 15.7083 5.29168ZM17.7916 0.0833435H3.20831C2.37951 0.0833435 1.58466 0.412584 0.998604 0.998635C0.412553 1.58469 0.083313 2.37954 0.083313 3.20834V13.625C0.083313 14.4538 0.412553 15.2487 0.998604 15.8347C1.58466 16.4208 2.37951 16.75 3.20831 16.75H15.2812L19.1354 20.6146C19.2327 20.7111 19.3482 20.7875 19.4751 20.8394C19.602 20.8912 19.7379 20.9175 19.875 20.9167C20.0116 20.9202 20.1472 20.8917 20.2708 20.8333C20.461 20.7552 20.6239 20.6225 20.7388 20.4519C20.8537 20.2814 20.9156 20.0807 20.9166 19.875V3.20834C20.9166 2.37954 20.5874 1.58469 20.0014 0.998635C19.4153 0.412584 18.6204 0.0833435 17.7916 0.0833435ZM18.8333 17.3646L16.4479 14.9688C16.3506 14.8722 16.2351 14.7958 16.1082 14.744C15.9813 14.6922 15.8454 14.6659 15.7083 14.6667H3.20831C2.93205 14.6667 2.66709 14.5569 2.47174 14.3616C2.27639 14.1662 2.16665 13.9013 2.16665 13.625V3.20834C2.16665 2.93208 2.27639 2.66712 2.47174 2.47177C2.66709 2.27642 2.93205 2.16668 3.20831 2.16668H17.7916C18.0679 2.16668 18.3329 2.27642 18.5282 2.47177C18.7236 2.66712 18.8333 2.93208 18.8333 3.20834V17.3646Z" fill="#2F2F2F"/>
                </svg>
                <p>26</p>
              </div>
              <div class="state">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8333 8.08332C18.7292 3.19791 14.7708 0.166656 10.5 0.166656C6.22918 0.166656 2.27084 3.19791 0.166675 8.08332C0.109318 8.21474 0.0797119 8.35659 0.0797119 8.49999C0.0797119 8.64338 0.109318 8.78523 0.166675 8.91665C2.27084 13.8021 6.22918 16.8333 10.5 16.8333C14.7708 16.8333 18.7292 13.8021 20.8333 8.91665C20.8907 8.78523 20.9203 8.64338 20.9203 8.49999C20.9203 8.35659 20.8907 8.21474 20.8333 8.08332ZM10.5 14.75C7.19792 14.75 4.07292 12.3646 2.27084 8.49999C4.07292 4.63541 7.19792 2.24999 10.5 2.24999C13.8021 2.24999 16.9271 4.63541 18.7292 8.49999C16.9271 12.3646 13.8021 14.75 10.5 14.75ZM10.5 4.33332C9.67592 4.33332 8.87034 4.57769 8.18513 5.03553C7.49993 5.49337 6.96588 6.14411 6.65051 6.90547C6.33514 7.66683 6.25263 8.50461 6.4134 9.31286C6.57417 10.1211 6.97101 10.8635 7.55373 11.4463C8.13645 12.029 8.87888 12.4258 9.68713 12.5866C10.4954 12.7474 11.3332 12.6649 12.0945 12.3495C12.8559 12.0341 13.5066 11.5001 13.9645 10.8149C14.4223 10.1297 14.6667 9.32408 14.6667 8.49999C14.6667 7.39492 14.2277 6.33511 13.4463 5.55371C12.6649 4.77231 11.6051 4.33332 10.5 4.33332ZM10.5 10.5833C10.088 10.5833 9.68517 10.4611 9.34257 10.2322C8.99997 10.0033 8.73294 9.67792 8.57526 9.29724C8.41758 8.91656 8.37632 8.49768 8.45671 8.09355C8.53709 7.68942 8.73551 7.31821 9.02687 7.02685C9.31823 6.73549 9.68944 6.53707 10.0936 6.45669C10.4977 6.3763 10.9166 6.41756 11.2973 6.57524C11.6779 6.73292 12.0033 6.99995 12.2322 7.34255C12.4612 7.68515 12.5833 8.08794 12.5833 8.49999C12.5833 9.05252 12.3638 9.58243 11.9731 9.97313C11.5824 10.3638 11.0525 10.5833 10.5 10.5833Z" fill="#2F2F2F"/>
                </svg>
                <p>1606</p>
              </div>
            </div>
            <div class="date">21.03.2002</div>
          </div>
        </div>
      </div>
    </div>
    <div class="recomendednews__arrows">
      <div class="recomendednews__arrows__left" @click="slider('left')">
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="1.44244" transform="matrix(-1 0 0 1 32.642 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 22.8076 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 12.9724 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 12.9724 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 3.13764 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 3.13764 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 12.9724 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 3.13764 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 3.13764 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 12.9724 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <path d="M30.7772 17.926C31.3405 18.4893 31.3405 19.4026 30.7772 19.9659L21.5976 29.1455C21.0343 29.7088 20.121 29.7088 19.5577 29.1455C18.9944 28.5822 18.9944 27.6689 19.5577 27.1056L27.7173 18.9459L19.5577 10.7862C18.9944 10.2229 18.9944 9.30964 19.5577 8.74633C20.121 8.18302 21.0343 8.18302 21.5976 8.74633L30.7772 17.926ZM3.79333 17.5035L29.7573 17.5035V20.3884L3.79333 20.3884V17.5035Z" fill="#069C38"/>
        </svg>
      </div>
      <div class="recomendednews__arrows__right" @click="slider('right')">
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="1.44244" transform="matrix(-1 0 0 1 32.642 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 22.8076 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 12.9724 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 32.642 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 12.9724 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 3.13764 34.0261)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 3.13764 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <circle r="1.44244" transform="matrix(-1 0 0 1 12.9724 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 24.1911)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 3.13764 14.3562)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 3.13764 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 12.9724 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <ellipse rx="1.44244" ry="1.44244" transform="matrix(-1 0 0 1 22.8076 4.52154)" fill="#069C38" fill-opacity="0.5"/>
          <path d="M30.7772 17.926C31.3405 18.4893 31.3405 19.4026 30.7772 19.9659L21.5976 29.1455C21.0343 29.7088 20.121 29.7088 19.5577 29.1455C18.9944 28.5822 18.9944 27.6689 19.5577 27.1056L27.7173 18.9459L19.5577 10.7862C18.9944 10.2229 18.9944 9.30964 19.5577 8.74633C20.121 8.18302 21.0343 8.18302 21.5976 8.74633L30.7772 17.926ZM3.79333 17.5035L29.7573 17.5035V20.3884L3.79333 20.3884V17.5035Z" fill="#069C38"/>
        </svg>
      </div>
    </div>
    <div class="recomendednews__bottom"><img src="../../assets/images/news/leaves3.png" alt=""></div>
  </div>
</template>
<script>
import './recomendednews.scss'

export default {
  name: 'RecomendedNews',
  methods: {
    slider (side) {
      const sliderWrapper = document.querySelector('#wrap')
      const sliderItem = sliderWrapper.children

      if (side === 'right') {
        sliderWrapper.style.transition = 'transform 0.6s ease'
        sliderWrapper.style.transform = 'translateX(-34.5%)'

        setTimeout(() => {
          for (let i = 0; i < sliderItem.length; i++) {
            let k = sliderItem[i].style.order
            if (Number(k) === 0) {
              k = Number(sliderItem.length)
            }
            k = Number(k) - 1
            sliderItem[i].style.order = `${k}`
          }
          sliderWrapper.style.transition = 'none'
          sliderWrapper.style.transform = 'translateX(0%)'
        }, 800)
      }
      if (side === 'left') {
        for (let i = 0; i < sliderItem.length; i++) {
          let k = sliderItem[i].style.order
          if (Number(k) === Number(sliderItem.length) - 1) {
            k = -1
          }
          k = Number(k) + 1
          sliderItem[i].style.order = `${k}`
        }
        sliderWrapper.style.transition = 'none'
        sliderWrapper.style.transform = 'translateX(-34.5%)'
        setTimeout(() => {
          sliderWrapper.style.transition = 'transform 0.6s ease'
          sliderWrapper.style.transform = 'translateX(0%)'
        }, 200)
      }
    }
  },
  mounted () {
    const sliderWrapper = document.querySelector('#wrap')
    const sliderItem = sliderWrapper.children

    for (let i = 0; i < sliderItem.length; i++) {
      sliderItem[i].style.order = `${i}`
    }
  }
}
</script>
