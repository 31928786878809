<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <main>
        <div
          class="hint"
          :class="{'animate__animated animate__bounceInRight': $store.state.salesNotification }"
          v-if="$store.state.salesNotification"
        >
          <div class="hint-border">
            <div class="premium-hint">
              <p class="premium-hint__title">Внимание!</p>
              <p class="premium-hint__text">На данный момент все робуксы распроданы!</p>
            </div>
          </div>
        </div>
        <Auth />
        <Slider />
        <Live />
        <Gallery />
        <Reviews />
      </main>
      <Footer />
    </div>
  </div>
</template>
<script>
import './home.scss'

import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer/Footer'
import Reviews from '@/components/Reviews/Reviews'
import Slider from '@/components/Slider/Slider'
import Live from '@/components/Live/Live'
import Gallery from '@/components/Gallery/Gallery'
import Auth from '@/components/Auth/Auth'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'

export default {
  name: 'Home',
  components: {
    Navigation,
    AsideMenu,
    Reviews,
    Footer,
    Slider,
    Live,
    Gallery,
    Auth,
    burgerMenu,
    userDropMenu
  },
  metaInfo: {
    title: 'rbxtree.pro — Сайт дешевых робуксов / робаксов',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'rbxtree.pro лучший сайт по продаже робуксов! Тут ты можешь купить робуксы дешево, быстро и безопасно. Покупая робуксы на нашем сайте, Вы экономите очень много денег и времени, а заодно и играете любимую игру роблокс. Покупка трансфером 1 RUB = 2 R$. Тысячи положительных отзывов от реальных людей в нашей группе ВКОНТАКТЕ.'
    }, {
      property: 'og:locale',
      content: 'ru_RU'
    }, {
      property: 'og:type',
      content: 'website'
    }, {
      property: 'og:title',
      content: 'rbxtree.pro — Сайт дешевых робуксов / робаксов'
    }, {
      property: 'og:description',
      content: 'rbxtree.pro лучший сайт по продаже робуксов! Тут ты можешь купить робуксы дешево, быстро и безопасно. Покупая робуксы на нашем сайте, Вы экономите очень много денег и времени, а заодно и играете любимую игру роблокс. Покупка трансфером 1 RUB = 2 R$. Тысячи положительных отзывов от реальных людей в нашей группе ВКОНТАКТЕ.'
    }, {
      property: 'og:url',
      content: 'https://rbxtree.pro/'
    }, {
      property: 'og:image',
      content: '/img/logo.39eaa367.svg'
    }, {
      property: 'og:site_name',
      content: 'Интернет-магазин робуксов'
    }]
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'EntertainmentBusiness',
      name: 'Rbxtree',
      image: 'https://rbxtree.pro/img/logo.ce6fbc85.svg',
      '@id': 'https://rbxtree.pro/contacts',
      url: 'https://rbxtree.pro/',
      priceRange: '$$'
    }
  }
}
</script>
