<template>
  <div class="reviews">
    <div class="reviews__image">
      <img class="re__particle" src="../../assets/images/reviews/particles.svg" alt="">
      <img src="./../../assets/images/reviews/like.svg" alt="like">
    </div>
    <div class="reviews__text">
      <h2>ОТЗЫВЫ</h2>
      <p>
        В нашей группе ВКонтакте более 20 000 отзывов реальных игроков! Также мы постоянно проводим розыгрыши робуксов именно через нашу группу!
      </p>
      <a href="https://vk.com/topic-196995578_41441332" target="_blank">
        ПРИСОЕДИНЯЙСЯ
        <img src="./../../assets/images/reviews/right-arrow.svg" alt="">
      </a>
    </div>
    <img class="bg-tree" src="../../assets/images/reviews/tree.png" alt="">
    <img class="footer-branch" src="../../assets/images/reviews/branch.png" alt="">
  </div>
</template>

<script>
import './reviews.scss'

export default {
  name: 'Reviews'
}
</script>
