<template>
  <header class="header">
    <div class="overlay"></div>
    <router-link :to="{ path: '/' }" class="header__logo">
      <img src="./../../assets/images/navigation/logo.svg" alt="logo">
    </router-link>
    <div class="menu-burger" @click="$store.state.showBurgerMenu = true">
      <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.000976562" y="1" width="18.5143" height="4" rx="2" fill="black"/>
        <rect x="0.000976562" y="12.4545" width="36" height="4" rx="2" fill="black"/>
        <rect x="17.4868" y="23.9091" width="18.5143" height="4" rx="2" fill="black"/>
      </svg>
    </div>
    <nav class="nav">
      <router-link to="/" class="nav__item">Главная</router-link>
      <router-link to="/faq" class="nav__item">F.A.Q.</router-link>
      <a v-if="false" href="#" class="nav__item">
        БОНУСЫ
        <img  class="nav__item-soon" src="./../../assets/images/navigation/soon.png" alt="soon">
      </a>
    </nav>
    <div class="nav__button" v-if="!$store.state.isAuth">
      <div class="nav__button__2">
        <button @click="$store.commit('toggleAuthShowState')">Личный кабинет</button>
      </div>
      <img
        class="nav__button-user"
        src="./../../assets/images/navigation/user.svg"
        alt="user"
        @click="$store.commit('toggleAuthShowState')">
    </div>
    <div class="auth__block" v-else>
      <div class="content-auth">
        <div class="user">
          <p class="user-name">{{ $store.state.account.username }}</p>
        </div>
        <div class="balance">
          <div class="balance-add">
            <router-link to="/payment">+</router-link>
          </div>
          <div class="balance-block">
            <p >
              {{ $store.state.account.rubBalance.toFixed(2) }} ₽
            </p>
          </div>
        </div>
      </div>
      <div class="auth__block-avatar"
      @click="
        $store.state.showMenuDropDown = !$store.state.showMenuDropDown;
        $store.state.authWindow.changePassword = false;
        $store.state.showAuth = false;"
      >
        <div class="user-ava">
          <img
            v-if="$store.state.account.isVk"
            :src="$store.state.account.avatarUrl"
            @click="openUserDropMenu"
            alt=""
          >
          <img
            v-if="$store.state.account.isVk === 0"
            :src="require(`../../assets/images/navigation/avatar-${$store.state.account.avatarId}.png`)"
            @click="openUserDropMenu"
            alt=""
          >
        </div>
        <img
          :class="{ rotate: $store.state.showMenuDropDown }"
          class="arrow"
          src="../../assets/images/navigation/arrow.svg" alt="">

      </div>
      <div class="dropdown" v-show="$store.state.showMenuDropDown" :class="$store.state.account.isVk === 1 ? 'is-vk' : ''">
        <ul class="dropdown__menu">
          <li @click="$store.state.showMenuDropDown = false">
            <router-link to="/history">История</router-link>
          </li>
          <li @click="$store.state.showMenuDropDown = false">
            <router-link to="/payment">Пополнить баланс</router-link>
          </li>
          <li @click="$store.dispatch('showChangePassword')" v-if="$store.state.account.isVk === 0">
            <router-link to="#">Сменить пароль</router-link>
          </li>
          <li
            class="exit-btn"
            @click="$store.dispatch('logOut')"
          >
            Выйти
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import './navigation.scss'

export default {
  name: 'Navigation',
  methods: {
    openUserDropMenu () {
      if (document.body.clientWidth <= 880) this.$store.state.showUserDropMenu = true
    }
  }
}
</script>
