<template>
  <div class="order transfer__wrapper">
    <div class="transfer-modal">
<!--      v-if="data.orders.length > 1"-->
      <div class="arrow__left" @click="changeOrder(-1)" >
        <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z" fill="#069C38" fill-opacity="0.29"/>
        </svg>
      </div>
      <div class="modal">
        <div class="transfer-modal__content">
          <div class="transfer-modal__content__number">#{{ currentIndex + 1 }}</div>
          <div class="transfer-modal__content-logo">
            <img src="../../../assets/images/transfer/logo.svg" alt="">
          </div>
          <div class="transfer-modal__content-timer">
            <div
              class="timer__counter"
              v-if="['waiting', 'paid', 'code', 'robuxship'].includes(data.orders[currentIndex].status)"
            >
              <img :src="require('./../../../assets/images/premium/loader.svg')" alt="loader" class="rotate">
              <span>{{ displayTimer() }}</span>
            </div>
            <div
              class="timer__counter"
              v-if="data.orders[currentIndex].status === 'success'"
            >
              <img :src="require('./../../../assets/images/transfer/allow.svg')" alt="allow">
            </div>
            <div
              class="timer__counter"
              v-if="data.orders[currentIndex].status === 'error' || data.orders[currentIndex].status === 'system_error'"
            >
              <img :src="require('./../../../assets/images/transfer/deny.svg')" alt="deny">
            </div>
          </div>
          <div class="transfer-modal__content-main">
            <p class="title">{{ data.orders[currentIndex].description }}</p>
            <div class="table">
              <table>
                <thead class="table__header">
                <tr>
                  <td>ИГРОВОЙ НИК</td>
                  <td>КОЛ-ВО R$</td>
                  <td>ИТОГО, РУБ</td>
                </tr>
                </thead>
                <tbody class="table__content">
                <tr>
                  <td>{{ data.orders[currentIndex].username }}</td>
                  <td><div class="border">{{ data.orders[currentIndex].robux_amount.toFixed(2) }} R$</div></td>
                  <td>{{ data.orders[currentIndex].rub_amount.toFixed(2) }} ₽</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p
              class="status-ok"
               v-if="data.orders[currentIndex].status === 'success'"
            >Отслеживать поступление робуксов на ваш аккаунт вы можете
              <a href="https://www.roblox.com/transactions" target="_blank">здесь</a>
            </p>
            <p
              class="status-ok"
              v-if="data.orders[currentIndex].status === 'error' || data.orders[currentIndex].status === 'system_error'"
            >Средства возвращены на баланс аккаунта RBXTREE. Попробуйте еще раз :)
            </p>
          </div>
        </div>
        <div
          class="transfer-modal__order"
          @click="changeModal"
        >
          <p>сделать еще заказ</p>
        </div>
      </div>
<!--      v-if="data.orders.length > 1"-->
      <div class="arrow__right" @click="changeOrder(1)">
        <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z" fill="#069C38" fill-opacity="0.29"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import './transferOrder.scss'

export default {
  name: 'TransferOrder',
  props: ['data'],
  data () {
    return {
      currentIndex: 0,
      currentTimer: null,
      timer: null,
      intervalId: null,
      orderIId: null
    }
  },
  created () {
    this.$store.dispatch('authorization', this.$cookies.get('access_token'))

    this.orderIId = setInterval(() => {
      this.$store.dispatch('authorization', this.$cookies.get('access_token'))

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      this.loading = true

      fetch('https://pending.backend-tree.com/active_orders', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loading = false

          if (data.status) {
            this.data.orders = data.orders

            if (this.data.orders.length > 0) {
              this.currentTransfer = 'TransferOrder'
            }
          }
        })
        .catch((err) => console.log(err))
    }, 25000)
  },
  mounted () {
    this.setTimer()
  },
  destroyed () {
    clearInterval(this.orderIId)
  },
  methods: {
    changeModal () {
      this.$emit('changeModal', { modal: 'MainForm' })
    },
    setTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      const orderDate = new Date(this.data.orders[this.currentIndex].date)
      orderDate.setHours(orderDate.getHours() + 1)
      this.timer = orderDate - Date.now()

      this.intervalId = setInterval(() => {
        this.timer -= 1000
      }, 1000)
    },
    displayTimer () {
      if (this.timer <= 0) {
        return '00:00:00'
      }

      const formatedHours = ('0' + String(Math.trunc(this.timer / 1000 / 60 / 60))).slice(-2)
      const formatedMinutes = ('0' + String(Math.trunc(this.timer / 1000 / 60 % 60))).slice(-2)
      const formatedSeconds = ('0' + String(Math.trunc(this.timer / 1000 % 60 % 60))).slice(-2)

      return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
    },
    changeOrder (direction) {
      if (direction === 1) {
        this.currentIndex = this.currentIndex + 1 >= this.data.orders.length ? 0 : this.currentIndex + 1
      } else if (direction === -1) {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.data.orders.length - 1 : this.currentIndex - 1
      }

      this.setTimer()
    }
  }
}
</script>
