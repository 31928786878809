<template>
  <div class="support-chat">
    <div class="support-chat__full-image" v-if="showFullImage">
      <div class="support-chat__full-image__wrapper">
        <div class="support-chat__full-image__wrapper__close" @click="closeFullImage">
          <img src="./../../assets/images/SupportChat/close.svg" alt="close">
        </div>
        <img :src="fullImage" alt="">
      </div>
    </div>
    <transition name="slide-fade">
      <div class="support-chat__opened" v-show="opened">
        <div class="support-chat__opened__header">
          <img class="online" src="./../../assets/images/SupportChat/online-button.svg" alt="online">
          <p>МЫ ГОТОВЫ ВАМ ПОМОЧЬ!</p>
          <img @click="closeChat" class="close" src="./../../assets/images/SupportChat/down-arrow.svg" alt="close">
        </div>
        <div class="support-chat__opened__messages" :class="{ extended: formOpen }">
          <div class="support-chat__opened__anket" v-if="formOpen">
            <p>Для связи с оператором введите</p>
            <small>информацию о себе:</small>
            <div class="auth__input">
              <div class="auth__input__mark">
                <img src="./../../assets/images/SupportChat/name.svg" alt="name">
              </div>
              <input
                type="text"
                placeholder="Введите имя"
                v-model="anketValues.name"
                :class="anketErrors.name.show && anketErrors.name.status === false ? 'error': ''"
              >
              <div class="auth__input__error" v-if="anketErrors.name.show && anketErrors.name.status === false">
                <p>{{ anketErrors.name.message }}</p>
              </div>
              <div
                class="auth__input__title"
                :class="anketErrors.name.show && anketErrors.name.status === false ? 'error': ''"
              >
                Имя
              </div>
            </div>
            <div class="auth__input">
              <div class="auth__input__mark">
                <img src="./../../assets/images/SupportChat/email.svg" alt="email">
              </div>
              <input
                type="text"
                placeholder="Введите email"
                v-model="anketValues.email"
                :class="anketErrors.email.show && anketErrors.email.status === false ? 'error': ''"
              >
              <div class="auth__input__error" v-if="anketErrors.email.show && anketErrors.email.status === false">
                <p>{{ anketErrors.email.message }}</p>
              </div>
              <div
                class="auth__input__title"
                :class="anketErrors.email.show && anketErrors.email.status === false ? 'error': ''"
              >
                Email
              </div>
            </div>
            <div class="auth__input">
              <div class="auth__input__mark">
                <img src="./../../assets/images/SupportChat/vk.svg" alt="vk">
              </div>
              <input
                type="text"
                placeholder="Введите ссылку на Вконтакте"
                v-model="anketValues.vk"
                :class="anketErrors.vk.show && anketErrors.vk.status === false ? 'error': ''"
              >
              <div class="auth__input__error" v-if="anketErrors.vk.show && anketErrors.vk.status === false">
                <p>{{ anketErrors.vk.message }}</p>
              </div>
              <div
                class="auth__input__title"
                :class="anketErrors.vk.show && anketErrors.vk.status === false ? 'error': ''"
              >
                СТРАНИЦА VK
              </div>
            </div>
            <button @click="fillData">СВЯЗАТЬСЯ с оператором</button>
          </div>
          <div class="support-chat__opened__wrapper" v-if="formOpen === false">
            <div
              v-for="(message, index) in messages"
              :key="message.datetime"
              class="message"
              :class="{
                'support-chat__opened__messages__operator': message.sender !== 'user',
                'support-chat__opened__messages__me': message.sender === 'user'
              }"
            >
              <div class="support-chat__opened__messages__operator__avatar" v-if="message.sender !== 'user'">
                <img v-if="parseAvatar(index)" :src="message.sender === 'operator' ? require('./../../assets/images/SupportChat/operator-avatar.png') : require('./../../assets/images/SupportChat/bot-avatar.svg')" alt="">
              </div>
              <div class="support-chat__opened__messages__me__wrapper" v-if="message.sender === 'user'">
                <pre v-if="message.type === 'text'">{{ message.data }}</pre>
                <img
                  v-if="message.type === 'image'"
                  @click="openFullImage(message.data)"
                  :src="message.data"
                  alt=""
                >
                <small>{{ parseData(message.datetime) }}</small>
              </div>
              <div class="support-chat__opened__messages__operator__info" v-else>
                <h2 v-if="parseNickname(index)">{{ message.sender === 'operator' ? 'Помощник RBXTREE' : 'Чат-бот' }}</h2>
                <div class="support-chat__opened__messages__operator__info__message">
                  <pre v-if="message.type === 'text'" v-html="parseMessage(message.data)"></pre>
                  <img
                    v-if="message.type === 'image'"
                    @click="openFullImage(message.data)"
                    :src="message.data"
                    alt=""
                  >
                  <small>{{ parseData(message.datetime) }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="support-chat__opened__form" v-if="formOpen === false">
          <div class="support-chat__opened__form__phrases">
            <p
              v-for="btn in fastButtons"
              :key="btn"
              @click="sendFastMessage(btn)">
              {{ btn }}
            </p>
          </div>
          <div class="support-chat__opened__form__input">
            <input
              type="text"
              placeholder="Введите сообщение..."
              v-model="messageText"
              @keyup.enter="sendMessage"
              autocomplete="off"
            >
            <input v-show="false" id="img-upload" @change="sendImage" type="file" name="file" autocomplete="off" required>
            <label for="img-upload">
              <img class="photo" src="./../../assets/images/SupportChat/photo.svg" alt="">
            </label>
            <img @click="sendMessage" class="send" src="./../../assets/images/SupportChat/send.svg" alt="">
          </div>
        </div>
      </div>
    </transition>
    <!--  Скрыто из-за конфликта с intercom (opened) -->
    <div class="support-chat__closed" v-if="false">
      <img @click="openChat" src="./../../assets/images/SupportChat/chat-closed.svg" alt="chat">
    </div>
  </div>
</template>

<script>
import './supportChat.scss'

import io from 'socket.io-client'

export default {
  name: 'SupportChat',
  data () {
    return {
      opened: false,
      socket: null,
      dialog: null,
      messages: [],
      messageText: '',
      fullImage: '',
      showFullImage: false,
      formOpen: false,
      operatorIsCalled: false,
      smileMap: {
        SMILE_1: 'SMILE_1.png',
        SMILE_2: 'SMILE_2.png',
        SMILE_3: 'SMILE_3.png',
        SMILE_4: 'SMILE_4.png',
        SMILE_5: 'SMILE_5.png'
      },
      fastButtons: [
        'Вопросы по покупке',
        'Не пришли робуксы',
        'Сайт'
      ],
      anketValues: {
        name: '',
        email: '',
        vk: ''
      },
      anketErrors: {
        name: {
          status: false,
          show: false,
          message: ''
        },
        email: {
          status: false,
          show: false,
          message: ''
        },
        vk: {
          status: false,
          show: false,
          message: ''
        }
      }
    }
  },
  created () {
    (async () => {
      let dialogID = localStorage.getItem('dialog_id')

      if (!dialogID) {
        const response = await fetch('https://live-chat.backend-tree.com/api/v1/sessions/generate_dialog')
        const data = await response.json()

        if (response.status === 200) {
          localStorage.setItem('dialog_id', data.dialog_id)
          dialogID = data.dialog_id
        }
      }

      this.socket = io('https://live-chat.backend-tree.com', {
        transports: ['websocket'],
        query: `Dialog=${dialogID}`
      })

      this.socket.on('connect', () => {
        console.log('Connect')

        this.socket.emit('fetch', { Dialog: dialogID })
      })

      this.socket.on('check_dialog', (dialog) => {
        this.dialog = dialog
        this.fastButtons = Object.keys(dialog.step.ways).filter((w) => w !== '')
        this.messages = dialog.messages

        console.log(this.dialog)

        setTimeout(() => {
          document.querySelector('.support-chat__opened__messages').scrollBy({
            top: 10000000000000,
            behavior: 'auto'
          })
        }, 1)
      })

      this.socket.on('new_message', (message) => {
        this.fastButtons = Object.keys(message.step.ways).filter((w) => w !== '')

        this.messages.push(message.message)

        setTimeout(() => {
          this.chatScroll()
        }, 1)

        if (message.step.network_id === 1) {
          this.operatorIsCalled = false
        }

        if (message.step.network_id === 11 && this.operatorIsCalled === false) {
          if (this.dialog.details.is_filled) {
            setTimeout(() => {
              this.socket.emit('operator', { Dialog: dialogID })
            }, 2000)

            this.operatorIsCalled = true
            this.formOpen = false
          } else {
            this.formOpen = true
          }
        }
      })

      this.socket.on('disconnect', () => {
        console.log('Disnonnect')
      })
    })()
  },
  methods: {
    sendMessage () {
      if (this.messageText === '') {
        return
      }

      this.socket.emit('push', {
        Dialog: localStorage.getItem('dialog_id'),
        message: {
          type: 'text',
          data: this.messageText
        }
      })

      const d = new Date()
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset())

      this.messages.push({
        data: this.messageText,
        datetime: d.toISOString(),
        sender: 'user',
        type: 'text'
      })

      this.messageText = ''
      setTimeout(() => {
        this.chatScroll()
      }, 1)
    },
    fillData () {
      this.socket.emit('fill', {
        Dialog: localStorage.getItem('dialog_id'),
        data: {
          name: this.anketValues.name,
          email: this.anketValues.email,
          vk: this.anketValues.vk
        }
      })

      this.dialog.details.is_filled = true

      setTimeout(() => {
        this.socket.emit('operator', {
          Dialog: localStorage.getItem('dialog_id')
        })
      }, 2000)

      this.operatorIsCalled = true
      this.formOpen = false

      setTimeout(() => {
        this.chatScroll()
      }, 1)
    },
    openChat () {
      this.opened = true

      setTimeout(() => {
        this.chatScroll()
      }, 1)
    },
    closeChat () {
      this.opened = false
    },
    sendFastMessage (msg) {
      this.socket.emit('push', {
        Dialog: localStorage.getItem('dialog_id'),
        message: {
          type: 'text',
          data: msg
        }
      })

      const d = new Date()
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset())

      this.messages.push({
        data: msg,
        datetime: d.toISOString(),
        sender: 'user',
        type: 'text'
      })

      setTimeout(() => {
        this.chatScroll()
      }, 1)
    },
    parseMessage (message) {
      let parsedMessage = message

      Object.keys(this.smileMap).forEach((k) => {
        parsedMessage = parsedMessage.replaceAll(`|${k}|`, `<img src="/smiles/${this.smileMap[k]}" />`)
      })

      return parsedMessage
    },
    parseData (datetime) {
      const d = new Date(datetime)
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset())

      const hours = d.getHours()
      const minutes = d.getMinutes()

      const hoursParse = ('0' + hours).substr(-2)
      const minutesParse = ('0' + minutes).substr(-2)

      return `${hoursParse}:${minutesParse}`
    },
    parseAvatar (index) {
      return this.messages[index]?.sender !== this.messages[index + 1]?.sender
    },
    parseNickname (index) {
      return this.messages[index]?.sender !== this.messages[index - 1]?.sender
    },
    chatScroll () {
      const scrollTarget = document.querySelector('.support-chat__opened__messages .message:last-child')

      const topOffset = 0
      const elementPosition = scrollTarget.getBoundingClientRect().bottom + scrollTarget.getBoundingClientRect().height
      const offsetPosition = elementPosition - topOffset

      document.querySelector('.support-chat__opened__messages').scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      })
    },
    openFullImage (src) {
      this.fullImage = src
      this.showFullImage = true
    },
    closeFullImage () {
      this.fullImage = ''
      this.showFullImage = false
    },
    sendImage: async function (event) {
      const file = event.target.files[0]
      const formData = new FormData()

      formData.append('file', file, file.name)

      const response = await fetch('https://imgs.backend-tree.com', {
        method: 'POST',
        body: formData
      })
      const data = await response.json()

      if (data.filename) {
        this.socket.emit('push', {
          Dialog: localStorage.getItem('dialog_id'),
          message: {
            type: 'image',
            data: `https://imgs.backend-tree.com/static/uploads/${data.filename}`
          }
        })

        const d = new Date()
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset())

        this.messages.push({
          data: `https://imgs.backend-tree.com/static/uploads/${data.filename}`,
          datetime: d.toISOString(),
          sender: 'user',
          type: 'image'
        })

        setTimeout(() => {
          this.chatScroll()
        }, 1)
      }
    }
  }
}
</script>
