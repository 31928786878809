import Vue from 'vue'
import VueCookies from 'vue-cookies'
import router from './router'
import Meta from 'vue-meta'
import App from './components/App/App.vue'
import store from './vuex/store'
import VueJsonLD from 'vue-jsonld'
// import VueSocketIO from 'vue-socket.io'
// import VueSocketIO from 'vue-socket.io'
// import io from 'socket.io-client'

import './scss/_reset.scss'
import './scss/_main.scss'

Vue.use(VueCookies)
Vue.use(VueJsonLD)
Vue.use(Meta)

// const socket = io('https://notifications.backend-tree.com/', {
//   // transportOptions: {
//   //   polling: {
//   //     extraHeaders: {
//   //       Authorization: 'Bearer abc'
//   //     }
//   //   }
//   // }
// })

// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: socket,
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   }
// }))

Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
