module.exports.sliderInit = () => {
  const arrowRight = document.querySelector('.slider-arrow__right')
  const sliderWrapper = document.querySelector('.slider-wrapper')
  const sliderItem = document.querySelector('.slider__item-bg')
  const dots = document.querySelectorAll('.circle')
  const sliderBar = document.querySelector('.slider-timebar')

  const speedBar = 0.05
  const maxSlides = dots.length - 1
  let i = 0

  const sliderBarColors = {
    // 0: '#E86F00',
    0: '#3FAAB3',
    1: '#E86F00',
    2: '#069C38'
    // 1: '#069C38',
    // 3: '#E86F00',
  }

  setInterval(() => {
    if (i < 100) {
      sliderBar.value = i
      i += speedBar
    } else {
      i = 0
      getIndex()
    }
  }, 1)

  const checkEnd = (index) => {
    i = 0
    if (index > maxSlides) {
      index = 0
      return index
    }
    return index
  }

  const changeDote = (index) => {
    dots.forEach(dote => {
      dote.classList.remove('active')
      if (Number(dote.dataset.index) === index) {
        dote.classList.add('active')
      }
    })
  }

  const getIndex = () => {
    const index = checkEnd(Number(document.querySelector('.circle.active').dataset.index) + 1)
    nextSlide(index)
  }

  const nextSlide = (index) => {
    sliderBar.style.setProperty('--color-bar', sliderBarColors[index])
    sliderWrapper.style.transition = '.5s all'
    sliderWrapper.style.transform = `translate(-${sliderItem.clientWidth * index}px)`

    dots.forEach(dote => {
      if (index === Number(dote.dataset.index)) {
        changeDote(index)
      }
    })
  }

  dots.forEach(dote => {
    dote.addEventListener('click', (event) => {
      if (!event.target.classList.contains('active')) {
        const active = document.querySelectorAll('.active')
        if (active) {
          active.forEach(item => {
            item.classList.remove('active')
          })
          dote.classList.add('active')
          const index = dote.dataset.index
          i = 0
          nextSlide(index)
        }
      }
    })
  })

  arrowRight.addEventListener('click', getIndex)
}
