import Vue from 'vue'
import cookie from 'vue-cookies'
import Vuex from 'vuex'

import router from './../router/index'

Vue.use(Vuex)

const storeConfig = {
  state: {
    showLogPassInstruction: false,
    showPremiumInstruction: false,
    ipCountry: 'ru',
    showPreloader: true,
    syncCompleted: true,
    salesNotification: false,
    timerNotification: null,
    additionalSync: [],
    showAuth: false,
    animateAuth: false,
    isLogPassMobile: false,
    isPremiumMobile: false,
    isLackRobux: false,
    authWindow: {
      logIn: true,
      changePassword: false
    },
    isAuth: false,
    showMenuDropDown: false,
    showBurgerMenu: false,
    showUserDropMenu: false,
    account: {
      email: '',
      rubBalance: 0,
      userId: '',
      username: '',
      accessToken: '',
      refreshToken: '',
      isVk: 0,
      avatarId: 1,
      avatarUrl: ''
    },
    payments: {
      order: null,
      methodType: 1,
      isPremium: false
    },
    socket: {
      connected: false
    }
  },
  mutations: {
    toggleAuthShowState (state) {
      if (state.animateAuth) {
        return
      }

      state.showAuth = !state.showAuth

      if (state.showAuth === false) {
        state.animateAuth = true

        let authNode
        const authMain = document.querySelector('.auth')
        authMain.classList.add('animate__fadeOut')

        authMain.childNodes.forEach((node) => {
          if (!node.style) {
            return
          }

          if (node.style.display !== 'none') {
            authNode = node
            authNode.classList.add('animate__slideOutRight')
          }
        })

        setTimeout(() => {
          state.animateAuth = false
          authMain.classList.remove('animate__fadeOut')
          authNode.classList.remove('animate__slideOutRight')
        }, 1000)
      }
    },
    showNotification (state) {
      if (!state.timerNotification) {
        state.salesNotification = true
      } else {
        clearTimeout(state.timerNotification)
        state.salesNotification = true
      }
      state.timerNotification = setTimeout(() => {
        state.salesNotification = false
      }, 8000)
    },
    setPaymentsData (state, data) {
      console.log(data)
      state.payments.order = data.order
      state.payments.methodType = data.methodType
      state.payments.isPremium = data.isPremium

      router.push('/payment')
    },
    clearPaymentData (state) {
      state.payments = {}
    },
    completeSync (state) {
      state.syncCompleted = true
    },
    setAccountData (state, data) {
      state.isAuth = true
      state.account.email = data.email
      state.account.rubBalance = data.rub_balance
      state.account.userId = data.user_id
      state.account.username = data.username
      state.account.isVk = data.is_vk

      if (state.account.isVk) {
        state.account.avatarUrl = data.avatar
      } else {
        state.account.avatarId = data.avatar
      }

      localStorage.setItem('user_id', data.user_id)
      state.syncCompleted = true
    },
    setTokens (state, tokens) {
      state.account.accessToken = tokens.access
      state.account.refreshToken = tokens.refresh

      cookie.set('access_token', tokens.access, '1d')
      cookie.set('refresh_token', tokens.refresh, '2d')
    },
    removeAccountData (state) {
      state.account = {}
      state.isAuth = false
      state.showUserDropMenu = false

      localStorage.removeItem('user_id')
    },
    removeTokens (state) {
      cookie.remove('access_token')
      cookie.remove('refresh_token')
    },
    showChangePassword (state) {
      if (state.showUserDropMenu) state.showUserDropMenu = false
      state.showAuth = true
      state.authWindow.changePassword = true
      state.showMenuDropDown = false
    },
    closeShowChangePassword (state) {
      state.showAuth = false
      state.authWindow.changePassword = false
    }
  },
  actions: {
    SOCKET_CONNECT ({ commit }) {
      console.log('SOCKET CONNECTED')
    },
    SOCKET_DISCONNECT ({ commit }) {
      console.log('SOCKET DISCONNECTED')
    },
    SOCKET_NOTIFICATION ({ commit }, message) {
      console.log(message)
    },
    signIn ({ commit }, data) {
      commit('setAccountData', data.data)
      commit('setTokens', data.auth_token)
    },
    showChangePassword ({ commit }) {
      commit('showChangePassword')
    },
    closeShowChangePassword ({ commit }) {
      commit('closeShowChangePassword')
    },
    authorization ({ commit }, token) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${token}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch('https://auth.backend-tree.com/api/v1/user/profile', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            commit('setAccountData', data.data)
          } else {
            commit('completeSync', data.data)
          }
        })
        .catch((err) => console.log(err))
    },
    logOut ({ commit }) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${cookie.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        headers: requestHeaders
      }

      fetch('https://auth.backend-tree.com/api/v1/user/logout', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            commit('removeAccountData')
            commit('removeTokens')

            router.push('/')
          }
        })
        .catch((err) => console.log(err))
    }
  }
}

export default new Vuex.Store(storeConfig)
