module.exports.get = async (url, jwt = null, headers = null) => {
  const reqHeaders = new Headers()

  if (headers) {
    Object.keys(headers).forEach((k) => {
      reqHeaders.append(k, headers[k])
    })
  }

  if (jwt) {
    reqHeaders.append('Authorization', `Bearer ${jwt}`)
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: reqHeaders
  })

  const data = await response.json()

  return {
    statusCode: response.status,
    data
  }
}

module.exports.post = async (url, body, jwt = null, headers = null) => {
  const reqHeaders = new Headers()

  reqHeaders.append('Content-Type', 'application/json')

  if (headers) {
    Object.keys(headers).forEach((k) => {
      reqHeaders.append(k, headers[k])
    })
  }

  if (jwt) {
    reqHeaders.append('Authorization', `Bearer ${jwt}`)
  }

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: reqHeaders
  })

  const data = await response.json()

  return {
    statusCode: response.status,
    data
  }
}

module.exports.put = async (url, body, jwt = null) => {
  const reqHeaders = new Headers()

  reqHeaders.append('Content-Type', 'application/json')

  if (jwt) {
    reqHeaders.append('Authorization', `Bearer ${jwt}`)
  }

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: reqHeaders
  })

  const data = await response.json()

  return {
    statusCode: response.status,
    data
  }
}

module.exports.patch = async (url, body, jwt = null, headers = null) => {
  const reqHeaders = new Headers()

  reqHeaders.append('Content-Type', 'application/json')

  if (headers) {
    Object.keys(headers).forEach((k) => {
      reqHeaders.append(k, headers[k])
    })
  }

  if (jwt) {
    reqHeaders.append('Authorization', `Bearer ${jwt}`)
  }

  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: reqHeaders
  })

  const data = await response.json()

  return {
    statusCode: response.status,
    data
  }
}
