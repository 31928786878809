<template>
  <div class="app" :class="{ 'fixed': $store.state.showBurgerMenu }">
    <burgerMenu />
    <QiwiVerification v-if="qiwiVerification" :body="qiwiVerificationBody" :close="closeQiwiVerification" />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
      :class="{ 'translateRight': $store.state.showBurgerMenu, 'translateLeft': $store.state.showUserDropMenu }">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main>
        <div class="donat">
          <div v-if="loading" class="transfer-loader">
            <Loader />
          </div>
          <div class="donat__tree__right">
            <img src="./../../assets/images/donat/right-tree.png" alt="">
          </div>
          <div class="donat__tree__left">
            <img src="./../../assets/images/donat/left-tree.png" alt="">
          </div>
          <div class="donat__wrapper">
            <div class="donat-column">
<!--              <div class="donat-column__block rub_card active" @mouseenter="hoverPayment('rub_card', $event)"
                @mouseleave="unhoverPayment('rub_card', $event)" @click="choosePayment('rub_card', $event)">
                <img src="./../../assets/images/donat/rub_card-hover.png" alt="rub_card">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>-->
                <div class="donat-column__block payok active" @mouseenter="hoverPayment('payok', $event)"
                     @mouseleave="unhoverPayment('payok', $event)" @click="choosePayment('payok', $event)">
                    <img src="./../../assets/images/donat/payok.png" alt="payok">
                    <div class="donat-column__block__number">
                        <small></small>
                    </div>
                </div>
              <div v-if="false" class="donat-column__block lava" @mouseenter="hoverPayment('lava', $event)"
                   @mouseleave="unhoverPayment('lava', $event)" @click="choosePayment('lava', $event)">
                <img src="./../../assets/images/donat/lava.png" alt="lava">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
<!--              <div class="donat-column__block card" @mouseenter="hoverPayment('card', $event)"
                @mouseleave="unhoverPayment('card', $event)" @click="choosePayment('card', $event)">
                <img src="./../../assets/images/donat/card.png" alt="card">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>-->

<!--              <div class="donat-column__block sberbank" @mouseenter="hoverPayment('sberbank', $event)"-->
<!--                @mouseleave="unhoverPayment('sberbank', $event)" @click="choosePayment('sberbank', $event)">-->
<!--                <img src="./../../assets/images/donat/sberbank.png" alt="sberbank">-->
<!--                <div class="donat-column__block__number">-->
<!--                  <small></small>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="donat-column__block yoomoney" @mouseenter="hoverPayment('yoomoney', $event)"-->
<!--                @mouseleave="unhoverPayment('yoomoney', $event)" @click="choosePayment('yoomoney', $event)">-->
<!--                <img src="./../../assets/images/donat/yoomoney.png" alt="yoomoney">-->
<!--                <div class="donat-column__block__number">-->
<!--                  <small></small>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="donat-form">
              <div class="donat-form__payments">
                <div class="donat-form__payments__left">
                  <img src="./../../assets/images/donat/left-arrow.svg" alt="" @click="switchMethod(-1)">
                </div>
                <div class="donat-form__payments__center">
                  <img :src="require(`./../../assets/images/donat/${choosedPaymentMethod}.png`)" alt="">
                  <div class="donat-form__payments__center__commision" v-if="choosedPaymentMethod !== 'another'">
                    <p>{{ commision }}%</p>
                  </div>
                </div>
                <div class="donat-form__payments__right">
                  <img src="./../../assets/images/donat/right-arrow.svg" alt="" @click="switchMethod(1)">
                </div>
              </div>
              <div class="donat-form__wrapper">
                <div class="donat-form__payment">
                  <img :src="require(`./../../assets/images/donat/${choosedPaymentMethod}.png`)"
                    :alt="choosedPaymentMethod">
                </div>
                <div class="donat-form__header">
                  <h2>Пополнение</h2>
                </div>
                <div class="donat-form__input">
                  <div class="donat-form__input__mark">
                    <img src="./../../assets/images/donat/rub.svg" alt="">
                  </div>
                  <div class="donat-form__input__input">
                    <input type="number" placeholder="Сумма" @input="setPrice" :value="priceWithoutPercent"
                      @change="validatePrice" @keyup.enter="pay" :readonly="payments.status"
                      :class="payments.status ? 'inactive' : ''">
                  </div>
                  <div class="donat-form__input__info">
                    <img @click="showInputInfo = !showInputInfo" src="./../../assets/images/donat/info.svg" alt="info">
                    <p v-if="showInputInfo">Минимальная сумма пополнения 5 рублей</p>
                  </div>
                </div>
                <div class="donat-form__input">
                  <div class="donat-form__input__mark">
                    <img src="./../../assets/images/donat/percentage.svg" alt="">
                  </div>
                  <div class="donat-form__input__input">
                    <input type="text" placeholder="Сумма"
                      :value="(Number(priceWithoutPercent) / Number(percent)).toFixed(2) + ' ₽'"
                      :class="payments.status ? 'inactive' : ''" readonly>
                  </div>
                  <div v-if="choosedPaymentMethod !== 'another'" class="donat-form__input__addition">
                    <p v-if="choosedPaymentMethod !== 'card'">+{{ commision }}% комиссии</p>
                    <div class="payments-content__right__commision" v-if="choosedPaymentMethod === 'card'">
                      <p>Комиссия</p>
                      <p>RUB: 4.5% + 10₽</p>
                      <p>USD: 4.5% + 1.2$</p>
                      <p>EUR: 4.5% + 0.1€</p>
                    </div>
                  </div>
                  <div v-if="choosedPaymentMethod === 'another'" class="donat-form__input__info">
                    <img @click="showCommisionInfo = !showCommisionInfo" src="./../../assets/images/donat/info.svg"
                      alt="info">
                    <p v-if="showCommisionInfo">Комиссия неизвестна, вы получите на баланс меньше, чем {{
                      priceWithoutPercent }} RUB</p>
                  </div>
                </div>

                <div class="donat-form__input donat-form__input_fullsize donat-form__input_have-state-icon">
                  <div class="donat-form__input__mark">
                    <img src="./../../assets/images/donat/percentage.svg" alt="">
                  </div>
                  <div class="donat-form__input__input">
                    <input type="text" placeholder="Есть промокод?" v-model="promocode"
                      :class="payments.status ? 'inactive' : ''" @input="onPromocodeInput">

                    <img src="@/assets/images/donat/check.svg" alt="check" class="donat-form__input__input__icon"
                      v-if="promocodeState.show && promocodeState.status">
                    <img src="@/assets/images/donat/xmark.svg" alt="check" class="donat-form__input__input__icon"
                      v-else-if="promocodeState.show">

                    <p class="donat-form__input__input__subtext" v-if="promocodeState.show"
                      :class="{ error: !promocodeState.status }">{{ promocodeState.subtext }}</p>
                  </div>
                </div>

                <div class="donat-form__button">
                  <p :class="error.show && error.status === false ? '' : 'hide-error'">{{ error.message }}</p>
                  <button @click="pay">Пополнить</button>
                </div>
                <div class="donat-form__line">
                  <span></span>
                </div>
                <div class="donat-form__support">
                  <p>В случае возникновения проблем напишите в нашу <a href="https://vk.com/im?sel=-196995578"
                      target="_blank">службу поддержки</a></p>
                </div>
              </div>
            </div>
            <div class="donat-column">
<!--              <div class="donat-column__block alfabank" @mouseenter="hoverPayment('alfabank', $event)"-->
<!--                @mouseleave="unhoverPayment('alfabank', $event)" @click="choosePayment('alfabank', $event)">-->
<!--                <img src="./../../assets/images/donat/alfabank.png" alt="alfabank">-->
<!--                <div class="donat-column__block__number">-->
<!--                  <small></small>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="donat-column__block bitcoin" @mouseenter="hoverPayment('bitcoin', $event)"-->
<!--                @mouseleave="unhoverPayment('bitcoin', $event)" @click="choosePayment('bitcoin', $event)">-->
<!--                <img src="./../../assets/images/donat/bitcoin.png" alt="bitcoin">-->
<!--                <div class="donat-column__block__number">-->
<!--                  <small></small>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="donat-column__block tether" @mouseenter="hoverPayment('tether', $event)"-->
<!--                @mouseleave="unhoverPayment('tether', $event)" @click="choosePayment('tether', $event)">-->
<!--                <img src="./../../assets/images/donat/tether.png" alt="tether">-->
<!--                <div class="donat-column__block__number">-->
<!--                  <small></small>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="donat-column__block another" @mouseenter="hoverPayment('another', $event)"-->
<!--                @mouseleave="unhoverPayment('another', $event)" @click="choosePayment('another', $event)">-->
<!--                <img src="./../../assets/images/donat/another.png" alt="another">-->
<!--                <div class="donat-column__block__text">-->
<!--                  <p>прочие способы оплаты</p>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import './donat.scss'

import Navigation from '@/components/Navigation/Navigation'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import Auth from '@/components/Auth/Auth'
import Loader from '@/components/LoaderBar/LoaderBar'
import QiwiVerification from '@/components/QiwiVerification/QiwiVerification'

const DEFAULT_PRICE = 5
const LAVA_PRICE = 300

export default {
  name: 'Donat',
  components: {
    Navigation,
    AsideMenu,
    burgerMenu,
    userDropMenu,
    Loader,
    Auth,
    QiwiVerification
  },
  data () {
    return {
      choosedPaymentMethod: 'payok',
      choosedPaymentSystem: 'payok',
      priceWithoutPercent: DEFAULT_PRICE,
      percent: 0.92,
      commision: 8,
      commisionConfig: {},
      promocode: null,
      promocodeTimer: null,
      promocodeState: {
        show: false,
        status: false,
        subtext: 'Вы ввели неверный промокод!'
      },
      error: {
        status: false,
        show: false,
        message: 'none'
      },
      loading: true,
      showInputInfo: false,
      showCommisionInfo: false,
      payments: {
        status: false,
        data: {}
      },
      qiwiVerification: false,
      qiwiVerificationBody: {},
      qiwiBig: {},
      qiwiLow: {},
      mobileDirection: [
        // 'lava',
        'payok'
        // 'qiwi',
        // 'rub_card',
        // 'sberbank',
        // 'yoomoney',
        // 'card',
        // 'alfabank',
        // 'tether',
        // 'bitcoin',
        // 'another'
      ]
    }
  },
  computed: {
    currentMinPrice () {
      return this.choosedPaymentMethod === 'lava' ? LAVA_PRICE : this.choosedPaymentMethod === 'payok' ? 10 : DEFAULT_PRICE
    }
  },
  created () {
    if (this.$store.state.payments.order) {
      this.payments.status = true
      this.payments.data = this.$store.state.payments
    }

    if (this.payments.status) {
      this.priceWithoutPercent = this.payments.data.order.rub_amount
    }

    this.loading = true

    fetch('https://topups.backend-tree.com/api/v1/config/commissions')
      .then((res) => res.json())
      .then((data) => {
        this.loading = false

        data.forEach((commision) => {
          if (commision.name !== 'boleto' && commision.name !== 'mts') {
            this.commisionConfig[commision.name] = {
              commision: Math.round((1 - commision.value) * 100),
              percent: commision.value
            }
          }

          if (commision.name === 'qiwi') {
            this.commisionConfig.qiwi.commision = 8
            this.commisionConfig.qiwi.percent = 0.92
            this.qiwiBig = {
              commision: 8,
              percent: 0.92
            }
            this.qiwiLow = {
              commision: 8,
              percent: 0.92
            }
          }
        })

        this.commisionConfig.another = {
          commision: NaN,
          percent: NaN
        }

        this.commisionConfig.payok = {
          commision: 0,
          percent: 1
        }

        this.commisionConfig.rub_card = {
          commision: 6.9,
          percent: 0.931
        }

        this.commisionConfig.lava = {
          commision: 5,
          percent: 0.95
        }

        this.displayCommision()

        this.commision = this.commisionConfig[this.choosedPaymentMethod].commision
        this.percent = this.commisionConfig[this.choosedPaymentMethod].percent
      })
      .catch((err) => console.log(err))
  },
  destroyed () {
    this.$store.commit('clearPaymentData')
  },
  methods: {
    pay () {
      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      const promocode = (this.promocode && this.promocodeState.status) ? this.promocode : null

      const requestBody = {
        user_id: this.$store.state.account.userId || localStorage.getItem('user_id'),
        rubble_amount: parseFloat(parseFloat(this.priceWithoutPercent).toFixed(2)),
        payment_type: this.choosedPaymentMethod
      }

      if (promocode) {
        requestBody.promo = promocode
      }

      if (this.payments.status) {
        requestBody.order_id = this.payments.data.order._id
        requestBody.method_type = this.payments.data.methodType
        requestBody.is_premium = this.payments.data.isPremium
      }

      if (this.choosedPaymentMethod === 'uah_card') {
        requestBody.payment_type = null
        requestBody.payment_system = 'freekassa'
      } else if (this.choosedPaymentMethod === 'payok' ||
        this.choosedPaymentMethod === 'yoomoney') {
        requestBody.payment_type = null
        requestBody.payment_system = 'payok'
      } else if (this.choosedPaymentMethod === 'lava') {
        requestBody.payment_type = null
        requestBody.payment_system = 'lava'
      } else if ((this.choosedPaymentMethod === 'rub_card' && this.priceWithoutPercent <= 750)) {
        requestBody.payment_system = 'gamemoney'
      } else {
        requestBody.payment_system = 'gamemoney'
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      fetch('https://topups.backend-tree.com/api/v1/topups/create', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (!data.status) {
            const code = data.code
            if (code === 1) {
              this.incorrectPromocode('Промокод уже активирован')
              return
            }

            if (code === 2) {
              this.incorrectPromocode()
              return
            }
          }

          if (data.redirect_uri) {
            location.href = data.redirect_uri
            return
          }

          const formUrl = data.m ? 'https://pay.freekassa.ru/' : 'https://pay.gmpays.com/api/terminal/create'
          let formHTML = ''

          Object.keys(data).forEach((key) => {
            formHTML += `<input type="hidden" name="${key}" value="${data[key]}"/>`
          })

          const formBlock = document.createElement('form')
          formBlock.action = formUrl
          formBlock.method = data.m ? 'GET' : 'POST'
          formBlock.innerHTML = formHTML

          document.body.appendChild(formBlock)
          formBlock.submit()
        })
        .catch((err) => console.log(err))
    },
    onPromocodeInput () {
      if (!this.promocode) {
        this.promocodeState.show = false
        this.promocodeState.status = false
      }

      // Отмена предыдущего таймера
      if (this.promocodeTimer) {
        clearTimeout(this.promocodeTimer)
      }

      // Установка нового таймера
      this.promocodeTimer = setTimeout(() => {
        this.checkPromocode()
      }, 1000)
    },
    correctPromocode (factor) {
      const percentage = parseFloat(((factor - 1) * 100).toFixed(2))

      this.promocodeState.show = true
      this.promocodeState.status = true
      this.promocodeState.subtext = `+${percentage}% к пополнению`
    },
    incorrectPromocode (message = 'Вы ввели неверный промокод!') {
      this.promocodeState.show = true
      this.promocodeState.status = false
      this.promocodeState.subtext = message
    },
    checkPromocode () {
      if (!this.promocode) return

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(`https://topups.backend-tree.com/api/v1/topups/check_promo?promo=${this.promocode}`, requestParams)
        .then((res) => {
          if (!res.ok) {
            this.incorrectPromocode()
            return
          }

          return res.json()
        })
        .then((data) => {
          this.correctPromocode(data.factor)
        })
        .catch()
    },
    switchMethod (direction) {
      this.error.show = false
      const currentMethodIndex = this.mobileDirection.findIndex((m) => m === this.choosedPaymentMethod)

      if (direction === 1) {
        if (currentMethodIndex + 1 >= this.mobileDirection.length) {
          this.choosedPaymentMethod = this.mobileDirection[0]
        } else {
          this.choosedPaymentMethod = this.mobileDirection[currentMethodIndex + 1]
        }
      } else {
        if (currentMethodIndex - 1 < 0) {
          this.choosedPaymentMethod = this.mobileDirection[this.mobileDirection.length - 1]
        } else {
          this.choosedPaymentMethod = this.mobileDirection[currentMethodIndex - 1]
        }
      }

      if (!this.$store.state.payments.order) this.priceWithoutPercent = this.currentMinPrice
      this.commision = this.commisionConfig[this.choosedPaymentMethod].commision
      this.percent = this.commisionConfig[this.choosedPaymentMethod].percent
    },
    displayCommision () {
      Object.keys(this.commisionConfig).forEach((key) => {
        if (key === 'another') {
          return
        }

        const mainBlock = document.querySelector(`.donat-column__block.${key}`)

        if (mainBlock === null) {
          return
        }

        if (this.commisionConfig[key].commision === 0) {
          mainBlock.querySelector('.donat-column__block__number').style.display = 'none'
        }

        if (mainBlock) {
          const commisionBlock = mainBlock.querySelector('.donat-column__block__number small')

          console.log(mainBlock)

          commisionBlock.innerHTML = `${this.commisionConfig[key].commision}%`
        }
      })
    },
    setPrice (event) {
      this.priceWithoutPercent = Number(event.target.value)

      if (this.choosedPaymentMethod !== 'qiwi') {
        return
      }

      console.log(event.target.value)

      if (Number(event.target.value) > 200) {
        this.commisionConfig.qiwi = this.qiwiBig
      } else {
        this.commisionConfig.qiwi = this.qiwiLow
      }

      this.percent = this.commisionConfig.qiwi.percent
      this.commision = this.commisionConfig.qiwi.commision

      this.displayCommision()
    },
    hoverPayment (method, event) {
      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      img.src = require(`./../../assets/images/donat/${method}-hover.png`)
      mainBlock.classList.add('hover')
    },
    unhoverPayment (method, event) {
      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      if (mainBlock.classList.contains('active') === false) {
        img.src = require(`./../../assets/images/donat/${method}.png`)
      }

      mainBlock.classList.remove('hover')
    },
    choosePayment (method, event) {
      const currentActiveBlock = document.querySelector('.donat-column__block.active')

      if (currentActiveBlock) {
        currentActiveBlock.classList.remove('active')
        currentActiveBlock.childNodes[0].src = require(`./../../assets/images/donat/${currentActiveBlock.classList[1]}.png`)
      }

      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      img.src = require(`./../../assets/images/donat/${method}-hover.png`)
      mainBlock.classList.add('active')

      this.choosedPaymentMethod = method

      if (!this.$store.state.payments.order) this.priceWithoutPercent = this.currentMinPrice
      this.commision = this.commisionConfig[method].commision
      this.percent = this.commisionConfig[method].percent
    },
    validatePrice () {
      if (isNaN(this.priceWithoutPercent)) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Введите корректную сумму'
      } else if (this.priceWithoutPercent < this.currentMinPrice) {
        this.error.status = false
        this.error.show = true
        this.error.message = `Сумма пополнения должна быть не менее ${this.currentMinPrice} рублей`
      } else {
        this.error.status = true
        this.error.show = false
        this.error.message = 'none'
      }
    },
    closeQiwiVerification () {
      this.qiwiVerification = false
    }
  }
}
</script>
