<template>
  <div class="suppliers-wrapper">
    <div class="suppliers-wrapper__block">
      <div class="suppliers-wrapper__block-logo">
        <img src="../../assets/images/suppliersLogIn/logo.png" alt="logo">
      </div>
      <div class="suppliers-wrapper__block-form">
        <div class="filed-wrapper">
          <div class="suppliers-wrapper__block-form__field">
            <div class="suppliers-wrapper__block-form__field-img">
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.71 11.71C14.6904 10.9387 15.406 9.88092 15.7572 8.68394C16.1085 7.48697 16.0779 6.21027 15.6698 5.03147C15.2617 3.85267 14.4963 2.83039 13.4801 2.10686C12.4639 1.38332 11.2474 0.994507 10 0.994507C8.75255 0.994507 7.53611 1.38332 6.51993 2.10686C5.50374 2.83039 4.73834 3.85267 4.33021 5.03147C3.92208 6.21027 3.89151 7.48697 4.24276 8.68394C4.59401 9.88092 5.3096 10.9387 6.29 11.71C4.61007 12.383 3.14428 13.4994 2.04889 14.9399C0.953495 16.3805 0.26956 18.0913 0.0699967 19.89C0.0555513 20.0213 0.0671132 20.1542 0.104022 20.2811C0.140931 20.4079 0.202464 20.5263 0.285108 20.6293C0.452016 20.8375 0.69478 20.9708 0.959997 21C1.22521 21.0292 1.49116 20.9518 1.69932 20.7849C1.90749 20.618 2.04082 20.3752 2.07 20.11C2.28958 18.1552 3.22168 16.3498 4.68822 15.0388C6.15475 13.7278 8.0529 13.003 10.02 13.003C11.9871 13.003 13.8852 13.7278 15.3518 15.0388C16.8183 16.3498 17.7504 18.1552 17.97 20.11C17.9972 20.3557 18.1144 20.5827 18.2991 20.747C18.4838 20.9114 18.7228 21.0015 18.97 21H19.08C19.3421 20.9698 19.5817 20.8373 19.7466 20.6313C19.9114 20.4252 19.9881 20.1624 19.96 19.9C19.7595 18.0962 19.0719 16.381 17.9708 14.9382C16.8698 13.4954 15.3969 12.3795 13.71 11.71ZM10 11C9.20887 11 8.43551 10.7654 7.77772 10.3259C7.11992 9.88636 6.60723 9.26164 6.30448 8.53074C6.00173 7.79983 5.92251 6.99557 6.07686 6.21964C6.2312 5.44372 6.61216 4.73099 7.17157 4.17158C7.73098 3.61217 8.44371 3.2312 9.21964 3.07686C9.99556 2.92252 10.7998 3.00173 11.5307 3.30448C12.2616 3.60724 12.8863 4.11993 13.3259 4.77772C13.7654 5.43552 14 6.20888 14 7C14 8.06087 13.5786 9.07828 12.8284 9.82843C12.0783 10.5786 11.0609 11 10 11Z" fill="white"/>
              </svg>
            </div>
            <div class="suppliers-wrapper__block-form__field-wrap" :class="{wrong: isErrorLogin}">
              <div class="suppliers-wrapper__block-form__field-wrap__label">
                <p>LOGIN</p>
              </div>
              <input class="suppliers-wrapper__block-form__field-wrap__input" type="text">
            </div>
          </div>
          <p class="input-errors" :class="{showError: isErrorLogin}">Введённый логин неверный!</p>
        </div>
        <div class="filed-wrapper">
          <div class="suppliers-wrapper__block-form__field">
            <div class="suppliers-wrapper__block-form__field-img">
              <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 11C7.69555 10.9964 7.39732 11.0862 7.14544 11.2573C6.89357 11.4284 6.70015 11.6725 6.59121 11.9568C6.48228 12.2411 6.46306 12.552 6.53615 12.8476C6.60923 13.1431 6.77111 13.4092 7 13.61V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V13.61C9.22889 13.4092 9.39077 13.1431 9.46385 12.8476C9.53694 12.552 9.51772 12.2411 9.40879 11.9568C9.29985 11.6725 9.10643 11.4284 8.85456 11.2573C8.60268 11.0862 8.30445 10.9964 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="white"/>
              </svg>
            </div>
            <div class="suppliers-wrapper__block-form__field-wrap" :class="{wrong: isErrorPassword}">
              <div class="suppliers-wrapper__block-form__field-wrap__label">
                <p>Password</p>
              </div>
              <input class="suppliers-wrapper__block-form__field-wrap__input" type="password">
              <img @click="toggleView" class="suppliers-wrapper__block-form__field-wrap__view" src="../../assets/images/suppliersLogIn/unview.svg" alt="view">
            </div>
          </div>
          <p class="input-errors" :class="{showError: isErrorPassword}">Неверный пароль!</p>
        </div>
      </div>
      <div class="suppliers-wrapper__block-footer">
        <button>log in</button>
      </div>
    </div>
  </div>
</template>

<script>
import './suppliersLogIn.scss'

export default {
  name: 'SuppliersLogIn',
  data () {
    return {
      isErrorLogin: true,
      isErrorPassword: false
    }
  },
  methods: {
    toggleView (event) {
      const target = event.target
      const passwordInput = target.parentNode.querySelector('.suppliers-wrapper__block-form__field-wrap__input')

      console.log(passwordInput)

      if (passwordInput.type === 'password') {
        passwordInput.type = 'text'
        target.src = require('../../assets/images/suppliersLogIn/view.svg')
      } else if (passwordInput.type === 'text') {
        passwordInput.type = 'password'
        target.src = require('../../assets/images/suppliersLogIn/unview.svg')
      }
    }
  }
}
</script>
