<template>
  <div class="selectnews">
    <AsideMenu />
    <div class="selectnews__wrapper">
      <Navigation />
      <div class="selectnews__wrapper__main">
        <h3 class="selectnews__wrapper__main__title">Кстати,  реплицированные с зарубежных источников, современные исследования и по сей день остаются уделом.</h3>
        <div class="selectnews__wrapper__main__states">
          <div class="date">25 января 2021 г.</div>
          <div class="state">
            <img src="../../assets/images/news/view.svg" alt="">
            <p>1606</p>
          </div>
        </div>
        <img class="selectnews__wrapper__main__img" src="../../assets/images/news/selectnews.png" alt="">
        <p class="selectnews__wrapper__main__inf">Современные технологии достигли такого уровня, что сплочённость команды профессионалов создаёт необходимость включения в производственный план целого ряда внеочередных мероприятий с учётом комплекса новых предложений.</p>
        <p class="selectnews__wrapper__main__inf">В своём стремлении улучшить пользовательский опыт мы упускаем, что элементы политического процесса набирают популярность среди определенных слоев населения, а значит, должны быть разоблачены. Вот вам яркий пример современных тенденций - дальнейшее развитие различных форм деятельности выявляет срочную потребность инновационных методов управления процессами. Следует отметить, что сплочённость команды профессионалов представляет собой интересный эксперимент проверки укрепления моральных ценностей.</p>
        <RecomendedNews v-if="width > 500"/>
        <MiniRecomendedNews v-if="width <= 500"/>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import './selectnews.scss'

import AsideMenu from '@/components/asideMenu/AsideMenu'
import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer/Footer'
import RecomendedNews from '@/components/RecomendedNews/RecomendedNews'
import MiniRecomendedNews from '@/components/MiniRecomendedNews/MiniRecomendedNews'

export default {
  name: 'SelectNews',
  components: {
    AsideMenu,
    Navigation,
    Footer,
    RecomendedNews,
    MiniRecomendedNews
  },
  data () {
    return {
      width: document.documentElement.clientWidth
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.$forceUpdate()
      this.width = document.documentElement.clientWidth
    })
  }
}
</script>
