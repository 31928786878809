// const PT_COUNTRIES = ['BR', 'PT']
// const EU_COUNRIES = ['AU', 'BE', 'BG', 'GB', 'HU', 'DE', 'GR', 'DK', 'IE', 'ES', 'IT', 'LV', 'LT', 'LU', 'MT', 'LI',
//   'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'FI', 'FR', 'HR', 'CZ', 'SE', 'EE', 'BA', 'IE', 'IS', 'NO']
const RU_COUNTRIES = ['AZ', 'AM', 'BY', 'KZ', 'KG', 'MD', 'RU', 'TJ', 'UZ', 'TM', 'GE', 'UA']

const isCountryInRuRegion = (country) => {
  return RU_COUNTRIES.includes(country.toUpperCase())
}
const isCountryRu = (country) => {
  return ['RU'].includes(country.toUpperCase())
}

module.exports = { isCountryInRuRegion, isCountryRu }
