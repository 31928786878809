<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main>
        <div class="donat">
          <div v-if="loading" class="transfer-loader">
            <Loader />
          </div>
          <div class="donat__tree__right">
            <img src="./../../assets/images/donat/right-tree.png" alt="">
          </div>
          <div class="donat__tree__left">
            <img src="./../../assets/images/donat/left-tree.png" alt="">
          </div>
          <div class="donat__wrapper">
            <div class="donat-column">
              <div
                class="donat-column__block qiwi active"
                @mouseenter="hoverPayment('qiwi', $event)"
                @mouseleave="unhoverPayment('qiwi', $event)"
                @click="choosePayment('qiwi', $event)"
              >
                <img src="./../../assets/images/donat/qiwi-hover.png" alt="qiwi">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block card"
                @mouseenter="hoverPayment('card', $event)"
                @mouseleave="unhoverPayment('card', $event)"
                @click="choosePayment('card', $event)"
              >
                <img src="./../../assets/images/donat/card.png" alt="cards">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block free-kassa"
                @mouseenter="hoverPayment('free-kassa', $event)"
                @mouseleave="unhoverPayment('free-kassa', $event)"
                @click="choosePayment('free-kassa', $event)"
              >
                <img src="./../../assets/images/donat/free-kassa.png" alt="free-kassa">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block bitcoin"
                @mouseenter="hoverPayment('bitcoin', $event)"
                @mouseleave="unhoverPayment('bitcoin', $event)"
                @click="choosePayment('bitcoin', $event)"
              >
                <img src="./../../assets/images/donat/bitcoin.png" alt="bitcoin">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <!-- <div
                class="donat-column__block mts"
                @mouseenter="hoverPayment('mts', $event)"
                @mouseleave="unhoverPayment('mts', $event)"
                @click="choosePayment('mts', $event)"
              >
                <img src="./../../assets/images/donat/mts.png" alt="mts">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div> -->
              <div
                class="donat-column__block beeline"
                @mouseenter="hoverPayment('beeline', $event)"
                @mouseleave="unhoverPayment('beeline', $event)"
                @click="choosePayment('beeline', $event)"
              >
                <img src="./../../assets/images/donat/beeline.png" alt="beeline">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
            </div>
            <div class="donat-form">
              <div class="donat-form__payments">
                <div class="donat-form__payments__left">
                  <img
                    src="./../../assets/images/donat/left-arrow.svg"
                    alt=""
                    @click="switchMethod(-1)"
                  >
                </div>
                <div class="donat-form__payments__center">
                  <img :src="require(`./../../assets/images/donat/${choosedPaymentMethod}.png`)" alt="">
                  <div class="donat-form__payments__center__commision" v-if="choosedPaymentMethod !== 'another'">
                    <p>{{ commision }}%</p>
                  </div>
                </div>
                <div class="donat-form__payments__right">
                  <img
                    src="./../../assets/images/donat/right-arrow.svg"
                    alt=""
                    @click="switchMethod(1)"
                  >
                </div>
              </div>
              <div class="donat-form__wrapper">
                <div class="donat-form__payment">
                  <img :src="require(`./../../assets/images/donat/${choosedPaymentMethod}.png`)" :alt="choosedPaymentMethod">
                </div>
                <div class="donat-form__header">
                  <h2>Пополнение</h2>
                </div>
                <div class="donat-form__input">
                  <div class="donat-form__input__mark">
                    <img src="./../../assets/images/donat/rub.svg" alt="">
                  </div>
                  <div class="donat-form__input__input">
                    <input
                      type="number"
                      placeholder="Сумма"
                      @input="setPrice"
                      :value="priceWithoutPercent"
                      @change="validatePrice"
                      @keyup.enter="pay"
                      :readonly="payments.status"
                      :class="payments.status ? 'inactive' : ''"
                    >
                  </div>
                  <div class="donat-form__input__info">
                    <img @click="showInputInfo = !showInputInfo" src="./../../assets/images/donat/info.svg" alt="info">
                    <p v-if="showInputInfo">Минимальная сумма пополнения 5 рублей</p>
                  </div>
                </div>
                <div class="donat-form__input">
                  <div class="donat-form__input__mark">
                    <img src="./../../assets/images/donat/percentage.svg" alt="">
                  </div>
                  <div class="donat-form__input__input">
                    <input
                      type="text"
                      placeholder="Сумма"
                      :value="(Number(priceWithoutPercent) / Number(percent)).toFixed(2) + ' ₽'"
                      :class="payments.status ? 'inactive' : ''"
                      readonly
                    >
                  </div>
                  <div v-if="choosedPaymentMethod !== 'another'" class="donat-form__input__addition">
                    <p>+{{ commision }}% комиссии</p>
                  </div>
                  <div v-if="choosedPaymentMethod === 'another'" class="donat-form__input__info">
                    <img @click="showCommisionInfo = !showCommisionInfo" src="./../../assets/images/donat/info.svg" alt="info">
                    <p v-if="showCommisionInfo">Комиссия неизвестна, вы получите на баланс меньше, чем {{ priceWithoutPercent }} RUB</p>
                  </div>
                </div>

                <div class="donat-form__button">
                  <p :class="error.show && error.status === false ? '' : 'hide-error'">{{ error.message }}</p>
                  <button @click="pay">Пополнить</button>
                </div>
                <div class="donat-form__line">
                  <span></span>
                </div>
                <div class="donat-form__support">
                  <p>В случае возникновения проблем напишите в нашу <a href="https://vk.com/im?sel=-196995578" target="_blank">службу поддержки</a></p>
                </div>
              </div>
            </div>
            <div class="donat-column">
              <div
                class="donat-column__block advcash"
                @mouseenter="hoverPayment('advcash', $event)"
                @mouseleave="unhoverPayment('advcash', $event)"
                @click="choosePayment('advcash', $event)"
              >
                <img src="./../../assets/images/donat/advcash.png" alt="advcash">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block alfabank"
                @mouseenter="hoverPayment('alfabank', $event)"
                @mouseleave="unhoverPayment('alfabank', $event)"
                @click="choosePayment('alfabank', $event)"
              >
                <img src="./../../assets/images/donat/alfabank.png" alt="alfabank">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block alipay"
                @mouseenter="hoverPayment('alipay', $event)"
                @mouseleave="unhoverPayment('alipay', $event)"
                @click="choosePayment('alipay', $event)"
              >
                <img src="./../../assets/images/donat/alipay.png" alt="alipay">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block ethereum"
                @mouseenter="hoverPayment('ethereum', $event)"
                @mouseleave="unhoverPayment('ethereum', $event)"
                @click="choosePayment('ethereum', $event)"
              >
                <img src="./../../assets/images/donat/ethereum.png" alt="ethereum">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block kassa24"
                @mouseenter="hoverPayment('kassa24', $event)"
                @mouseleave="unhoverPayment('kassa24', $event)"
                @click="choosePayment('kassa24', $event)"
              >
                <img src="./../../assets/images/donat/kassa24.png" alt="kassa24">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
            </div>
            <div class="donat-column">
              <div
                class="donat-column__block skinpay"
                @mouseenter="hoverPayment('skinpay', $event)"
                @mouseleave="unhoverPayment('skinpay', $event)"
                @click="choosePayment('skinpay', $event)"
              >
                <img src="./../../assets/images/donat/skinpay.png" alt="skinpay">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block promsviaz"
                @mouseenter="hoverPayment('promsviaz', $event)"
                @mouseleave="unhoverPayment('promsviaz', $event)"
                @click="choosePayment('promsviaz', $event)"
              >
                <img src="./../../assets/images/donat/promsviaz.png" alt="promsviaz">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block tether"
                @mouseenter="hoverPayment('tether', $event)"
                @mouseleave="unhoverPayment('tether', $event)"
                @click="choosePayment('tether', $event)"
              >
                <img src="./../../assets/images/donat/tether.png" alt="tether">
                <div class="donat-column__block__number">
                  <small></small>
                </div>
              </div>
              <div
                class="donat-column__block another"
                @mouseenter="hoverPayment('another', $event)"
                @mouseleave="unhoverPayment('another', $event)"
                @click="choosePayment('another', $event)"
              >
                <img src="./../../assets/images/donat/another.png" alt="another">
                <div class="donat-column__block__text">
                  <p>прочие способы оплаты</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import './donat.scss'

import Navigation from '@/components/Navigation/Navigation'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import Auth from '@/components/Auth/Auth'
import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'Donat',
  components: {
    Navigation,
    AsideMenu,
    burgerMenu,
    userDropMenu,
    Loader,
    Auth
  },
  data () {
    return {
      choosedPaymentMethod: 'qiwi',
      choosedPaymentSystem: 'gamemoney',
      priceWithoutPercent: 5,
      percent: 0.92,
      commision: 8,
      commisionConfig: {},
      error: {
        status: false,
        show: false,
        message: 'none'
      },
      loading: true,
      showInputInfo: false,
      showCommisionInfo: false,
      payments: {
        status: false,
        data: {}
      }
    }
  },
  created () {
    if (this.$store.state.payments.order) {
      this.payments.status = true
      this.payments.data = this.$store.state.payments
    }

    if (this.payments.status) {
      this.priceWithoutPercent = this.payments.data.order.rub_amount
    }

    this.loading = true

    fetch('https://topups.backend-tree.com/api/v1/config/commissions')
      .then((res) => res.json())
      .then((data) => {
        this.loading = false

        data.forEach((commision) => {
          if (commision.name !== 'boleto' && commision.name !== 'mts') {
            this.commisionConfig[commision.name] = {
              commision: Math.round((1 - commision.value) * 100),
              percent: commision.value
            }
          }
        })

        this.commisionConfig.another = {
          commision: NaN,
          percent: NaN
        }

        this.commisionConfig['free-kassa'] = {
          commision: 0,
          percent: 1
        }

        this.displayCommision()

        this.commision = this.commisionConfig[this.choosedPaymentMethod].commision
        this.percent = this.commisionConfig[this.choosedPaymentMethod].percent
      })
      .catch((err) => console.log(err))
  },
  destroyed () {
    this.$store.commit('clearPaymentData')
  },
  methods: {
    pay () {
      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      const requestBody = {
        user_id: this.$store.state.account.userId || localStorage.getItem('user_id'),
        rubble_amount: parseFloat(parseFloat(this.priceWithoutPercent).toFixed(2)),
        payment_type: this.choosedPaymentMethod
      }

      if (this.choosedPaymentMethod === 'free-kassa' || (this.choosedPaymentMethod === 'qiwi' && this.priceWithoutPercent < 200)) {
        requestBody.payment_type = null
        requestBody.payment_system = 'free-kassa'
      } else if (this.choosedPaymentMethod === 'enot') {
        requestBody.payment_type = null
        requestBody.payment_system = 'enot'
      } else {
        requestBody.payment_system = 'gamemoney'
      }

      if (this.payments.status) {
        requestBody.order_id = this.payments.data.order._id
        requestBody.method_type = this.payments.data.methodType
        requestBody.is_premium = this.payments.data.isPremium
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      fetch('https://topups.backend-tree.com/api/v1/topups/create', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.redirect_uri) {
            location.href = data.redirect_uri
            return
          }
          const formUrl = 'https://pay.gmpays.com/api/terminal/create'
          let formHTML = ''

          Object.keys(data).forEach((key) => {
            formHTML += `<input type="hidden" name="${key}" value="${data[key]}"/>`
          })

          const formBlock = document.createElement('form')
          formBlock.action = formUrl
          formBlock.method = 'POST'

          formBlock.innerHTML = formHTML

          document.body.appendChild(formBlock)
          formBlock.submit()
        })
        .catch((err) => console.log(err))
    },
    switchMethod (direction) {
      const allMethods = Object.keys(this.commisionConfig)
      const currentMethod = this.choosedPaymentMethod
      const currentMethodIndex = allMethods.indexOf(currentMethod)

      if (direction === 1) {
        if (currentMethodIndex + 1 >= allMethods.length) {
          this.choosedPaymentMethod = allMethods[0]
        } else {
          this.choosedPaymentMethod = allMethods[currentMethodIndex + 1]
        }
      } else {
        if (currentMethodIndex - 1 < 0) {
          this.choosedPaymentMethod = allMethods[allMethods.length - 1]
        } else {
          this.choosedPaymentMethod = allMethods[currentMethodIndex - 1]
        }
      }

      this.commision = this.commisionConfig[this.choosedPaymentMethod].commision
      this.percent = this.commisionConfig[this.choosedPaymentMethod].percent
    },
    displayCommision () {
      Object.keys(this.commisionConfig).forEach((key) => {
        if (key === 'another') {
          return
        }

        const mainBlock = document.querySelector(`.donat-column__block.${key}`)

        if (mainBlock) {
          const commisionBlock = mainBlock.querySelector('.donat-column__block__number small')

          commisionBlock.innerHTML = `${this.commisionConfig[key].commision}%`
        }
      })
    },
    setPrice (event) {
      this.priceWithoutPercent = Number(event.target.value)
    },
    hoverPayment (method, event) {
      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      img.src = require(`./../../assets/images/donat/${method}-hover.png`)
      mainBlock.classList.add('hover')
    },
    unhoverPayment (method, event) {
      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      if (mainBlock.classList.contains('active') === false) {
        img.src = require(`./../../assets/images/donat/${method}.png`)
      }

      mainBlock.classList.remove('hover')
    },
    choosePayment (method, event) {
      const currentActiveBlock = document.querySelector('.donat-column__block.active')

      if (currentActiveBlock) {
        currentActiveBlock.classList.remove('active')
        currentActiveBlock.childNodes[0].src = require(`./../../assets/images/donat/${currentActiveBlock.classList[1]}.png`)
      }

      const mainBlock = event.target.closest('.donat-column__block')
      const img = mainBlock.childNodes[0]

      img.src = require(`./../../assets/images/donat/${method}-hover.png`)
      mainBlock.classList.add('active')

      this.choosedPaymentMethod = method

      this.commision = this.commisionConfig[method].commision
      this.percent = this.commisionConfig[method].percent
    },
    validatePrice () {
      if (isNaN(this.priceWithoutPercent)) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Введите корректную сумму'
      } else if (this.priceWithoutPercent < 5) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'Сумма пополнения должна быть не менее 5 рублей'
      } else {
        this.error.status = true
        this.error.show = false
        this.error.message = 'none'
      }
    }
  }
}
</script>
