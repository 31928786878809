<template>
  <div class="captcha-block">
    <div class="captcha-wrapper">
      <div id="turnstile-box" v-show="captchaType === 'cloudflare'">
        <LoaderUi v-if="!isCaptchaLoaded" />
      </div>
      <div class="hide-captcha-btn" @click="$emit('hideCaptcha')">
        <img src="../../assets/images/captcha/close.svg" alt="close">
        <img class="captcha-btn-arrow" src="../../assets/images/captcha/right-arrow.svg" alt="Come back">
        <span>Вернуться назад</span>
      </div>
    </div>
  </div>
</template>

<script>
import './captcha.scss'

import LoaderUi from '../LoaderUI/LoaderUI.vue'

const SCRIPT_SRC = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'
const SITE_KEY = '0x4AAAAAAAFT-dxmsjeSfOMk'

export default {
  name: 'CaptchaComponent',
  components: {
    LoaderUi
  },
  emits: [
    'onVerify',
    'hideCaptcha'
  ],
  data () {
    return {
      isCaptchaLoaded: false,
      timeToContinue: 8,
      captchaToken: '',
      captchaId: '',
      captchaType: ''
    }
  },
  mounted () {
    window.onloadTurnstileCallback = () => {
      this.renderCaptcha()
    }

    this.enableCloudFlareCaptcha()
  },
  methods: {
    enableCloudFlareCaptcha () {
      this.captchaType = 'cloudflare'

      if (window.turnstile === null || !window.turnstile) {
        this.loadCaptchaScript()
      } else {
        this.renderCaptcha()
      }
    },
    renderCaptcha () {
      if (!window.turnstile) {
        return
      }

      window.turnstile.render('#turnstile-box', {
        sitekey: SITE_KEY,
        callback: (token) => {
          this.$emit('onVerify', token)
        }
      })

      setTimeout(() => {
        this.isCaptchaLoaded = true
      }, 50)
    },
    loadCaptchaScript () {
      const script = document.createElement('script')
      script.src = SCRIPT_SRC
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    }
  }
}
</script>
