<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main class="group-main">
        <div class="group-main__first">
          <SearchUserModal />
          <SelectedUsersModal />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import './groupMethod.scss'

import Navigation from '@/components/Navigation/Navigation'
import Auth from '@/components/Auth/Auth'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'

import SearchUserModal from '@/components/GroupMethod/SearchUserModal/SearchUserModal'
import SelectedUsersModal from '@/components/GroupMethod/SelectedUsersModal/SelectedUsersModal'

export default {
  name: 'GroupMethod',
  components: {
    AsideMenu,
    burgerMenu,
    userDropMenu,
    Navigation,
    Auth,
    SearchUserModal,
    SelectedUsersModal
  }
}
</script>
