<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main>
        <div class="faq">
          <div class="faq-header">
            <h2>FAQ</h2>
          </div>
          <div
            class="faq-content"
            :class="{'scroll' : isScroll && visibleScroll}"
          >
            <div class="faq-content__column">
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>БЕСПЛАТНЫЕ РОБУКСЫ!</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Чтобы получить робуксы бесплатно участвуйте в розыгрышах в нашей группе ВКонтакте!</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>Почему большие комиссии?</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>К сожалению комиссии на пополнение выставляем не мы, а платёжная система, постарайтесь выбрать удобный для вас способ с наименьшей комиссией!</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>Мне не отвечают в поддержке!</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Обычное время ожидания ответа в поддержке занимает 24ч, но в особо загруженные дни может вырасти до 72ч.</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>Как купить робуксы?</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>На странице каждого метода есть инструкция для покупки робуксов, найти её можно по вопросительному знаку в углу экрана.</p>
                </div>
              </div>
            </div>
            <div class="faq-content__column">
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>Почему курс большой?</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Мы стараемся продавать самые дешёвые робуксы, насколько это возможно, но не всё зависит от нас(</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>Мне не пришли робуксы!</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Будьте внимательны, у каждого метода своё время ожидания робуксов, если вы всё сделали по инструкции, то вам обязательно всё придёт! Если время ожидания превысило ожидаемое, напишите в поддержку через личные сообщения нашей группы ВКонтакте.</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper"
                  @click="toggleAsk"
                >
                  <p>сайт имеет другие ссылки?</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Не попадитесь на мошенников, они делают копии нашего сайта и группы, но не выдают робуксы никогда! У нашего сайта один адрес: rbxtree.pro</p>
                </div>
              </div>
              <div class="faq-content__column__block">
                <div
                  class="faq-content__column__block__wrapper last-faq"
                  @click="toggleAsk"
                >
                  <p>МОЖНО ЛИ СДЕЛАТЬ НЕСКОЛЬКО ЗАКАЗОВ ПОДРЯД?</p>
                  <img class="faq-arrow" src="./../../assets/images/faq/arrow.png" alt="arrow">
                </div>
                <div class="faq-content__column__block__text hide animate__animated">
                  <p>Да, Вы можете сделать несколько заказов подряд, не дожидаясь их получения.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="faq-line"></div>
          <div class="faq-button">
            <a href="https://vk.com/im?sel=-196995578" target="_blank">
              <img src="./../../assets/images/faq/headphones.svg">
              <span>задать вопрос</span>
            </a>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import 'animate.css'
import './faq.scss'

import Navigation from '@/components/Navigation/Navigation'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import Auth from '@/components/Auth/Auth'
// import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'History',
  components: {
    Navigation,
    AsideMenu,
    burgerMenu,
    Auth,
    userDropMenu
    // Loader
  },
  metaInfo: {
    title: 'Часто задаваемые вопросы интернет-магазина робуксов «RBXTREE»',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'FAQ — ответы на самые часто задаваемые вопросы о робуксах.'
    }]
  },
  data () {
    return {
      isAnimatePlaying: false,
      isScroll: true,
      visibleScroll: false
    }
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [{
        '@type': 'Question',
        name: 'Бесплатные робуксы',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Чтобы получить робуксы бесплатно участвуйте в розыгрышах в нашей группе ВКонтакте!'
        }
      }, {
        '@type': 'Question',
        name: 'Почему большие комиссии?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'К сожалению комиссии на пополнение выставляем не мы, а платёжная система, постарайтесь выбрать удобный для вас способ с наименьшей комиссией!'
        }
      }, {
        '@type': 'Question',
        name: 'Мне не отвечают в поддержке',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Обычное время ожидания ответа в поддержке занимает 24ч, но в особо загруженные дни может вырасти до 72ч.'
        }
      }, {
        '@type': 'Question',
        name: 'Как купить робуксы?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'На странице каждого метода есть инструкция для покупки робуксов, найти её можно по вопросительному знаку в углу экрана.'
        }
      }, {
        '@type': 'Question',
        name: 'Почему курс большой?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Мы стараемся продавать самые дешёвые робуксы, насколько это возможно, но не всё зависит от нас'
        }
      }, {
        '@type': 'Question',
        name: 'Мне не пришли робуксы',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Будьте внимательны, у каждого метода своё время ожидания робуксов, если вы всё сделали по инструкции, то вам обязательно всё придёт! Если время ожидания превысило ожидаемое, напишите в поддержку через личные сообщения нашей группы ВКонтакте.'
        }
      }, {
        '@type': 'Question',
        name: 'У сайта есть другие адреса?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Не попадитесь на мошенников, они делают копии нашего сайта и группы, но не выдают робуксы никогда! У нашего сайта один адрес: rbxtree.com'
        }
      }, {
        '@type': 'Question',
        name: 'Можно ли сделать несколько заказов подряд?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Да, Вы можете сделать несколько заказов подряд, не дожидаясь их получения.'
        }
      }]
    }
  },
  created () {

  },
  methods: {
    closeArrow (arrows) {
      arrows.forEach(arrow => {
        if (arrow.classList.contains('arrow_up')) arrow.classList.remove('arrow_up')
      })
    },
    toggleAsk (event) {
      if (this.isAnimatePlaying) {
        return
      }

      const oppenedText = document.querySelector('.faq-content__column__block__text.open')
      const wrapper = event.target.closest('.faq-content__column__block__wrapper')
      const arrows = document.querySelectorAll('.faq-arrow')
      const currentArrow = wrapper.querySelector('img')
      const text = wrapper.parentNode.childNodes[1]

      if (oppenedText !== null) {
        oppenedText.classList.remove('animate__fadeInDown')
        oppenedText.classList.add('animate__fadeOutUp')
        oppenedText.style.position = 'absolute'
        this.isAnimatePlaying = true
        this.closeArrow(arrows)
        currentArrow.classList.add('arrow_up')

        setTimeout(() => {
          oppenedText.classList.remove('animate__fadeOutUp')
          oppenedText.classList.add('hide')
          oppenedText.style.position = 'static'
          this.isAnimatePlaying = false
          oppenedText.classList.remove('open')
        }, 490)
      }

      if (text.classList.contains('hide')) {
        text.classList.remove('hide')
        text.classList.add('animate__fadeInDown')
        text.classList.add('open')
        currentArrow.classList.add('arrow_up')
        this.visibleScroll = true
      } else {
        text.classList.remove('animate__fadeInDown')
        text.classList.add('animate__fadeOutUp')
        text.style.position = 'absolute'
        currentArrow.classList.remove('arrow_up')
        this.isAnimatePlaying = true
        this.visibleScroll = false

        setTimeout(() => {
          text.classList.remove('animate__fadeOutUp')
          text.classList.add('hide')
          text.style.position = 'static'
          this.isAnimatePlaying = false
          text.classList.remove('.open')
        }, 490)
      }
    }
  },
  mounted () {
    if (document.body.clientWidth <= 1040) {
      this.isScroll = true
    }
  }
}
</script>
