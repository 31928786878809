<template>
  <div class="gallery">
    <div class="hint" v-if="false">
      <div class="hint-border">
        <div class="gallery-hint">
          <p class="gallery-hint__title">Внимание!</p>
          <p class="gallery-hint__content">На данный момент все робуксы распроданы!</p>
        </div>
      </div>
    </div>
    <router-link to="/fastpass" class="gallery__item as">
      <img class="as__branch" src="../../assets/images/gallery/as-branch.png" alt="">
      <!-- <img class="as__sn" src="../../assets/images/gallery/method-fastpass-sn.svg" alt=""> -->
      <img class="as__sn" src="../../assets/images/gallery/method-fastpass-available.svg" alt="">

      <div class="item-wrap">
        <!--        <div class="gallery__item-btn as">-->
        <!--          <div class="border">-->
        <!--            <div class="btn-block">-->
        <!--              <p>купить</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="background-block">
          <div class="mask"></div>
          <img src="../../assets/images/gallery/as-bg-2560.png" alt="">
        </div>
      </div>
    </router-link>
    <router-link to="/transfer" class="gallery__item top">
      <img class="back__branch" src="../../assets/images/gallery/top-branch.png" alt="">
      <img class="top__sn" src="../../assets/images/gallery/group-sn.png" alt="">
      <div class="item-wrap">
        <div class="gallery__item-btn top">
          <div class="border">
            <div class="btn-block">
              <img src="../../assets/images/gallery/cart.svg" alt="cart">
              <p>купить</p>
            </div>
          </div>
        </div>
        <div class="background-block">
          <div class="mask"></div>
          <picture>
            <source srcset="../../assets/images/gallery/top-bg-2560.webp" type="image/webp">
            <img src="../../assets/images/gallery/top-bg-2560.png" alt="">
          </picture>
        </div>
      </div>
    </router-link>
    <router-link to="/premium" class="gallery__item middle">
      <img class="middle__sn" src="../../assets/images/gallery/middle-sn-none.png" v-if="!premiumAvailable" alt="">
      <img class="middle__sn" src="../../assets/images/gallery/middle-sn-available.png" v-else alt="">
      <div class="item-wrap">
        <!--        <div class="gallery__item-btn">-->
        <!--          <div class="border">-->
        <!--            <div class="btn-block">-->
        <!--              <p>купить</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="background-block">
          <div class="mask"></div>
          <img src="../../assets/images/gallery/middle-bg-2560.png" alt="">
        </div>
      </div>
    </router-link>

    <div class="gallery__item right">
      <img class="right__sn" src="../../assets/images/gallery/as-sn-none.png" v-if="!logpassAvailable" alt="">
      <img class="right__sn" src="../../assets/images/gallery/as-sn-ready.png" v-else alt="">
      <div class="item-wrap">
        <!-- <div class="gallery__item-btn top" @click="goToFastpass">
          <div class="border">
            <div class="btn-block">
              <img src="../../assets/images/gallery/cart.svg" alt="cart">
              <p>купить</p>
            </div>
          </div>
        </div> -->
        <div class="background-block">
          <div class="mask"></div>
          <img src="../../assets/images/gallery/right-bg-2560.png" alt="">
        </div>
      </div>
    </div>

    <!-- <div class="gallery__item right">
      <img class="right__sn" src="../../assets/images/gallery/method-fastpass-sn.svg" alt="">
      <div class="item-wrap">
        <div class="gallery__item-btn top" @click="goToFastpass">
          <div class="border">
            <div class="btn-block">
              <img src="../../assets/images/gallery/cart.svg" alt="cart">
              <p>купить</p>
            </div>
          </div>
        </div>
        <div class="background-block">
          <div class="mask"></div>
          <img src="../../assets/images/gallery/right-bg-2560.png" alt="">
        </div>
      </div>
    </div> -->
    <div class="gallery__item bottom">
      <img class="bottom__sn" src="../../assets/images/gallery/bottom-sn.png" alt="">
      <div class="item-wrap">
        <div class="background-block">
          <div class="mask bottom"></div>
          <img src="../../assets/images/gallery/bottom-bg-2560.png" alt="">
        </div>
      </div>
    </div>
    <div class="gallery__item bottom2">
      <img class="bottom2__sn" src="../../assets/images/gallery/bottom2-sn.png" alt="">
      <div class="item-wrap">
        <div class="background-block">
          <div class="mask bottom"></div>
          <img src="../../assets/images/gallery/bottom2-bg-2560.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './gallery.scss'

export default {
  name: 'Gallery',
  data () {
    return {
      logpassAvailable: false,
      premiumAvailable: false
    }
  },
  created () {
    this.areSalesAvailable((data) => {
      this.logpassAvailable = data.are_sales_available
    }, 'logpass_normal', false)

    this.areSalesAvailable((data) => {
      this.premiumAvailable = data.are_sales_available
    }, 'premium', false)
  },
  methods: {
    goToLogPass () {
      this.areSalesAvailable((data) => {
        this.logpassAvailable = data.are_sales_available

        if (data.are_sales_available) {
          this.$router.push('/logpass')
        }
      }, 'logpass_normal', false)
    },
    goToPremium () {
      this.areSalesAvailable((data) => {
        this.logpassAvailable = data.are_sales_available

        if (data.are_sales_available) {
          this.$router.push('/logpass')
        }
      }, 'premium', false)
    },
    goToFastpass () {
      this.$router.push('/fastpass')
    },
    areSalesAvailable (cb, method, sync = true) {
      if (sync) {
        this.$store.state.additionalSync.push(true)
      }

      fetch(`https://logpass.backend-tree.com/api/v1/orders/are_sales_available?method=${method}`)
        .then((res) => res.json())
        .then((data) => {
          cb(data)

          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
        .catch(() => {
          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
    }
  }
}
</script>
