<template>
  <div class="gift">
    <div
      class="gift-closed animate__animated animate__bounceInRight"
      v-show="showClosedGift"
      @click="openGiftModal"
    >
      <div class="gift-closed__close" @click="closeClosedGift">
        <img src="./../../assets/images/Gift/close.svg" alt="close">
      </div>
      <div class="gift-closed__present">
        <img src="./../../assets/images/Gift/present.svg" alt="present">
      </div>
      <div class="gift-closed__text">
        <p>УЧАСТВУЙ В КОНКУРСЕ!</p>
        <small>НАЖМИТЕ ДЛЯ ПРОСМОТРА</small>
      </div>
    </div>
    <div class="gift-mobile-closed">
      <div class="gift-mobile-closed__image" @click="openGiftModal">
        <img src="./../../assets/images/Gift/present-mobile.svg" alt="" class="animate__animated animate__headShake animate__delay-4s animate__repeat-2">
      </div>
      <div
        class="gift-mobile-closed__text animate__animated"
        v-show="showMobileText"
        :class="{ 'animate__bounceOutRight': showMobileTextCloseAnimation, 'animate__bounceInRight': showMobileText }"
      >
        <p>УЧАСТВУЙ В КОНКУРСЕ!</p>
      </div>
    </div>
    <transition name="fade">
      <div
        class="gift-opened"
        v-show="showGiftModal"
      >
        <div class="gift-opened__modal">
          <div class="gift-opened__modal__close" @click="closeGiftModal">
            <img src="./../../assets/images/Gift/close-modal.svg" alt="close">
          </div>
          <div class="gift-opened__modal__header">
            <h2>ВЗРЫВНОЙ КОНКУРС НА 3000R$!</h2>
          </div>
          <p class="gift-opened__modal__help">У нас появился свой телеграм канал и в честь этого мы устраиваем конкурс! Скорее беги принимать участие!</p>
          <div class="gift-opened__modal__blocks">
            <a
              class="gift-opened__modal__blocks__block tg"
              target="_blank"
              href="https://t.me/rbxtreegg"
            >
              <div class="gift-opened__modal__blocks__block__icon tg">
                <img src="./../../assets/images/Gift/tg.svg" alt="tg">
              </div>
              <div class="gift-opened__modal__blocks__block__text">
                <p>ПРИНЯТЬ УЧАСТИЕ!</p>
                <small>НАЖМИТЕ, ЧТОБЫ ПРИНЯТЬ УЧАСТИЕ</small>
              </div>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import './gift.scss'

import http from './../../utils/http'

export default {
  name: 'Gift',
  components: {
  },
  data () {
    return {
      showGiftModal: false,
      showClosedGift: false,
      showMobileText: false,
      showMobileTextCloseAnimation: false,
      vkGift: null,
      tgGift: null,
      counter: 0
    }
  },
  watch: {
    '$store.state.isAuth': async function (val) {
      if (val === false) {
        this.vkGift.participate = false
        this.tgGift.participate = false
      } else {
        const userId = localStorage.getItem('user_id')

        if (userId && this.vkGift && this.tgGift) {
          {
            const { data } = await http.post('https://adopt-giveaways.herokuapp.com/api/v1/giveaways/ask_for_participation', {
              userId,
              giveawayId: this.vkGift.giveawayId
            })

            this.vkGift.participate = data.isParticipate
          }

          const { data } = await http.post('https://adopt-giveaways.herokuapp.com/api/v1/giveaways/ask_for_participation', {
            userId,
            giveawayId: this.tgGift.giveawayId
          })

          this.tgGift.participate = data.isParticipate
        }
      }
    }
  },
  async created () {},
  mounted () {
    setTimeout(() => {
      this.openClosedGift()
      this.showMobileText = true
    }, 2000)

    setTimeout(() => {
      this.showMobileTextCloseAnimation = true

      setTimeout(() => {
        this.showMobileText = false
        this.showMobileTextCloseAnimation = false
      }, 700)
    }, 7000)
  },
  methods: {
    closeClosedGift () {
      this.showClosedGift = false
    },
    openClosedGift () {
      this.showClosedGift = true
    },
    openGiftModal (event) {
      if (event.target.closest('.gift-closed__close')) {
        return
      }

      this.showGiftModal = true
      // if (this.vkGift.participate) {
      //   this.activateVk()
      // } else {
      //   this.diactivateVk()
      // }

      // if (this.tgGift.participate) {
      //   this.activateTg()
      // } else {
      //   this.diactivateTg()
      // }
    },
    closeGiftModal () {
      this.showGiftModal = false
    },
    async participateInGift (giftId) {
      if ((this.vkGift.participate && this.vkGift.giveawayId === giftId) || (this.tgGift.participate && this.tgGift.giveawayId === giftId)) {
        return
      }

      const { data } = await http.post('https://adopt-giveaways.herokuapp.com/api/v1/giveaways/participate', {
        userId: localStorage.getItem('user_id'),
        giveawayId: giftId
      })

      console.log(data)

      if (this.vkGift.giveawayId === giftId) {
        this.vkGift.participate = true
        this.activateVk()
      } else {
        this.tgGift.participate = true
        this.activateTg()
      }
    },
    activateVk () {
      if (document.querySelector('.gift-opened__modal__blocks__block.vk')) {
        document.querySelector('.gift-opened__modal__blocks__block.vk').classList.add('active')
        document.querySelector('.gift-opened__modal__blocks__block.vk .gift-opened__modal__blocks__block__text p').innerHTML = 'Участие принято!'
        document.querySelector('.gift-opened__modal__blocks__block.vk .gift-opened__modal__blocks__block__text small').innerHTML = 'ВЫ ПОДТВЕРДИЛИ СВОЕ УЧАСТИЕ'
      }
    },
    activateTg () {
      if (document.querySelector('.gift-opened__modal__blocks__block.tg')) {
        document.querySelector('.gift-opened__modal__blocks__block.tg').classList.add('active')
        document.querySelector('.gift-opened__modal__blocks__block.tg .gift-opened__modal__blocks__block__text p').innerHTML = 'Участие принято!'
        document.querySelector('.gift-opened__modal__blocks__block.tg .gift-opened__modal__blocks__block__text small').innerHTML = 'ВЫ ПОДТВЕРДИЛИ СВОЕ УЧАСТИЕ'
      }
    },
    diactivateVk () {
      if (document.querySelector('.gift-opened__modal__blocks__block.vk')) {
        document.querySelector('.gift-opened__modal__blocks__block.vk .gift-opened__modal__blocks__block__text p').innerHTML = 'ПРИНЯТЬ УЧАСТИЕ!'
        document.querySelector('.gift-opened__modal__blocks__block.vk .gift-opened__modal__blocks__block__text small').innerHTML = 'НАЖМИТЕ, ЧТОБЫ ПРИНЯТЬ УЧАСТИЕ'
      }
    },
    diactivateTg () {
      if (document.querySelector('.gift-opened__modal__blocks__block.tg')) {
        document.querySelector('.gift-opened__modal__blocks__block.tg .gift-opened__modal__blocks__block__text p').innerHTML = 'ПРИНЯТЬ УЧАСТИЕ!'
        document.querySelector('.gift-opened__modal__blocks__block.tg .gift-opened__modal__blocks__block__text small').innerHTML = 'НАЖМИТЕ, ЧТОБЫ ПРИНЯТЬ УЧАСТИЕ'
      }
    }
  }
}
</script>
