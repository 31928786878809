<template>
  <div class="slider-news">
    <img src="../../assets/images/sliderfornews/leaves.png" alt="" class="leaves">
    <div class="slider-news__wrapper">
      <div class="slider-news__wrapper__items" id="wrap">
        <div class="slider-item">
          <h3>Нет звука приятнее, чем песнь</h3>
          <p>Лишь многие известные личности, которые представляют собой яркий пример.</p>
          <button>ПЕРЕЙТИ</button>
        </div>
        <div class="slider-item">
          <h3>Нет звука</h3>
          <p>Лишь многие известные личности, которые представляют собой яркий пример.</p>
          <button>ПЕРЕЙТИ</button>
        </div>
        <div class="slider-item">
          <h3>Приятнее</h3>
          <p>Лишь многие известные личности, которые представляют собой яркий пример.</p>
          <button>ПЕРЕЙТИ</button>
        </div>
      </div>
      <div class="slider-news__wrapper__arrow-right" @click="slider()">
        <svg width="81" height="140" viewBox="0 0 81 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M76.8748 62.6045L17.9165 3.7503C16.9482 2.77396 15.7961 1.99902 14.5267 1.47018C13.2573 0.941343 11.8958 0.669067 10.5207 0.669067C9.14556 0.669067 7.78404 0.941343 6.51468 1.47018C5.24531 1.99902 4.09322 2.77396 3.12485 3.7503C1.18474 5.70199 0.0957642 8.34212 0.0957642 11.0941C0.0957642 13.846 1.18474 16.4861 3.12485 18.4378L54.6873 70.5211L3.12485 122.084C1.18474 124.035 0.0957642 126.675 0.0957642 129.427C0.0957642 132.179 1.18474 134.819 3.12485 136.771C4.08959 137.755 5.24006 138.538 6.50959 139.075C7.77912 139.611 9.14248 139.89 10.5207 139.896C11.8989 139.89 13.2622 139.611 14.5318 139.075C15.8013 138.538 16.9518 137.755 17.9165 136.771L76.8748 77.917C77.9322 76.9415 78.776 75.7577 79.3532 74.44C79.9303 73.1223 80.2283 71.6993 80.2283 70.2607C80.2283 68.8222 79.9303 67.3992 79.3532 66.0815C78.776 64.7638 77.9322 63.5799 76.8748 62.6045Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="slider-news__blocks">
      <div class="slider-news__blocks__block"
        :class="{ active: slide == 0}"
        @click="slider(), slide = 0"
      ></div>
      <div class="slider-news__blocks__block"
        :class="{ active: slide == 1}"
        @click="slider(), slide = 1"
      ></div>
      <div class="slider-news__blocks__block"
        :class="{ active: slide == 2}"
        @click="slider(), slide = 2"
      ></div>
    </div>
  </div>
</template>
<script>
import './sliderfornews.scss'

export default {
  name: 'SliderForNews',
  data () {
    return {
      slide: 0
    }
  },
  methods: {
    slider () {
      const sliderWrapper = document.querySelector('#wrap')
      if (this.slide === sliderWrapper.childElementCount - 1) {
        this.slide = 0
        sliderWrapper.style.transition = 'transform 0.6s ease'
        sliderWrapper.style.transform = `translateX(-${this.slide}00%)`
      } else {
        this.slide += 1
        sliderWrapper.style.transition = 'transform 0.6s ease'
        sliderWrapper.style.transform = `translateX(-${this.slide}00%)`
      }
    }
  }
}
</script>
