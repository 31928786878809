<template>
  <div class="footer">

    <div class="footer__left-particle">
      <img src="./../../assets/images/footer/particles-left.png" alt="left">
    </div>
    <div class="footer__right-particle">
      <img src="./../../assets/images/footer/particles-right.png" alt="right">
    </div>
    <div class="footer-top">
      <ul class="footer-left">
        <li>
          <a href="#">Главная</a>
        </li>
        <li>
          <router-link to="/faq">F.A.Q.</router-link>
        </li>
        <li v-if="false">
          <a href="#" class="bonus-soon">
            Бонусы
            <img class="footer-soon" src="../../assets/images/footer/soon.svg" alt="">
          </a>
        </li>
      </ul>
      <div class="footer-center">
        <div class="footer-center__top">
          <img src="./../../assets/images/footer/logo.svg" alt="logo">
          <img v-if="isCountryRu($store.state.ipCountry)" class="footer-address" src="../../assets/images/footer/address.svg" alt="">
          <p>НАДЕЖНЫЙ МАГАЗИН - НИЗКИЕ ЦЕНЫ!</p>
        </div>
      </div>
      <ul class="footer-right">
        <li>
          <router-link :to="{ path: '/contacts' }">Контакты</router-link>
        </li>
        <li>
          <router-link :to="{ path: '/privacy' }">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</router-link>
        </li>
        <li>
          <router-link :to="{ path: '/agreement' }">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</router-link>
        </li>
      </ul>
      <div class="footer-center__bottom">
        <a href="//freekassa.ru/" target="_blank" rel="noopener noreferrer" style="display: none">
          <img src="//cdn.freekassa.ru/banners/small-white-1.png" title="Прием платежей на сайте для физических лиц и т.д.">
        </a>
        <small v-if="!isCountryFromRu($store.state.ipCountry)">
        </small>
        <p>( C ) COPYRIGHT 2020-2024. RBXTREE CO.</p>
      </div>
    </div>

    <p class="footer__tags">купить робуксы, купить робаксы, купить роблоксы, robuxrange, rbxtree, rblx, robuxshop, robuxpier, робуксрэндж, дерево робуксов, дерево робаксов, робуксшоп, rblxtree</p>
  </div>
</template>

<script>
import './footer.scss'

import ipUtil from './../../utils/ip'

export default {
  name: 'Footer',
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    },
    isCountryRu (c) {
      return ipUtil.isCountryRu(c)
    }
  }
}
</script>
