<template>
  <div class="custom-loader" :class="{translucent: translucent}">
    <span class="custom-loader__circle"></span>
  </div>
</template>

<script>
import './loader.scss'

export default {
  name: 'LoaderUi',
  props: ['translucent']
}
</script>
