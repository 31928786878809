<template>
  <div
    v-show="$store.state.showAuth || $store.state.animateAuth"
    class="auth animate__animated"
    @click="closeHandle"
  >
    <CaptchaComponent
      v-if="captchaShow"
      @onVerify="captchaCallback"
      @hideCaptcha="closeCaptcha"
    />
    <div
      v-show="auth.logIn.status && $store.state.isAuth === false"
      class="auth__login animate__animated"
      :class="auth.logIn.status && $store.state.isAuth === false ? 'animate__slideInRight' : ''"
    >
      <img
        @click="$store.commit('toggleAuthShowState')"
        class="menu-close"
        src="../../assets/images/auth/arrow-back.svg" alt="">
      <div
        class="auth__loader"
        v-if="auth.loaderOn"
      >
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
      </div>
      <div class="auth__nav">
        <p @click="() => { goToRegister(); hideSignIn(); }">
          Региcтрация <img src="./../../assets/images/auth/right-arrow.svg" alt="go">
        </p>
      </div>
      <div class="auth__header">
        <h2>Авторизация</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/mail.svg" alt="mail">
        </div>
        <input
          type="text"
          placeholder="Введите почту или логин"
          v-model="auth.logIn.values.email"
          @keyup.enter="openCaptchaForSignIn"
          @change="validateSignInEmail"
          :class="auth.logIn.errors.email.show && auth.logIn.errors.email.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.logIn.errors.email.show && auth.logIn.errors.email.status === false">
          <p>{{ auth.logIn.errors.email.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.logIn.errors.email.show && auth.logIn.errors.email.status === false ? 'error': ''"
        >
          Email или Логин
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Введите пароль"
          v-model="auth.logIn.values.password"
          @keyup.enter="openCaptchaForSignIn"
          @change="validateSignInPassword"
          :class="auth.logIn.errors.password.show && auth.logIn.errors.password.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.logIn.errors.password.show && auth.logIn.errors.password.status === false">
          <p>{{ auth.logIn.errors.password.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.logIn.errors.password.show && auth.logIn.errors.password.status === false ? 'error': ''"
        >
          Пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__addition">
        <p @click="() => { goToRecovery(); hideSignIn(); }">Забыли пароль?</p>
      </div>
      <div class="auth__button">
        <button @click="openCaptchaForSignIn">Авторизация</button>
      </div>
      <div class="auth__button-addition">
        <p>У Вас нет аккаунта? <span @click="() => { goToRegister(); hideSignIn(); }">Создайте его!</span></p>
      </div>
      <div class="auth__line">
        <span></span>
      </div>
<!--      <div class="auth__button">
        <a class="vk" :href="vkUrl"><img src="./../../assets/images/auth/vk.svg" alt="vk"> Войдите с помощью ВКонтакте</a>
      </div>-->
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2024. RBXTREE CO.</p>
      </div>
    </div>
    <div
      v-show="auth.register.status && auth.register.stage === 1"
      class="auth__register animate__animated animate__slideInRight"
    >
      <img
        @click="$store.commit('toggleAuthShowState')"
        class="menu-close"
        src="../../assets/images/auth/arrow-back.svg" alt="">
      <div
        class="auth__loader"
        v-if="auth.loaderOn"
      >
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
      </div>
      <div class="auth__nav">
        <p @click="() => { goToSignIn(); hideRegister(); }">
          Авторизация <img src="./../../assets/images/auth/right-arrow.svg" alt="go">
        </p>
      </div>
      <div class="auth__header">
        <h2>Региcтрация</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/mail.svg" alt="mail">
        </div>
        <input
          type="email"
          placeholder="info@rbxtree.pro"
          v-model="auth.register.values.email"
          @keyup.enter="openCaptchaForSignUp"
          @change="validateRegisterEmail"
          :class="auth.register.errors.email.show && auth.register.errors.email.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.register.errors.email.show && auth.register.errors.email.status === false">
          <p>{{ auth.register.errors.email.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.register.errors.email.show && auth.register.errors.email.status === false ? 'error': ''"
        >
          E-mail
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/username.svg" alt="user">
        </div>
        <input
          type="text"
          placeholder="Введите логин"
          v-model="auth.register.values.username"
          @keyup.enter="openCaptchaForSignUp"
          @change="validateRegisterUsername"
          :class="auth.register.errors.username.show && auth.register.errors.username.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.register.errors.username.show && auth.register.errors.username.status === false">
          <p>{{ auth.register.errors.username.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.register.errors.username.show && auth.register.errors.username.status === false ? 'error': ''"
        >
          Логин
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Введите пароль"
          v-model="auth.register.values.password"
          @keyup.enter="openCaptchaForSignUp"
          @change="validateRegisterPassword"
          :class="auth.register.errors.password.show && auth.register.errors.password.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.register.errors.password.show && auth.register.errors.password.status === false">
          <p>{{ auth.register.errors.password.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.register.errors.password.show && auth.register.errors.password.status === false ? 'error': ''"
        >
          Пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Повторите пароль"
          v-model="auth.register.values.password2"
          @keyup.enter="openCaptchaForSignUp"
          @change="validateRegisterPassword2"
          :class="auth.register.errors.password2.show && auth.register.errors.password2.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.register.errors.password2.show && auth.register.errors.password2.status === false">
          <p>{{ auth.register.errors.password2.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.register.errors.password2.show && auth.register.errors.password2.status === false ? 'error': ''"
        >
          Подтвердите пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__checkbox">
        <input
          type="checkbox"
          class="auth__checkbox__checkbox"
          id="privacy-policy"
          name="privacy-policy"
          v-model="auth.register.values.checkbox"
        >
        <label for="privacy-policy">Я согласен с <span @click="$router.push('/agreement'); $store.state.showAuth = false">политикой конфиденциальности</span></label>
      </div>
      <div class="auth__button">
        <button
          v-on="auth.register.values.checkbox ? { click: openCaptchaForSignUp } : {}"
          :class="auth.register.values.checkbox ? '' : 'inactive'"
        >
          Зарегистрироваться
        </button>
      </div>
      <div class="auth__button-addition">
        <p>У Вас уже есть аккаунт? <span @click="() => { goToSignIn(); hideRegister(); }">Войдите!</span></p>
      </div>
      <div class="auth__line">
        <span></span>
      </div>
<!--      <div class="auth__button">
        <a class="vk" :href="vkUrl"><img src="./../../assets/images/auth/vk.svg" alt="vk"> Войдите с помощью ВКонтакте</a>
      </div>-->
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2021. RBXTREE CO.</p>
      </div>
    </div>
    <div
      v-show="auth.recovery.status && auth.recovery.stage === 1"
      class="auth__recovery animate__animated animate__slideInRight"
    >
      <img
        @click="$store.commit('toggleAuthShowState')"
        class="menu-close"
        src="../../assets/images/auth/arrow-back.svg" alt="">
      <div
        class="auth__loader"
        v-if="auth.loaderOn"
      >
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
      </div>
      <div class="auth__back">
        <p @click="() => { goToSignIn(); hideRecovery(); }">
          <img src="./../../assets/images/auth/left-arrow.svg" alt="go"> Назад
        </p>
      </div>
      <div class="auth__header">
        <h2>Восстановление</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/mail.svg" alt="mail">
        </div>
        <input
          type="email"
          placeholder="info@rbxtree.pro"
          v-model="auth.recovery.values.email"
          :class="auth.recovery.errors.email.show && auth.recovery.errors.email.status === false ? 'error': ''"
          @change="validateEmailRecovery"
          @keyup.enter="openCaptchaForEmail"
        >
        <div class="auth__input__error" v-if="auth.recovery.errors.email.show && auth.recovery.errors.email.status === false">
          <p>{{ auth.recovery.errors.email.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.recovery.errors.email.show && auth.recovery.errors.email.status === false ? 'error': ''"
        >
          E-mail
        </div>
      </div>
      <div class="auth__button">
        <button
          :class="auth.recovery.errors.email.status === true ? '' : 'inactive'"
          v-on="auth.recovery.errors.email.status === true ? { click: openCaptchaForEmail } : { }"
        >
          Продолжить
        </button>
      </div>
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2021. RBXTREE CO.</p>
      </div>
    </div>
    <div
      v-show="auth.recovery.status && auth.recovery.stage === 2"
      class="auth__register auth__recovery__stage__two animate__animated animate__slideInRight"
    >
      <div
        class="auth__loader"
        v-if="auth.loaderOn"
      >
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
      </div>
      <div class="auth__header">
        <h2>Восстановление</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/mail.svg" alt="mail">
        </div>
        <input
          type="email"
          placeholder="info@rbxtree.pro"
          :value="auth.recovery.values.email"
          readonly
          class="inactive"
        >
        <div class="auth__input__title">E-mail</div>
        <div class="auth__input__edit">
          <img src="./../../assets/images/auth/edit.svg" alt="edit">
        </div>
      </div>
      <div class="auth__email">
        <p>На почту <span>{{ auth.recovery.values.email }}</span> отправлен код для восстановления пароля!</p>
      </div>
      <div class="auth__code">
        <small>Введите 6-значный код:</small>
        <div class="auth__code__inputs recovery">
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(0, 'recovery', $event)"
          >
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(1, 'recovery', $event)"
          >
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(2, 'recovery', $event)"
          >
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(3, 'recovery', $event)"
          >
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(4, 'recovery', $event)"
          >
          <input
            :class="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show ? 'error' : ''"
            type="text"
            @focus="selectCellValue"
            @keyup="fillCodeCell(5, 'recovery', $event)"
          >
        </div>
        <div class="auth__code__resend">
          <p v-if="auth.recovery.timers.code.value === 0"><small class="send" @click="resendCode('recovery')">Запросить снова</small></p>
          <p v-else><small>Запросить снова:</small> <span>0:{{ ('0' + auth.recovery.timers.code.value).slice(-2) }}</span></p>
        </div>
      </div>
      <div class="auth__button">
        <p
          v-if="auth.recovery.errors.code.status === false && auth.recovery.errors.code.show"
          class="auth__button__error"
        >
          {{ auth.recovery.errors.code.message }}
        </p>
        <button
          :class="auth.recovery.values.code.join('').length !== 6 ? 'inactive' : ''"
          v-on="auth.recovery.values.code.join('').length === 6 ? { click: openCaptchaForToken } : {}"
        >
          Продолжить
        </button>
      </div>
      <div class="auth__button-addition">
        <p>Вы вспомнили пароль? <span @click="() => { goToSignIn(); hideRecovery(); }">Войдите!</span></p>
      </div>
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2021. RBXTREE CO.</p>
      </div>
    </div>
    <div
      v-show="auth.recovery.status && auth.recovery.stage === 3"
      class="auth__recovery auth__recovery__stage__third animate__animated animate__slideInRight"
    >
      <div class="auth__header">
        <h2>Восстановление</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/mail.svg" alt="mail">
        </div>
        <input
          type="email"
          placeholder="info@rbxtree.pro"
          v-model="auth.recovery.values.email"
          readonly
        >
        <div
          class="auth__input__title"
          :class="auth.recovery.errors.email.show && auth.recovery.errors.email.status === false ? 'error': ''"
        >
          E-mail
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Пароль"
          v-model="auth.recovery.values.password"
          @keyup.enter="openCaptchaForResetPassword"
          @change="validateRecoverPassword"
          :class="auth.recovery.errors.password.show && auth.recovery.errors.password.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.recovery.errors.password.show && auth.recovery.errors.password.status === false">
          <p>{{ auth.recovery.errors.password.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.recovery.errors.password.show && auth.recovery.errors.password.status === false ? 'error': ''"
        >
          Пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Повторите пароль"
          v-model="auth.recovery.values.password2"
          @keyup.enter="openCaptchaForResetPassword"
          @change="validateRecoverPassword2"
          :class="auth.recovery.errors.password2.show && auth.recovery.errors.password2.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.recovery.errors.password2.show && auth.recovery.errors.password2.status === false">
          <p>{{ auth.recovery.errors.password2.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.recovery.errors.password2.show && auth.recovery.errors.password2.status === false ? 'error': ''"
        >
          Подтвердите пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__button">
        <button
          :class="auth.recovery.errors.password.status === true && auth.recovery.errors.password2.status === true ? '' : 'inactive'"
          v-on="auth.recovery.errors.password.status === true && auth.recovery.errors.password2.status === true ? { click: openCaptchaForResetPassword } : {}"
        >
          Продолжить
        </button>
      </div>
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2021. RBXTREE CO.</p>
      </div>
    </div>
    <div
      v-show="$store.state.authWindow.changePassword && $store.state.isAuth"
      class="auth__recovery auth__recovery__stage__third animate__animated animate__slideInRight"
    >
      <div
        class="auth__loader"
        v-if="auth.loaderOn"
      >
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
        <div class="auth__loader__bar"></div>
      </div>
      <div class="auth__header">
        <h2>ИЗМЕНИТЬ ПАРОЛЬ</h2>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Текущий пароль"
          v-model="auth.changePassword.values.password"
          @keyup.enter="changePasswordAuth"
          @change="validateChangePassword"
          :class="auth.changePassword.errors.password.show && auth.changePassword.errors.password.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.changePassword.errors.password.show && auth.changePassword.errors.password.status === false">
          <p>{{ auth.changePassword.errors.password.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.changePassword.errors.password.show && auth.changePassword.errors.password.status === false ? 'error': ''"
        >
          Текущий пароль
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Новый пароль"
          v-model="auth.changePassword.values.newPassword"
          @keyup.enter="changePasswordAuth"
          @change="validateChangeNewPassword"
          :class="auth.changePassword.errors.newPassword.show && auth.changePassword.errors.newPassword.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.changePassword.errors.newPassword.show && auth.changePassword.errors.newPassword.status === false">
          <p>{{ auth.changePassword.errors.newPassword.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.changePassword.errors.newPassword.show && auth.changePassword.errors.newPassword.status === false ? 'error': ''"
        >
          НОВЫЙ ПАРОЛЬ
        </div>
        <div class="auth__input__eye">
          <img
            src="./../../assets/images/auth/closed-eye.svg"
            alt="show password"
            @click="togglePasswordShow"
          >
        </div>
      </div>
      <div class="auth__input">
        <div class="auth__input__mark">
          <img src="./../../assets/images/auth/lock.svg" alt="lock">
        </div>
        <input
          type="password"
          placeholder="Повторите новый пароль"
          v-model="auth.changePassword.values.confirmPassword"
          @keyup.enter="changePasswordAuth"
          @change="validateChangeConfirmPassword"
          :class="auth.changePassword.errors.confirmPassword.show && auth.changePassword.errors.confirmPassword.status === false ? 'error': ''"
        >
        <div class="auth__input__error" v-if="auth.changePassword.errors.confirmPassword.show && auth.changePassword.errors.confirmPassword.status === false">
          <p>{{ auth.changePassword.errors.confirmPassword.message }}</p>
        </div>
        <div
          class="auth__input__title"
          :class="auth.changePassword.errors.confirmPassword.show && auth.changePassword.errors.confirmPassword.status === false ? 'error': ''"
        >
          Подтвердите новый пароль
        </div>
      </div>
      <div class="auth__button">
        <button
          :class="
          auth.changePassword.errors.newPassword.status === true &&
          auth.changePassword.errors.confirmPassword.status === true &&
          auth.changePassword.errors.password.status === true
          ? 'change-btn' : 'inactive'"
          v-on="auth.changePassword.errors.newPassword.status === true &&
          auth.changePassword.errors.confirmPassword.status === true &&
          auth.changePassword.errors.password.status === true
          ? { click: changePasswordAuth } : {}"
        >
          Продолжить
        </button>
      </div>
      <div class="auth__footer">
        <p>( C ) COPYRIGHT 2020-2021. RBXTREE CO.</p>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css'
import './auth.scss'

import { validateEmail, validateLength, validatePassword } from './../../utils/validate'
import CaptchaComponent from '@/components/Captcha/Captcha.vue'
import * as intercom from '@/utils/intercom'

export default {
  name: 'Auth',
  components: { CaptchaComponent },
  data () {
    return {
      vkUrl: '',
      captchaShow: false,
      captchaCallback: (token) => {},
      auth: {
        loaderOn: false,
        logIn: {
          status: true,
          values: {
            email: '',
            password: ''
          },
          errors: {
            email: {
              status: false,
              show: false,
              message: ''
            },
            password: {
              status: false,
              show: false,
              message: ''
            }
          }
        },
        register: {
          status: false,
          stage: 1,
          values: {
            email: '',
            username: '',
            password: '',
            password2: '',
            checkbox: false,
            code: [null, null, null, null, null]
          },
          timers: {
            code: {
              value: 59,
              id: null
            }
          },
          errors: {
            email: {
              status: false,
              show: false,
              message: ''
            },
            username: {
              status: false,
              show: false,
              message: ''
            },
            password: {
              status: false,
              show: false,
              message: ''
            },
            password2: {
              status: false,
              show: false,
              message: ''
            },
            code: {
              status: false,
              show: false,
              message: ''
            }
          }
        },
        recovery: {
          status: false,
          stage: 1,
          values: {
            email: '',
            password: '',
            password2: '',
            code: [null, null, null, null, null, null]
          },
          timers: {
            code: {
              value: 59,
              id: null
            }
          },
          errors: {
            email: {
              status: false,
              show: false,
              message: ''
            },
            password: {
              status: false,
              show: false,
              message: ''
            },
            password2: {
              status: false,
              show: false,
              message: ''
            },
            code: {
              status: false,
              show: false,
              message: ''
            }
          }
        },
        changePassword: {
          status: false,
          values: {
            password: '',
            newPassword: '',
            confirmPassword: ''
          },
          errors: {
            password: {
              status: false,
              show: false,
              message: ''
            },
            newPassword: {
              status: false,
              show: false,
              message: ''
            },
            confirmPassword: {
              status: false,
              show: false,
              message: ''
            }
          }
        }
      }
    }
  },
  created () {
    fetch('https://auth.backend-tree.com/api/v1/user/vkurl')
      .then((res) => res.json())
      .then((data) => {
        this.vkUrl = data.vk_url
      })
      .catch((err) => console.log(err))
  },
  methods: {
    togglePasswordShow (event) {
      const eyeImage = event.target
      const passwordInput = eyeImage.parentNode.parentNode.childNodes[1]

      if (passwordInput.type === 'password') {
        passwordInput.type = 'text'
        eyeImage.src = require('./../../assets/images/auth/eye.svg')
      } else {
        passwordInput.type = 'password'
        eyeImage.src = require('./../../assets/images/auth/closed-eye.svg')
      }
    },
    signIn (clcaptcha) {
      this.captchaShow = false
      this.validateSignInEmail()
      this.validateSignInPassword()

      if (this.auth.logIn.errors.email.status === false || this.auth.logIn.errors.password.status === false) {
        return
      }

      const requestBody = JSON.stringify({
        login: this.auth.logIn.values.email,
        password: this.auth.logIn.values.password,
        clcaptcha: clcaptcha
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/login', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.auth.loaderOn = false

          if (data.error) {
            Object.keys(data.error).forEach((key) => {
              this.auth.logIn.errors[key].status = false
              this.auth.logIn.errors[key].show = true
              this.auth.logIn.errors[key].message = data.error[key]
            })
          } else {
            this.$store.commit('toggleAuthShowState')
            this.$store.dispatch('signIn', data)
            intercom.authCallback.call(this)
          }
        })
        .catch((err) => console.log(err))
    },
    register (clcaptcha) {
      this.captchaShow = false

      this.validateRegisterEmail()
      this.validateRegisterPassword()
      this.validateRegisterPassword2()
      this.validateRegisterUsername()

      if (
        this.auth.register.errors.email.status === false ||
        this.auth.register.errors.password.status === false ||
        this.auth.register.errors.password2.status === false ||
        this.auth.register.values.checkbox === false ||
        this.auth.register.errors.username.status === false) {
        return
      }

      const requestBody = {
        email: this.auth.register.values.email,
        username: this.auth.register.values.username,
        password: this.auth.register.values.password,
        clcaptcha: clcaptcha
      }

      const refCode = localStorage.getItem('ref_code')

      if (refCode) {
        requestBody.ref_code = refCode
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: requestHeaders
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/register', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.auth.loaderOn = false

          if (data.error) {
            Object.keys(data.error).forEach((key) => {
              this.auth.register.errors[key].status = false
              this.auth.register.errors[key].show = true
              this.auth.register.errors[key].message = data.error[key]
            })
          } else {
            this.$store.commit('toggleAuthShowState')
            this.$store.dispatch('signIn', data)
            intercom.authCallback.call(this)
          }
        })
        .catch((err) => console.log(err))
    },
    checkRecoverCode (clcaptcha) {
      this.captchaShow = false
      const code = this.auth.recovery.values.code.join('')

      if (code.length !== 6) {
        this.auth.recovery.errors.code.status = false
        this.auth.recovery.errors.code.show = true
        this.auth.recovery.errors.code.message = 'Введён неверный код!'

        return
      } else {
        this.auth.recovery.errors.code.status = true
      }

      const requestBody = JSON.stringify({
        token: code,
        clcaptcha: clcaptcha
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/verify-token', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.auth.loaderOn = false

          if (data.status === false) {
            this.auth.recovery.errors.code.status = false
            this.auth.recovery.errors.code.show = true
            this.auth.recovery.errors.code.message = 'Неверный код'
          } else {
            this.changeRecoveryStage(3)
          }
        })
        .catch((err) => console.log(err))
    },
    changePassword (clcaptcha) {
      this.captchaShow = false

      this.validateRecoverPassword()
      this.validateRecoverPassword2()

      if (this.auth.recovery.errors.password.status === false || this.auth.recovery.errors.password2.status === false) {
        return
      }

      const code = this.auth.recovery.values.code.join('')

      const requestBody = JSON.stringify({
        token: code,
        new: this.auth.recovery.values.password,
        clcaptcha: clcaptcha
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/update-password-token', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.auth.loaderOn = false

          if (data.status) {
            this.hideRecovery()
            this.goToSignIn()
          }
        })
        .catch((err) => console.log(err))
    },
    changePasswordAuth () {
      this.validateChangePassword()
      this.validateChangeNewPassword()
      this.validateChangeConfirmPassword()

      if (this.auth.changePassword.errors.password.status === false &&
      this.auth.changePassword.errors.newPassword.status === false &&
      this.auth.changePassword.errors.confirmPassword.status === false) {
        return
      }

      const requestBody = JSON.stringify({
        old: this.auth.changePassword.values.password,
        new: this.auth.changePassword.values.newPassword
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/update-password', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.auth.loaderOn = false

          if (data.status) {
            this.$store.dispatch('closeShowChangePassword')
          } else {
            Object.keys(data.error).forEach((key) => {
              this.auth.changePassword.errors[key].status = false
              this.auth.changePassword.errors[key].show = true
              this.auth.changePassword.errors[key].message = data.error[key]
            })
          }
        })
        .catch((err) => console.log(err))
    },
    fillCodeCell (number, type, event) {
      let isPhone = false

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        isPhone = true
      }

      const inputValue = event.target.value

      if (!isNaN(inputValue) && inputValue.length === 6) {
        this.pasteCode(inputValue, type)
        return
      }

      const pressedKey = event.key

      if (isNaN(pressedKey) && isPhone === false) {
        event.target.value = this.auth[type].values.code[number]
        return
      }

      const codeInputs = document.querySelectorAll(`.auth__code__inputs.${type} input`)

      if (isPhone === false) {
        event.target.value = pressedKey
      }

      this.auth[type].values.code[number] = isPhone ? event.target.value : pressedKey

      for (let i = 0; i < this.auth.register.values.code.length; i++) {
        if (!this.auth.register.values.code[i]) {
          codeInputs[i].focus()
          break
        }
      }

      codeInputs.forEach((input, index) => {
        if (input === event.target) {
          if (index + 1 === codeInputs.length) {
            codeInputs[0].focus()
          } else {
            codeInputs[index + 1].focus()
          }
        }
      })
    },
    pasteCode (pastedCode, type) {
      const codeInputs = document.querySelectorAll(`.auth__code__inputs.${type} input`)

      for (let i = 0; i < this.auth[type].values.code.length; i++) {
        this.auth[type].values.code[i] = pastedCode[i]
        codeInputs[i].value = pastedCode[i]
      }
    },
    selectCellValue (event) {
      event.target.select()
    },
    resendCode (type) {
      this.resetTimer(type)
    },
    openCaptcha (cb) {
      this.captchaShow = true
      this.captchaCallback = cb
    },
    closeCaptcha () {
      this.captchaShow = false
    },
    openCaptchaForSignIn () {
      this.openCaptcha(this.signIn)
    },
    openCaptchaForSignUp () {
      this.openCaptcha(this.register)
    },
    openCaptchaForEmail () {
      this.openCaptcha(this.startRecover)
    },
    openCaptchaForToken () {
      this.openCaptcha(this.checkRecoverCode)
    },
    openCaptchaForResetPassword () {
      this.openCaptcha(this.changePassword)
    },
    startRecover (token) {
      this.captchaShow = false
      this.validateEmailRecovery()

      if (this.auth.recovery.errors.email.status === false) {
        return
      }

      const requestBody = JSON.stringify({
        login: this.auth.recovery.values.email,
        clcaptcha: token
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody
      }

      this.auth.loaderOn = true

      fetch('https://auth.backend-tree.com/api/v1/user/reset-password', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            this.resetTimer('recovery')
            this.changeRecoveryStage(2)
            this.auth.loaderOn = false
          }
        })
        .catch((err) => console.log(err))
    },
    resetTimer (type) {
      this.auth[type].timers.code.value = 59

      this.auth[type].timers.code.id = setInterval(() => {
        this.auth[type].timers.code.value -= 1

        if (this.auth[type].timers.code.value <= 0) {
          clearInterval(this.auth[type].timers.code.id)
        }
      }, 1000)
    },
    validateSignInEmail () {
      let errorMessage = ''
      let emailState = true

      if (this.auth.logIn.values.email.length === 0) {
        errorMessage = 'Поле пустое'
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.auth.logIn.errors.email.status = emailState
      this.auth.logIn.errors.email.show = !emailState
      this.auth.logIn.errors.email.message = errorMessage
    },
    validateRegisterUsername () {
      let errorMessage = ''
      let usernameState = true

      if (this.auth.register.values.username.length === 0) {
        errorMessage = 'Поле пустое'
        usernameState = false
      } else if (validateLength(this.auth.register.values.username, 4, 60) === false) {
        errorMessage = 'Длина нийнейма от 4 символов'
        usernameState = false
      } else {
        errorMessage = ''
        usernameState = true
      }

      this.auth.register.errors.username.status = usernameState
      this.auth.register.errors.username.show = !usernameState
      this.auth.register.errors.username.message = errorMessage
    },
    validateEmailRecovery () {
      let errorMessage = ''
      let emailState = true

      if (this.auth.recovery.values.email.length === 0) {
        errorMessage = 'Поле пустое'
        emailState = false
      } else if (validateEmail(this.auth.recovery.values.email) === false) {
        errorMessage = 'Введённая почта неверная!'
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.auth.recovery.errors.email.status = emailState
      this.auth.recovery.errors.email.show = !emailState
      this.auth.recovery.errors.email.message = errorMessage
    },
    validateSignInPassword () {
      const passwordState = this.auth.logIn.values.password.length !== 0

      this.auth.logIn.errors.password.status = passwordState
      this.auth.logIn.errors.password.show = !passwordState
      this.auth.logIn.errors.password.message = passwordState ? '' : 'Поле пустое'
    },
    validateRegisterEmail () {
      let errorMessage = ''
      let emailState = true

      if (this.auth.register.values.email.length === 0) {
        errorMessage = 'Поле пустое'
        emailState = false
      } else if (validateEmail(this.auth.register.values.email) === false) {
        errorMessage = 'Введённая почта неверная!'
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.auth.register.errors.email.status = emailState
      this.auth.register.errors.email.show = !emailState
      this.auth.register.errors.email.message = errorMessage
    },
    validateRegisterPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.register.values.password.length === 0) {
        errorMessage = 'Поле пустое'
        passwordState = false
      } else if (this.auth.register.values.password.length < 6) {
        errorMessage = 'Допустимая длина пароля от 6 символов'
        passwordState = false
      } else if (validatePassword(this.auth.register.values.password) === false) {
        errorMessage = 'Пароль должен содержать букву верхнего и нижнего регистра'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.register.errors.password.status = passwordState
      this.auth.register.errors.password.show = !passwordState
      this.auth.register.errors.password.message = errorMessage
    },
    validateRegisterPassword2 () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.register.values.password2 !== this.auth.register.values.password) {
        errorMessage = 'Пароли не совпадают'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.register.errors.password2.status = passwordState
      this.auth.register.errors.password2.show = !passwordState
      this.auth.register.errors.password2.message = errorMessage
    },
    validateRecoverPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.recovery.values.password.length === 0) {
        errorMessage = 'Поле пустое'
        passwordState = false
      } else if (validatePassword(this.auth.recovery.values.password) === false) {
        errorMessage = 'Пароль должен быть от 6 до 18 символов и состоять из букв “A-z”!'
        passwordState = false
      } else if (this.auth.recovery.values.password === this.auth.recovery.values.password2) {
        this.auth.recovery.errors.password2.status = true
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.recovery.errors.password.status = passwordState
      this.auth.recovery.errors.password.show = !passwordState
      this.auth.recovery.errors.password.message = errorMessage
    },
    validateRecoverPassword2 () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.recovery.values.password2 !== this.auth.recovery.values.password) {
        errorMessage = 'Пароли не совпадают'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.recovery.errors.password2.status = passwordState
      this.auth.recovery.errors.password2.show = !passwordState
      this.auth.recovery.errors.password2.message = errorMessage
    },
    validateChangePassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.changePassword.values.password.length === 0) {
        errorMessage = 'Поле пустое'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.changePassword.errors.password.status = passwordState
      this.auth.changePassword.errors.password.show = !passwordState
      this.auth.changePassword.errors.password.message = errorMessage
    },
    validateChangeNewPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.changePassword.values.newPassword.length === 0) {
        errorMessage = 'Поле пустое'
        passwordState = false
      } else if (validatePassword(this.auth.changePassword.values.newPassword) === false) {
        errorMessage = 'Пароль должен быть от 6 до 18 символов и состоять из букв “A-z”!'
        passwordState = false
      } else if (this.auth.changePassword.values.newPassword === this.auth.changePassword.values.confirmPassword) {
        this.auth.changePassword.errors.confirmPassword.status = true
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.changePassword.errors.newPassword.status = passwordState
      this.auth.changePassword.errors.newPassword.show = !passwordState
      this.auth.changePassword.errors.newPassword.message = errorMessage
    },
    validateChangeConfirmPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.auth.changePassword.values.confirmPassword !== this.auth.changePassword.values.newPassword) {
        errorMessage = 'Пароли не совпадают'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.auth.changePassword.errors.confirmPassword.status = passwordState
      this.auth.changePassword.errors.confirmPassword.show = !passwordState
      this.auth.changePassword.errors.confirmPassword.message = errorMessage
    },
    goToRegister () {
      this.auth.register.status = true
      this.auth.register.stage = 1
    },
    changeRegisterStage (stage) {
      this.auth.register.stage = stage
    },
    hideRegister () {
      this.auth.register.status = false
    },
    goToSignIn () {
      this.auth.logIn.status = true
    },
    hideSignIn () {
      this.auth.logIn.status = false
    },
    goToRecovery () {
      this.auth.recovery.status = true
      this.auth.recovery.stage = 1
    },
    changeRecoveryStage (stage) {
      this.auth.recovery.stage = stage
    },
    hideRecovery () {
      this.auth.recovery.status = false
    },
    closeHandle (event) {
      if (event.target.closest('.auth__login') === null &&
          event.target.closest('.auth__register') === null &&
          event.target.closest('.auth__recovery') === null &&
          event.target.closest('.captcha-block') === null
      ) {
        this.$store.commit('toggleAuthShowState')
      }
    }
  }
}
</script>
