<template>
  <div class="menu-burger-block userMenu"
       :class="{ 'openUser' : $store.state.showUserDropMenu }">
    <div class="menu-burger-block__content">
      <h1 class="menu-burger-block__title">{{ $store.state.account.username }}</h1>
      <ul class="menu-burger-block__list">
        <li class="menu-burger-block__list-item">
          <router-link
            class="user__area" href="#"
            to="/history"
          >История</router-link>
        </li>
        <li class="menu-burger-block__list-item">
          <router-link
            class="paybtn" href="#"
            to="/payment"
          >Пополнить баланс</router-link>
        </li>
        <li
          @click="$store.dispatch('showChangePassword')"
          class="menu-burger-block__list-item"
          v-if="$store.state.account.isVk === 0"
        ><a class="change__pass" href="#">Сменить пароль</a>
        </li>
      </ul>
      <div
        class="menu-burger-block__logo"
        @click="$store.dispatch('logOut')"
      >
        <p>Exit</p>
      </div>
    </div>
  </div>
</template>

<script>
import './userDropMenu.scss'

export default {
  name: 'userDropMenu',
  mounted () {
    const overlays = document.querySelectorAll('.overlay')
    overlays.forEach(item => {
      item.addEventListener('click', () => {
        this.$store.state.showUserDropMenu = false
      })
    })

    const historyBtn = document.querySelector('.user__area')
    const payBtn = document.querySelector('.paybtn')
    historyBtn.addEventListener('click', () => {
      this.$store.state.showUserDropMenu = false
    })
    payBtn.addEventListener('click', () => {
      this.$store.state.showUserDropMenu = false
    })
  }
}
</script>
