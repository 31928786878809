<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <main class="contacts">
        <Auth />
        <component :is="currentComponent"></component>
      </main>
    </div>
  </div>
</template>

<script>
import './other.scss'

import Auth from '@/components/Auth/Auth'
import Navigation from '@/components/Navigation/Navigation'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'

import UserTerms from '@/components/Agreement/UserTerms'
import Contacts from '@/components/Contacts/Contacts'
import PrivacyPolicy from '@/components/PrivacyPolicy/PrivacyPolicy'

export default {
  name: 'Other',
  components: {
    Auth,
    Navigation,
    AsideMenu,
    burgerMenu,
    userDropMenu,
    Contacts,
    UserTerms,
    PrivacyPolicy
  },
  computed: {
    currentComponent () {
      return this.$route.meta.component || 'contacts'
    }
  }
}
</script>
