<template>
  <div class="live">
    <div class="live-wrapper">
<!--      <article class="live__item logpass">-->
<!--        <p class="live__item-title">100 R$</p>-->
<!--        <p class="live__item-subtitle">метод log+pass</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
<!--      <article class="live__item group">-->
<!--        <p class="live__item-title">1 000 R$</p>-->
<!--        <p class="live__item-subtitle">групповой метод</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
      <div
          class="live-list"
          v-for="(item, index) in sales"
          :key="index">
        <article class="live__item method" v-if="item.type === 1">
          <p class="live__item-title">{{ item.value }} R$</p>
          <p class="live__item-subtitle">метод трансфер</p>
        </article>
        <article class="live__item logpass-sales" v-if="item.type === 2">
          <p class="live__item-title">{{ item.value }} R$</p>
          <p class="live__item-subtitle">метод log+pass</p>
        </article>
        <article class="live__item premium-i" v-if="item.type === 3">
          <div class="premium__block">
            <img src="../../assets/images/live/premiumIcon.svg" alt="icon">
            <p class="live__item-title">{{ item.value }}</p>
          </div>
          <p class="live__item-subtitle">ПРЕМИУМ РОБЛОКС</p>
        </article>
        <div class="line"></div>
      </div>
<!--      <article class="live__item premium-i">-->
<!--        <div class="premium__block">-->
<!--          <img src="../../assets/images/live/premiumIcon.svg" alt="icon">-->
<!--          <p class="live__item-title">5 000 R$</p>-->
<!--        </div>-->
<!--        <p class="live__item-subtitle">ПРЕМИУМ РОБЛОКС</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
<!--      <article class="live__item group">-->
<!--        <p class="live__item-title">1 000 R$</p>-->
<!--        <p class="live__item-subtitle">групповой метод</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
<!--      <article class="live__item group">-->
<!--        <p class="live__item-title">1 000 R$</p>-->
<!--        <p class="live__item-subtitle">групповой метод</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
<!--      <article class="live__item group">-->
<!--        <p class="live__item-title">1 000 R$</p>-->
<!--        <p class="live__item-subtitle">групповой метод</p>-->
<!--      </article>-->
<!--      <div class="line"></div>-->
<!--      <article class="live__item group">-->
<!--        <p class="live__item-title">1 000 R$</p>-->
<!--        <p class="live__item-subtitle">групповой метод</p>-->
<!--      </article>-->
    </div>
  </div>
</template>

<script>
import './live.scss'
export default {
  name: 'Live',
  data () {
    return {
      sales: []
    }
  },
  async mounted () {
    await fetch('https://live-sales.backend-tree.com/api/v1/sales/fetch')
      .then(res => res.json())
      .then(data => {
        this.sales = data
      })
  }
}
</script>
