<template>
  <div class="error404">
    <AsideMenu />
    <div class="error404__wrapper">
        <Navigation />
        <div class="error404__wrapper__main">
            <img src="../../assets/images/error404/404.png" alt="">
        </div>
    </div>
  </div>
</template>
<script>
import './error404.scss'

import AsideMenu from '@/components/asideMenu/AsideMenu'
import Navigation from '@/components/Navigation/Navigation'

export default {
  name: 'Error404',
  components: {
    AsideMenu,
    Navigation
  }
}
</script>
