<template>
  <div class="apple">
    <div class="apple-form">
      <div class="apple-form__logo">
        <img src="./../../assets/images/premium/logo.png" alt="logo">
      </div>
      <p>Сайт находится на обновлении! <br>Пожалуйста ожидайте!</p>
      <div class="apple-form__input">
        <input type="text" placeholder="Введите код" v-model="notApple" @keyup.enter="eatApple">
      </div>
      <div class="apple-form__button">
        <button @click="eatApple">Подтвердить</button>
      </div>
    </div>
  </div>
</template>
<script>
import './apple.scss'

export default {
  name: 'Apple',
  data () {
    return {
      apple: 'sevawork',
      notApple: ''
    }
  },
  methods: {
    eatApple () {
      if (this.apple === this.notApple) {
        localStorage.setItem('apple', 'apple have eaten')
        this.$router.push('/')
      }
    }
  }
}
</script>
