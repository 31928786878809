<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main class="calculator">
        <div class="calculator-wrapper">
          <div class="calculator-block">
            <div class="calculator-block-content">
              <div class="calculator-block-content__header">
                <img src="../../assets/images/calculator/logo.png" alt="logo">
              </div>
              <div class="calculator-block-content-wrapper">
                <div class="calculator-block-content-wrapper__currency">
                  <div class="calculator-block-content-wrapper__currency-item active" @click="selectCurrency('RUB')">
                    <p>Рубли</p>
                  </div>
                  <div class="calculator-block-content-wrapper__currency-arrow" @click="changeCurrency">
                    <img src="../../assets/images/calculator/arrow.svg" alt="">
                  </div>
                  <div class="calculator-block-content-wrapper__currency-item" @click="selectCurrency('RBX')">
                    <p>Робуксы</p>
                  </div>
                </div>
                <div class="calculator-block-content-wrapper__input" v-if="currentCurrency=== 'RUB'">
                  <label>Сумма в рублях</label>
                  <input type="text" v-model="userOffer.inputTotalRub" @input="computedTotalOffer" placeholder="Введите количество рублей">
                </div>
                <div class="calculator-block-content-wrapper__input" v-if="currentCurrency === 'RBX'">
                  <label>Сумма в робуксах</label>
                  <input type="text" v-model="userOffer.inputTotalRbx" @input="computedTotalOffer" placeholder="Введите количество робуксов">
                </div>
              </div>
            </div>
          </div>
          <div class="calculator-offer" :class="{openOffer: userOffer.inputTotalRub || userOffer.inputTotalRbx}">
            <div class="calculator-offer__wrapper">
              <!-- <div class="calculator-offer__item-method">
                <img src="../../assets/images/calculator/methodTransfer.svg" alt="">
                <p class="calculator-offer__item-method__text">Трансфер метод</p>
              </div> -->
              <div class="calculator-offer__item-price">
                <div class="calculator-offer__item-price__top">
                  <img src="../../assets/images/calculator/methodTransfer.svg" alt="">
                  <p>Трансфер метод</p>
                </div>
                <button @click="goToTransfer" v-if="currentCurrency === 'RUB'">
                  <img src="../../assets/images/calculator/cart.svg" alt=""> {{ userOffer.offerRbxTransfer }} R$
                </button>
                <button @click="goToTransfer" v-if="currentCurrency === 'RBX'">
                  <img src="../../assets/images/calculator/cart.svg" alt=""> {{ userOffer.offerRubTransfer  }} РУБ.
                </button>
                <p class="calculator-offer__item-price-delivery">Доставка:<span class="orange"> без задержек</span></p>
              </div>
            </div>
            <div class="calculator-offer__wrapper">
              <!-- <div class="calculator-offer__item-method">
                <img src="../../assets/images/calculator/methodTransfer.svg" alt="">
                <p class="calculator-offer__item-method__text">LogPass</p>
              </div> -->
              <div class="calculator-offer__item-price">
                <div class="calculator-offer__item-price__top">
                  <img src="../../assets/images/calculator/methodLogPass.svg" alt="">
                  <p>LOG+PASS МЕТОД</p>
                </div>
                <button @click="goToLogPass" v-if="currentCurrency === 'RUB'">
                  <img src="../../assets/images/calculator/cart.svg" alt=""> {{ userOffer.offerRbxLogPass }} R$
                </button>
                <button @click="goToLogPass" v-if="currentCurrency === 'RBX'">
                  <img src="../../assets/images/calculator/cart.svg" alt=""> {{ userOffer.offerRubLogPass }} РУБ.
                </button>
                <p class="calculator-offer__item-price-delivery">Доставка:<span class="orange"> без задержек</span></p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import './calculator.scss'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import Auth from '@/components/Auth/Auth'
import Navigation from '@/components/Navigation/Navigation'

export default {
  name: 'Calculator',
  data () {
    return {
      currentCurrency: 'RUB',
      arrowCount: 1,
      rbxRateTransfer: 0,
      rbxRateLogPass: 0,
      userOffer: {
        inputTotalRub: null,
        inputTotalRbx: null,
        offerRubTransfer: null,
        offerRbxTransfer: null,
        offerRubLogPass: null,
        offerRbxLogPass: null
      }
    }
  },
  components: {
    burgerMenu,
    userDropMenu,
    AsideMenu,
    Auth,
    Navigation
  },
  metaInfo: {
    title: 'ᐉ Калькулятор робуксов: перевести рубли в робуксы • рассчитать стоимость робуксов',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Стоимость робукса ➤ Рассчитать стоимость робкуса в рублях в конвертере 👉 Выгодный курс.'
    }]
  },
  methods: {
    changeCurrency () {
      this.currentCurrency === 'RUB' ? this.selectCurrency('RBX') : this.selectCurrency('RUB')
    },
    clearCurrency () {
      const currency = document.querySelectorAll('.calculator-block-content-wrapper__currency-item')
      currency.forEach(item => {
        if (item.classList.contains('active')) item.classList.remove('active')
      })
    },
    selectCurrency (currency) {
      const currencyBtn = document.querySelectorAll('.calculator-block-content-wrapper__currency-item')
      const arrow = document.querySelector('.calculator-block-content-wrapper__currency-arrow')
      if (this.currentCurrency !== currency) {
        this.clearCurrency()
        if (currency === 'RUB') {
          currencyBtn[0].classList.add('active')
          this.userOffer.inputTotalRbx = null
          this.userOffer.offerRbxTransfer = null
          this.userOffer.offerRbxLogPass = null
          this.currentCurrency = 'RUB'
          arrow.style.transform = `rotate(-${this.arrowCount * 180}deg)`
        } else {
          currencyBtn[1].classList.add('active')
          this.userOffer.inputTotalRub = null
          this.userOffer.offerRbxTransfer = null
          this.userOffer.offerRbxLogPass = null
          this.currentCurrency = 'RBX'
          arrow.style.transform = `rotate(-${this.arrowCount * 180}deg)`
        }
        this.arrowCount += 1
      }
    },
    computedTotalOffer () {
      if (this.currentCurrency === 'RUB') {
        this.userOffer.offerRbxTransfer = (this.userOffer.inputTotalRub / this.rbxRateTransfer).toFixed(1)
        this.userOffer.offerRbxLogPass = (this.userOffer.inputTotalRub / this.rbxRateLogPass).toFixed(1)
      } else if (this.currentCurrency === 'RBX') {
        this.userOffer.offerRubTransfer = (this.userOffer.inputTotalRbx * this.rbxRateTransfer).toFixed(1)
        this.userOffer.offerRubLogPass = (this.userOffer.inputTotalRbx * this.rbxRateLogPass).toFixed(1)
      }
    },
    goToTransfer () {
      if (this.currentCurrency === 'RUB') {
        this.$router.push(`/transfer?rubTotal=${this.userOffer.inputTotalRub}&totalRobux=${this.userOffer.offerRbxTransfer}`)
      } else if (this.currentCurrency === 'RBX') {
        this.$router.push(`/transfer?rubTotal=${this.userOffer.offerRubLogPass}&totalRobux=${this.userOffer.inputTotalRbx}`)
      }
    },
    goToLogPass () {
      if (this.currentCurrency === 'RUB') {
        this.$router.push(`/logpass?rubTotal=${this.userOffer.inputTotalRub}&totalRobux=${this.userOffer.offerRbxLogPass}`)
      } else if (this.currentCurrency === 'RBX') {
        this.$router.push(`/logpass?rubTotal=${this.userOffer.offerRubLogPass}&totalRobux=${this.userOffer.inputTotalRbx}`)
      }
    }
  },
  mounted () {
    fetch('https://rates.backend-tree.com/get_rates/root')
      .then(res => res.json())
      .then(data => {
        console.log(data)
        this.rbxRateTransfer = data.before.rate
        this.rbxRateLogPass = data.logpass.rate
      })
  }
}
</script>
