<template>
  <div class="suppliers">
    <div class="suppliers-modal" style="display: none;">
      <div class="suppliers-modal__add" style="display: none;">
        <div class="suppliers-modal__close">
          ×
        </div>
        <div class="suppliers-modal__add__header">
          <h2>CREATE NEW WORKER</h2>
          <p>ENTER YOUR WORKER NICKNAME AND PASSWORD</p>
        </div>
        <div class="suppliers-modal__add__inputs">
          <div class="suppliers-modal__add__inputs__input">
            <input type="text" placeholder="Enter nick" class="nick">
          </div>
          <div class="suppliers-modal__add__inputs__input">
            <input type="password" placeholder="Enter password" class="password">
          </div>
        </div>
        <div class="suppliers-modal__add__error">
          <p></p>
        </div>
        <div class="suppliers-modal__add__button">
          <button>Create</button>
        </div>
      </div>

      <div class="suppliers-modal__delete">
        <div class="suppliers-modal__close">
          ×
        </div>
        <div class="suppliers-modal__add__header">
          <h2>You want to delete workers</h2>
          <p>Are you sure to delete workers?</p>
        </div>
        <div class="suppliers-modal__add__button suppliers-modal__delete__button">
          <button>Confirm</button>
        </div>
      </div>
    </div>
    <div class="suppliers-header">
      <div class="suppliers-header__logo">
        <a href="/suppliers">
          <img src="./../../assets/images/suppliers/logo.png" alt="logo">
        </a>
      </div>
      <div class="suppliers-header__earn">
        <div class="suppliers-header__earn__block">
          <img src="./../../assets/images/suppliers/day-earn.png" alt="day-earn">
          <div class="suppliers-header__earn__block__text day-earn">
            <p>0 R$</p>
            <small>Sold R$ today</small>
          </div>
        </div>
        <div class="suppliers-header__earn__block">
          <img src="./../../assets/images/suppliers/week-earn.png" alt="week-earn">
          <div class="suppliers-header__earn__block__text week-earn">
            <p>0</p>
            <small>Total orders</small>
          </div>
        </div>
        <div class="suppliers-header__earn__block">
          <img src="./../../assets/images/suppliers/month-earn.png" alt="month-earn">
          <div class="suppliers-header__earn__block__text month-earn">
            <p>0</p>
            <small>Bad orders</small>
          </div>
        </div>
      </div>
      <div class="suppliers-header__earn">
        <div class="suppliers-header__earn__block">
          <img src="./../../assets/images/suppliers/queue-earn.png" alt="queue-earn">
          <div class="suppliers-header__earn__block__text queue-earn">
            <p>22500 R$</p>
            <small>R$ in queue</small>
          </div>
        </div>
        <div class="suppliers-header__earn__block">
          <img src="./../../assets/images/suppliers/queue-orders.png" alt="queue-orders">
          <div class="suppliers-header__earn__block__text queue-orders">
            <p>1</p>
            <small>Orders in queue</small>
          </div>
        </div>
      </div>
      <div class="suppliers-header__buttons">
        <button class="workers">
          <a href="/suppliers/lk">Profile</a>
        </button>
      </div>
      <div class="suppliers-header__profile">
        <div class="suppliers-header__profile__info">
          <h2>sevadp</h2>
          <p>
            <img src="./../../assets/images/suppliers/wallet.png" alt="wallet">
            <span>15070.00</span>
          </p>
        </div>
        <div class="suppliers-header__profile__menu">
          <div class="suppliers-header__profile__menu__avatar">
            <img src="https://robohash.org/sevadp?size=250x250" alt="avatar">
          </div>
          <div class="suppliers-header__profile__menu__logout" style="display: none;">
            <a href="https://rbxtree.com/suppliers/logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <div class="suppliers-panel">
      <div class="suppliers-panel__workers">
        <div class="suppliers-panel__workers__header">
          <h2>Your workers <span></span></h2>
          <div class="suppliers-panel__workers__header__controls">
            <div class="suppliers-panel__workers__header__controls__buttons">
              <button>
                Add
                <img src="./../../assets/images/suppliers/add.png" alt="add">
              </button>
              <img src="./../../assets/images/suppliers/delete.png" alt="delete">
            </div>
            <div class="suppliers-panel__workers__header__controls__date">
              <input type="date" class="from">
              <p> - </p>
              <input type="date" class="till">
              <div class="suppliers-panel__workers__header__controls__accept">
                <button>Refresh</button>
              </div>
            </div>
          </div>
        </div>
        <div class="suppliers-panel__workers__table">
          <div class="suppliers-panel__workers__table__header">
            <p class="check">
              <span></span>
            </p>
            <p class="id">N</p>
            <p class="name">Username</p>
            <p class="sum">Amount,R$</p>
            <p class="orders">Orders</p>
            <p class="bad-orders">Bad Orders</p>
            <p class="bad-orders">Premium Statistic</p>
            <p class="timer">Work time</p>
            <p class="status">Status</p>
          </div>
          <div class="suppliers-panel__workers__table__rows"></div>
        </div>
      </div>
      <div class="suppliers-panel__withdraw">
        <div class="suppliers-panel__withdraw__history">
          <h2>Withdrawals History</h2>
          <div class="suppliers-panel__withdraw__history__table">
            <div class="suppliers-panel__withdraw__history__table__header">
              <p class="sum">Amount, $</p>
              <p class="wallet">Wallet</p>
            </div>
          <div class="suppliers-panel__withdraw__history__table__rows"></div>
        </div>
      </div>
      <div class="suppliers-panel__withdraw__output">
        <h2>Withdraw</h2>
        <div class="suppliers-panel__withdraw__output__wrapper">
          <div class="suppliers-panel__withdraw__output__wrapper__balance">
            <p>Account balance: <span class="account-balance">15070.00 $</span></p>
            <p>Available for withdrawal: <span class="available-balance">15070.00 $</span></p>
          </div>
          <div class="suppliers-panel__withdraw__output__wrapper__inputs">
            <div class="suppliers-panel__withdraw__output__wrapper__inputs__input">
              <p>Wallet</p>
              <input class="wallet" type="text">
            </div>
            <div class="suppliers-panel__withdraw__output__wrapper__inputs__input">
              <p>Amount</p>
              <input class="amount" type="text">
            </div>
          </div>
          <div class="suppliers-panel__withdraw__output__wrapper__button">
            <button>Withdraw</button>
          </div>
          <div class="suppliers-panel__withdraw__output__wrapper__message">
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import './suppliers.scss'

export default {
  name: 'Suppliers',
  components: {
  },
  data () {
    return {
      supplierData: {},
      withdrawls: [],
      workers: []
    }
  },
  created () {
    const userId = localStorage.getItem('user_id')
    const accessToken = this.$cookies.get('access_token')

    const requestHeaders = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch(`https://api.suppliers.backend-tree.com/api/v1/suppliers/fetch_supplier?user_id=${userId}`, requestParams)
      .then((res) => res.json())
      .then((data) => {
        this.supplierData = data

        this.initPayments()
        this.initWorkers()
      })
      .catch((err) => console.log(err))
  },
  mounted () {
  },
  methods: {
    initPayments () {
      const accessToken = this.$cookies.get('access_token')

      const requestHeaders = new Headers({
        Authorization: `Bearer ${accessToken}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch('https://api.suppliers.backend-tree.com/api/v1/withdrawals/fetch_withdrawals', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.withdrawls = data
        })
        .catch((err) => console.log(err))
    },
    initWorkers () {
      const accessToken = this.$cookies.get('access_token')

      const requestHeaders = new Headers({
        Authorization: `Bearer ${accessToken}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch('https://api.suppliers.backend-tree.com/api/v1/suppliers/fetch_workers', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.workers = data
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>
