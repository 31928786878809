<template>
  <div>
    <Preloader />
    <router-view />
    <SupportChat />
    <Gift v-if="false" />
    <div id="vk_community_messages"></div>
  </div>
</template>

<script>
import http from './../../utils/http'

import Preloader from '@/components/PreLoader/PreLoader.vue'

import SupportChat from '@/components/SupportChat/SupportChat.vue'
import Gift from '@/components/Gift/Gift'

import '@/scss/_reset.scss'
import '@/scss/fonts/font.css'
import '@/scss/_main.scss'

export default {
  name: 'App',
  components: {
    Gift,
    Preloader,
    SupportChat
  },
  created () {
    if (['rbxrange.ru', 'www.rbxrange.ru', 'rbxtree.ru', 'www.rbxtree.ru', 'rbxtree.com'].includes(location.host)) {
      location.href = 'https://rbxtree.pro/'
    }

    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.$store.state.showPreloader = false
      }
    }

    setInterval(() => {
      if (this.$cookies.get('access_token')) {
        this.$store.dispatch('authorization', this.$cookies.get('access_token'))
      }
    }, 30000)

    this.getCountry()
  },
  methods: {
    async getCountry () {
      const countryResponse = await http.get('https://auth.backend-tree.com/current-country')
      this.$store.state.ipCountry = countryResponse.data.country.toLowerCase()
    }
  }
}
</script>
