<template>
  <div class="selectedUsersModal"></div>
</template>

<script>
import './selectedUsersModal.scss'

export default {
  name: 'SelectedUsersModal'
}
</script>
