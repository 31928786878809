<template>
  <div class="slider">
    <div class="slider__counter">
      <div class="circle active" data-index="0"></div>
      <div class="circle" data-index="1"></div>
      <div class="circle" data-index="2"></div>
<!--      <div class="circle" data-index="3"></div>-->
<!--      <div class="circle" data-index="4"></div>-->
    </div>
    <div class="slider-wrapper">
      <!-- <div class="slider__item" data-index="0">
        <img data-position="0" class="slider__item-bg" src="../../assets/images/slider/new-method-2560.png" alt="">
      </div> -->
      <router-link to="/transfer" class="slider__item" data-index="0">
        <picture>
           <source srcset="../../assets/images/slider/1-banner.webp" type="image/webp">
          <img src="../../assets/images/slider/1-banner.png" class="slider__item-bg" data-position="0" alt="">
        </picture>
        <img v-if="false" class="slider__item-bg__arrow premium__arrow" src="../../assets/images/slider/arrow-bg-left.svg" alt="">
        <div v-if="false" class="btn-block left-back">
          <button class="slider__item-btn mega">Купить сейчас</button>
          <div class="btn-block__under"></div>
        </div>
      </router-link>
      <router-link to="/transfer" class="slider__item" data-index="1">
        <picture>
          <source srcset="../../assets/images/slider/2-banner.webp" type="image/webp">
          <img src="../../assets/images/slider/2-banner.png" class="slider__item-bg" data-position="1" alt="">
        </picture>
      </router-link>
      <router-link to="/transfer" class="slider__item" data-index="2">
        <picture>
          <source srcset="../../assets/images/slider/3-banner.webp" type="image/webp">
          <img src="../../assets/images/slider/3-banner.png" class="slider__item-bg" data-position="2" alt="">
        </picture>
      </router-link>
    </div>
    <div class="slider-arrow__right">
      <img src="../../assets/images/slider/arrow.svg" alt="arrow-left">
    </div>
    <progress max="100" value="20" class="slider-timebar"></progress>
  </div>
</template>

<script>
import './slider.scss'
import { sliderInit } from '@/utils/slider'

export default {
  name: 'Slider',
  mounted () {
    sliderInit()
  }
}
</script>
