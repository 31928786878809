<template>
  <div class="burger menu-burger-block"
      :class="{ 'open' : $store.state.showBurgerMenu }">
    <div class="menu-burger-block__content">
      <h1 class="menu-burger-block__title">Навигация</h1>
      <ul class="menu-burger-block__list">
        <li class="menu-burger-block__list-item"><router-link to="/">главная</router-link></li>
        <li class="menu-burger-block__list-item"><router-link to="/faq">F.A.Q</router-link></li>
        <li v-if="false" class="menu-burger-block__list-item">
          <a href="#">
            бонусы
            <img class="bonus__soon" src="../../assets/images/burgerMenu/soon.svg" alt="">
          </a>
        </li>
        <li v-if="$store.state.isAuth" class="menu-burger-block__list-item"><router-link to="/history">история</router-link></li>
      </ul>
      <div class="menu-burger-block__logo">
        <div class="social">
          <a href="https://vk.com/rbxtree" target="_blank">
            <svg class="vk" width="27" height="17" viewBox="0 0 27 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.7594 14.538C26.6868 14.4165 26.2379 13.4411 24.0777 11.4362C21.8163 9.33677 22.1201 9.67767 24.8439 6.04759C26.5028 3.8368 27.1661 2.4867 26.9585 1.90953C26.7611 1.35936 25.5409 1.5045 25.5409 1.5045L21.4838 1.52813C21.4838 1.52813 21.1835 1.48762 20.959 1.62095C20.7413 1.75258 20.6012 2.05635 20.6012 2.05635C20.6012 2.05635 19.9582 3.7676 19.1009 5.22234C17.2935 8.29213 16.5695 8.45414 16.2741 8.26344C15.5873 7.81959 15.7594 6.47793 15.7594 5.52611C15.7594 2.55083 16.21 1.31042 14.8802 0.989773C14.438 0.883453 14.114 0.812573 12.985 0.800759C11.537 0.785571 10.3101 0.805822 9.61647 1.14503C9.15406 1.37118 8.79797 1.87578 9.01567 1.90447C9.284 1.93991 9.89155 2.06817 10.2139 2.50695C10.6307 3.0723 10.6155 4.34477 10.6155 4.34477C10.6155 4.34477 10.8552 7.8466 10.0569 8.282C9.50846 8.58071 8.75746 7.97148 7.14578 5.18521C6.32053 3.75917 5.69611 2.18124 5.69611 2.18124C5.69611 2.18124 5.57629 1.88759 5.36196 1.73064C5.10207 1.53994 4.73754 1.47919 4.73754 1.47919L0.87963 1.50281C0.87963 1.50281 0.300774 1.51969 0.0881333 1.77114C-0.100881 1.9956 0.0729446 2.45801 0.0729446 2.45801C0.0729446 2.45801 3.09379 9.52409 6.51292 13.0867C9.65022 16.3522 13.2111 16.1379 13.2111 16.1379H14.8245C14.8245 16.1379 15.3122 16.0839 15.5603 15.8156C15.7898 15.5692 15.7814 15.1068 15.7814 15.1068C15.7814 15.1068 15.7493 12.9415 16.7551 12.6226C17.7458 12.3087 19.0182 14.7152 20.3666 15.6417C21.386 16.3421 22.1606 16.1885 22.1606 16.1885L25.767 16.1379C25.767 16.1379 27.6538 16.0215 26.7594 14.538Z" fill="#D6D6D6"/>
            </svg>
          </a>
          <a href="https://discord.com/invite/8mF9fjTnQy" target="_blank">
            <svg class="dis" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.32575 25.6118H21.5554L20.7325 22.9475L22.7009 24.6367L24.5049 26.2535L27.7916 29V2.99062C27.7094 1.37387 26.3174 0 24.5919 0L4.33179 0.003625C2.6075 0.003625 1.20825 1.37992 1.20825 2.99667V22.62C1.20825 24.325 2.60509 25.6118 4.32575 25.6118ZM17.0713 6.86696L17.0314 6.88146L17.0459 6.86696H17.0713ZM7.85046 8.40033C10.0653 6.78842 12.1183 6.86575 12.1183 6.86575L12.2838 7.02888C9.57354 7.67533 8.34467 8.88608 8.34467 8.88608C8.34467 8.88608 8.67334 8.72538 9.24729 8.48008C12.8977 7.04458 16.884 7.1485 20.5742 8.96583C20.5742 8.96583 19.3429 7.83363 16.7994 7.10862L17.0241 6.8875C17.3758 6.88871 19.2366 6.95396 21.2279 8.41C21.2279 8.41 23.4561 12.2163 23.4561 16.8925C23.3824 16.8031 22.0738 18.9056 18.7061 18.9781C18.7061 18.9781 18.1358 18.3328 17.7298 17.7698C19.6994 17.2043 20.4365 16.0732 20.4365 16.0732C19.79 16.4805 19.2003 16.7221 18.7218 16.9638C17.986 17.2876 17.2489 17.4471 16.513 17.6103C13.0282 18.1758 11.0719 17.2296 9.22313 16.4792L8.59117 16.1578C8.59117 16.1578 9.32704 17.2888 11.2181 17.8543C10.7215 18.421 10.2297 19.0651 10.2297 19.0651C6.86325 18.9853 5.63559 16.8828 5.63559 16.8828C5.63559 12.1993 7.85046 8.40033 7.85046 8.40033Z" fill="#D6D6D6"/>
              <path d="M17.289 15.4316C18.1481 15.4316 18.8477 14.7066 18.8477 13.8124C18.8477 12.9243 18.1517 12.1993 17.289 12.1993V12.2029C16.4335 12.2029 15.7326 12.9255 15.7302 13.8197C15.7302 14.7066 16.4298 15.4316 17.289 15.4316Z" fill="#D6D6D6"/>
              <path d="M11.7087 15.4316C12.5678 15.4316 13.2674 14.7066 13.2674 13.8124C13.2674 12.9243 12.5726 12.1993 11.7135 12.1993L11.7087 12.2029C10.8495 12.2029 10.1499 12.9255 10.1499 13.8197C10.1499 14.7066 10.8495 15.4316 11.7087 15.4316Z" fill="#D6D6D6"/>
            </svg>
          </a>
          <a href="https://t.me/rbxtreegg" target="_blank">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4917 0.916626C12.6074 0.916626 9.78788 1.77192 7.38966 3.37436C4.99144 4.9768 3.12226 7.2544 2.01848 9.91916C0.914705 12.5839 0.625906 15.5161 1.18861 18.345C1.75131 21.1739 3.14024 23.7724 5.17975 25.8119C7.21927 27.8514 9.81777 29.2404 12.6467 29.8031C15.4755 30.3658 18.4078 30.077 21.0725 28.9732C23.7373 27.8694 26.0149 26.0002 27.6173 23.602C29.2198 21.2038 30.0751 18.3843 30.0751 15.5C30.0752 13.5848 29.6981 11.6884 28.9653 9.91897C28.2325 8.14957 27.1583 6.54185 25.8041 5.18763C24.4498 3.83341 22.8421 2.75922 21.0727 2.02639C19.3033 1.29357 17.4069 0.916467 15.4917 0.916626V0.916626ZM20.1285 23.014C20.0739 23.1501 19.9909 23.2729 19.8849 23.3743C19.779 23.4757 19.6526 23.5533 19.5142 23.6018C19.3758 23.6504 19.2287 23.6688 19.0826 23.6558C18.9365 23.6428 18.7949 23.5988 18.6673 23.5266L14.7084 20.4504L12.1675 22.7945C12.1085 22.8381 12.0396 22.8661 11.9669 22.8761C11.8943 22.886 11.8203 22.8775 11.7519 22.8513L12.2389 18.4932L12.2545 18.5055L12.2645 18.4195C12.2645 18.4195 19.3885 11.9335 19.6787 11.6572C19.9725 11.3816 19.8755 11.3218 19.8755 11.3218C19.8923 10.9856 19.3483 11.3218 19.3483 11.3218L9.90923 17.3943L5.9783 16.0556C5.9783 16.0556 5.37455 15.839 5.31768 15.3629C5.25789 14.8904 5.99798 14.6337 5.99798 14.6337L21.6269 8.42484C21.6269 8.42484 22.9117 7.85242 22.9117 8.80038L20.1285 23.014Z" fill="#D6D6D6"/>
            </svg>
          </a>
        </div>
        <a href="#">
          <img src="../../assets/images/burgerMenu/logo.svg" alt="logo">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import './burgerMenu.scss'

export default {
  name: 'burgerMenu',
  mounted () {
    const overlays = document.querySelectorAll('.overlay')
    overlays.forEach(item => {
      item.addEventListener('click', () => {
        this.$store.state.showBurgerMenu = false
      })
    })

    const links = document.querySelectorAll('.menu-burger-block__list-item')
    links.forEach(link => {
      link.addEventListener('click', () => {
        this.$store.state.showBurgerMenu = false
      })
    })
  }
}
</script>
