<template>
  <div class="loader" >
    <div class="loader__bar"></div>
    <div class="loader__bar"></div>
    <div class="loader__bar"></div>
  </div>
</template>

<script>
import './loaderBar.scss'
export default {
  name: 'LoaderBar'
}
</script>
