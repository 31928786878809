<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main>
        <div class="history">
          <div v-if="loading" class="transfer-loader">
            <Loader />
          </div>
          <div class="history-header">
            <div class="history-header__block active">
              <p>трансфер</p>
              <small>{{ totalOrders }}</small>
            </div>
          </div>
          <div class="history-table">
            <div v-if="ordersLoading" class="transfer-loader">
              <Loader />
            </div>
            <div class="history-table__header">
              <p class="id">ID</p>
              <p class="nickname">игровой никнейм</p>
              <p class="sum-robux">сумма, R$</p>
              <p class="sum-rub">сумма, ₽</p>
              <p class="status">статус</p>
              <p class="description">описание</p>
              <p class="timer">Таймер</p>
            </div>
            <div class="history-table__rows">
              <div
                v-for="order in orders"
                :key="order._id"
                class="history-table__row"
              >
                <p class="id">{{ order._id.slice(-4) }}</p>
                <p class="nickname">{{ order.username }}</p>
                <p class="sum-robux">{{ order.robux_amount }} R$</p>
                <p class="sum-rub">{{ order.rub_amount.toFixed(2) }} ₽</p>
                <p class="status">
                  <span class="error" v-if="order.status === 'error' || order.status === 'system_error'">ошибка</span>
                  <span class="waiting" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(order.status)">Ожидание</span>
                  <span class="success" v-if="order.status === 'success'">Успешно</span>
                </p>
                <p class="description">{{ order.description }}</p>
                <p class="timer">{{ parseDate(order._id) }}</p>
              </div>
            </div>
          </div>
          <div class="history-footer">
            <div class="history-footer__nav">
              <div class="history-footer__nav__left" v-on="currentPage - 1 <= 0 ? { } : { click: () => changeOrders(currentPage - 1) }">
                <img src="./../../assets/images/history/left.svg" alt="left">
              </div>
              <div class="history-footer__nav__center">
                <p v-for="i in totalPages" :key="i" :class="{ active: i === currentPage }" @click="changeOrders(i)">{{ i }}</p>
              </div>
              <div class="history-footer__nav__right" v-on="currentPage + 1 > totalPages ? { } : { click: () => changeOrders(currentPage + 1) }">
                <img src="./../../assets/images/history/right.svg" alt="right">
              </div>
            </div>
            <div class="history-footer__info">
              <p>Показаны {{ orders.length }} из {{ totalOrders }} записей</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import './history.scss'

import Navigation from '@/components/Navigation/Navigation'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import Auth from '@/components/Auth/Auth'
import Loader from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'History',
  components: {
    Navigation,
    AsideMenu,
    burgerMenu,
    Auth,
    userDropMenu,
    Loader
  },
  data () {
    return {
      loading: true,
      ordersLoading: false,
      totalPages: 1,
      currentPage: 1,
      totalOrders: 0,
      orders: [],
      timers: []
    }
  },
  created () {
    this.loading = true

    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch('https://pending.backend-tree.com/get_history?count=7&page=1', requestParams)
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.totalPages = data.total_pages
          this.totalOrders = data.total_orders
          this.orders = data.orders

          // this.orders.forEach((order) => {
          //   const orderDate = new Date(order.date)
          //   orderDate.setHours(orderDate.getHours() + 1)
          //   const delta = orderDate - Date.now()

          //   this.timers.push({ [order._id]: delta })
          // })

          // this.initTimers()
        }

        this.loading = false
      })
      .catch((err) => console.log(err))
  },
  methods: {
    changeOrders (page) {
      this.ordersLoading = true
      this.currentPage = page

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(`https://pending.backend-tree.com/get_history?count=7&page=${page}`, requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            this.timers = []
            this.totalPages = data.total_pages
            this.totalOrders = data.total_orders
            this.orders = data.orders

            // this.orders.forEach((order) => {
            //   const orderDate = new Date(order.date)
            //   orderDate.setHours(orderDate.getHours() + 1)
            //   const delta = orderDate - Date.now()

            //   this.timers.push({ [order._id]: delta })
            // })

            // this.initTimers()

            this.ordersLoading = false
          } else {
            this.ordersLoading = false
          }
        })
        .catch((err) => console.log(err))
    },
    initTimers () {
      this.timers.forEach((timer) => {
        const key = Object.entries(timer)[0][0]

        const iid = setInterval(() => {
          timer[key] -= 1000

          if (timer[key] <= 0) {
            clearInterval(iid)
          }
        }, 1000)
      })
    },
    getTimer (id) {
      const rawTimer = this.timers.find((t) => Object.keys(t)[0] === id)
      const rawDelta = Object.values(rawTimer)[0]

      if (rawDelta <= 0) {
        return '00:00:00'
      } else {
        const formatedHours = ('0' + String(Math.trunc(rawDelta / 1000 / 60 / 60))).slice(-2)
        const formatedMinutes = ('0' + String(Math.trunc(rawDelta / 1000 / 60 % 60))).slice(-2)
        const formatedSeconds = ('0' + String(Math.trunc(rawDelta / 1000 % 60 % 60))).slice(-2)

        return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
      }
    },
    parseDate (id) {
      const foundOrder = this.orders.find((order) => order._id === id)
      const date = new Date(foundOrder.date)

      const day = ('0' + date.getDate()).substr(-2)
      const month = ('0' + (date.getMonth() + 1)).substr(-2)
      const year = date.getFullYear()
      const hour = ('0' + date.getHours()).substr(-2)
      const minutes = ('0' + date.getMinutes()).substr(-2)

      return `${day}.${month}.${year} ${hour}:${minutes}`
    }
  }
}
</script>
