<template>
  <div class="app"
       :class="{'fixed': $store.state.showBurgerMenu}">
    <div class="blackout-instruction" v-if="$store.state.showPremiumInstruction">
      <div class="premium-modal blackout-instruction__modal">
        <div class="blackout-instruction__modal-img">
          <img src="../../assets/images/cookiesInstruction/q.svg" alt="">
        </div>
        <div class="blackout-instruction__modal-content">
          <p class="blackout-instruction__modal-content__text">Хочешь воспользоваться инструкцией?</p>
          <div class="blackout-instruction__modal-content__buttons">
            <div class="blackout-instruction__modal-content__buttons-yes">
              <a href="https://vk.com/@rbxtree-cookies" @click="$store.state.showPremiumInstruction = false" target="_blank" v-if="cookie.status">Да</a>
              <a href="https://vk.com/@rbxtree-pquick" @click="$store.state.showPremiumInstruction = false" target="_blank" v-if="code.status">Да</a>
            </div>
            <div class="blackout-instruction__modal-content__buttons-no" @click="$store.state.showPremiumInstruction = false"><p>Нет</p></div>
          </div>
        </div>
      </div>
    </div>
    <burgerMenu />
    <userDropMenu />
    <AsideMenu />
    <div class="wrapper"
         :class="{'translateRight': $store.state.showBurgerMenu, 'translateLeft' : $store.state.showUserDropMenu}">
      <div class="overlay"></div>
      <Navigation />
      <Auth />
      <main>
        <div class="premium">
          <LoaderBar v-if="loading" />
          <div class="hint logpass-order-hint" v-if="(areSA === false) && (cookie.status === false && code.status === false) && loading === false">
            <div class="hint-border">
              <div class="premium-hint">
                <p class="premium-hint__title">Внимание!</p>
                <p class="premium-hint__text">На данный момент все робуксы распроданы!</p>
              </div>
            </div>
          </div>
          <div class="lp-cookie__orders orders-prem" v-if="orders.length > 0">
            <div class="lp-cookie__orders__left" @click="changeOrder(-1)">
              <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z" fill="#069C38" fill-opacity="0.29"/>
              </svg>
            </div>
            <div class="lp-cookie__orders__center">
              <div class="lp-cookie__orders__center__top" :class="{ code: orders[currentOrder].action_required && orders[currentOrder].is_quick }">
                <p class="lp-cookie__orders__center__top__number">#{{ currentOrder + 1 }}</p>
                <div class="lp-cookie__orders__center__top__logo">
                  <img src="./../../assets/images/logpass/logo.png" alt="logo">
                </div>
                <div class="lp-cookie__orders__center__top__timer" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(orders[currentOrder].status)">
                  <img src="./../../assets/images/logpass/timer.svg" alt="timer" class="rotate">
                  <p>{{ this.displayTimer() }}</p>
                </div>
                <div
                  class="timer__counter"
                  v-if="orders[currentOrder].status === 'success'"
                >
                  <img :src="require('./../../assets/images/logpass/allow.svg')" alt="allow">
                </div>
                <div
                  class="timer__counter"
                  v-if="orders[currentOrder].status === 'error' || orders[currentOrder].status === 'system_error'"
                >
                  <img :src="require('./../../assets/images/logpass/deny.svg')" alt="deny">
                </div>
                <div class="lp-cookie__orders__center__top__status">
                  <p>{{ orders[currentOrder].description }}</p>
                  <!-- <p v-if="orders[currentOrder].action_required && orders[currentOrder].is_quick">Введите код на сайте <a href="https://www.roblox.com/crossdevicelogin/confirmcode">roblox.com</a> для продолжения покупки!</p>
                  <small v-if="orders[currentOrder].action_required && orders[currentOrder].is_quick">
                    {{ orders[currentOrder].quick_code }}
                  </small> -->
                  <div class="lp-cookie__input" style="margin-top: 20px;" v-if="orders[currentOrder].action_required && orders[currentOrder].is_quick">
                    <div class="lp-cookie__input__mark">
                      <img src="./../../assets/images/logpass/lock.svg" alt="user">
                    </div>
                    <div class="lp-cookie__input__input">
                      <input
                        placeholder="Введите код"
                        type="text"
                        maxlength="6"
                        @keyup="(event) => { faCode = event.target.value.replace(/[^\d]/g,'') }"
                        v-model="faCode"
                      >
                      <div class="lp-cookie__input__input__title">
                        <p>CODE</p>
                      </div>
                      <div class="lp-cookie__input__input__error">
                      <p>{{ faCodeError }}</p>
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="orders[currentOrder].action_required && orders[currentOrder].is_quick"
                    @click="completeEnteringCode"
                    style="margin-top: 30px;"
                  >
                    Указать код
                  </button>
                </div>
                <div class="lp-cookie__orders__center__top__table">
                  <div class="lp-cookie__orders__center__top__table__header">
                    <p>ИГРОВОЙ НИК</p>
                    <p>Тип премиума</p>
                    <p>ИТОГО, РУБ</p>
                  </div>
                  <div class="lp-cookie__orders__center__top__table__content">
                    <p>{{ orders[currentOrder].username }}</p>
                    <p>{{ premium.map[orders[currentOrder].premium_type] }}</p>
                    <p>{{ orders[currentOrder].rub_amount.toFixed(2) }} ₽ </p>
                  </div>
                </div>
              </div>
              <div
                class="lp-cookie__orders__center__bottom"
                @click="makeAnother"
                v-if="areSA"
              >
                <small>+</small>
                <p>сделать еще заказ</p>
              </div>
            </div>
            <div class="lp-cookie__orders__right" @click="changeOrder(1)">
              <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z" fill="#069C38" fill-opacity="0.29"/>
              </svg>
            </div>
          </div>
          <a
            :href="getInstructionLink()"
            target="_blank"
            class="premium-help"
            v-if="orders.length === 0"
          >
            <img src="./../../assets/images/premium/help.svg" alt="help">
            <small>ИНСТРУКЦИЯ</small>
          </a>
          <div
            v-if="((cookie.status && cookie.stage === 1) || (code.status && code.stage === 1)) && orders.length === 0"
            @click="goBack"
            class="premium-back"
          >
            <img src="./../../assets/images/premium/back.png" alt="back">
          </div>
          <div
            v-if="cookie.status === false && code.status === false && orders.length === 0"
            class="premium-start"
          >
            <div class="premium-start__header">
              <h2>ПРЕМИУМ РОБЛОКС</h2>
              <h4>Получи робуксы в подарок!</h4>
              <p>Выберите один из предложенных вариантов</p>
            </div>
            <div class="premium-start__blocks">
              <div
                v-if="false"
                class="premium-start__blocks__block"
                @click="startCookie"
              >
                <div class="premium-start__blocks__block__image">
                  <img src="./../../assets/images/premium/cookies.svg" alt="cookie">
                </div>
                <div class="premium-start__blocks__block__text">
                  <p>Cookie</p>
                  <small>не требуется вводить код</small>
                </div>
              </div>
              <div
                v-if="true"
                class="premium-start__blocks__block"
                @click="startCode"
              >
                <div class="premium-start__blocks__block__image">
                  <img src="./../../assets/images/premium/code.png" alt="code">
                </div>
                <div class="premium-start__blocks__block__text">
                  <p>2FA</p>
                  <small>ТРЕБУЕТСЯ ВВОДИТЬ КОД С ПОЧТЫ</small>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="(cookie.status && cookie.stage === 1) || (code.status && code.stage === 1)"
            class="premium-choosed"
          >
            <div class="premium-choosed__premium" v-show="visiblePremiumPlans">
              <div
                class="premium-choosed__premium__block active"
                @click="changePremium(450, premium.rates.low, 'low', $event)"
              >
                <div class="premium-choosed__premium__block__img">
                  <img src="./../../assets/images/premium/premium-ico.svg" alt="ico">
                </div>
                <div class="premium-choosed__premium__block__text">
                  <p>premium 450</p>
                  <small>Получи <img src="./../../assets/images/premium/coin.svg"> 450 в этом месяце</small>
                </div>
                <div class="premium-choosed__premium__block__price">
                  <p><span>₽ {{ premium.rates.low }}</span> на месяц</p>
                </div>
              </div>
              <div
                class="premium-choosed__premium__block"
                @click="changePremium(1000, premium.rates.medium, 'medium', $event)"
              >
                <div class="premium-choosed__premium__block__img">
                  <img src="./../../assets/images/premium/premium-ico.svg" alt="ico">
                </div>
                <div class="premium-choosed__premium__block__text">
                  <p>premium 1 000</p>
                  <small>Получи <img src="./../../assets/images/premium/coin.svg"> 1 000 в этом месяце</small>
                </div>
                <div class="premium-choosed__premium__block__price">
                  <p><span>₽ {{ premium.rates.medium }}</span> на месяц</p>
                </div>
              </div>
              <div
                class="premium-choosed__premium__block"
                @click="changePremium(2200, premium.rates.high, 'high', $event)"
              >
                <div class="premium-choosed__premium__block__img">
                  <img src="./../../assets/images/premium/premium-ico.svg" alt="ico">
                </div>
                <div class="premium-choosed__premium__block__text">
                  <p>premium 2 200</p>
                  <small>Получи <img src="./../../assets/images/premium/coin.svg"> 2 200 в этом месяце</small>
                </div>
                <div class="premium-choosed__premium__block__price">
                  <p><span>₽ {{ premium.rates.high }}</span> на месяц</p>
                </div>
              </div>
              <button class="premium-choosed__premium__button" v-if="!visiblePremiumForm" @click="visiblePremiumForm = true; visiblePremiumPlans = false">Продолжить</button>
            </div>
            <div
              v-if="code.status && code.stage === 1"
              class="premium-choosed__cookie"
              v-show="visiblePremiumForm"
            >
              <div
                v-if="loaderOn"
                class="premium-choosed__cookie__loader"
              >
                <img src="./../../assets/images/premium/loader.svg" alt="">
              </div>
              <LoaderBar v-if="promoLoading" style="opacity: 0.5"/>
              <div class="premium-choosed__cookie__image">
                <img src="./../../assets/images/premium/code-ico.svg" alt="">
              </div>
              <div class="premium-choosed__cookie__header code">
                <img src="./../../assets/images/premium/logo.png" alt="logo">
              </div>
              <div class="premium-choosed__cookie__input code">
                <div class="premium-choosed__cookie__input__mark">
                  <img src="./../../assets/images/premium/rub_ico.svg" alt="rub">
                </div>
                <div class="premium-choosed__cookie__input__wrapper code-input">
                  <input
                    type="text"
                    :value="`${premium.price} ₽`"
                    readonly
                  >
                  <div
                    class="premium-choosed__cookie__input__title"
                  >
                    СУММА К ОПЛАТЕ
                  </div>
                  <div class="premium-choosed__cookie__input__sale" v-show="saleActive">
                    <p>Скидка: {{ saleSize }} ₽</p>
                  </div>
                </div>
              </div>
              <div class="premium-choosed__cookie__input code">
                <div class="premium-choosed__cookie__input__mark">
                  <img src="./../../assets/images/premium/promocode.svg" alt="cookie" style="filter: invert(1);">
                </div>
                <div class="premium-choosed__cookie__input__wrapper code-input">
                  <input
                    type="text"
                    placeholder="Promocode"
                    v-model="code.values.promocode"
                    @input="checkPromo"
                    @keyup.enter="buyPremiumCode"
                    :class="code.errors.promocode.status === false && code.errors.promocode.show ? 'error': ''"
                  >
                  <div class="premium-choosed__cookie__input__wrapper__error" v-if="code.errors.promocode.status === false && code.errors.promocode.show">
                    <p>{{ code.errors.promocode.message }}</p>
                  </div>
                  <div
                    class="premium-choosed__cookie__input__title"
                    :class="code.errors.promocode.status === false && code.errors.promocode.show ? 'error': ''"
                  >
                    Promocode
                  </div>
                </div>
              </div>
              <div class="premium-choosed__cookie__button" :class="{ inactive: validateBuy() === false }">
                <button @click="buyPremiumCode">ПОДТВЕРДИТЬ</button>
              </div>

              <div class="premium-choosed__cookie__addition code">
                <p>Премиум подписка покупается только на <span>1 месяц без автопродления</span></p>
              </div>
            </div>
            <div
              v-if="cookie.status && cookie.stage === 1"
              class="prem-cookie premium-choosed__cookie"
            >
              <div
                v-if="loaderOn"
                class="premium-choosed__cookie__loader"
              >
                <img src="./../../assets/images/premium/loader.svg" alt="">
              </div>
              <LoaderBar v-if="promoLoading" style="opacity: 0.5"/>
              <div class="premium-choosed__cookie__image">
                <img src="./../../assets/images/premium/cookie-ico.svg" alt="">
              </div>
              <div class="premium-choosed__cookie__header">
                <img src="./../../assets/images/premium/logo.png" alt="logo">
              </div>
              <div class="premium-choosed__cookie__input">
                <div class="premium-choosed__cookie__input__mark">
                  <img src="./../../assets/images/premium/form-cookie-ico.svg" alt="cookie">
                </div>
                <div class="premium-choosed__cookie__input__wrapper">
                  <input
                    type="text"
                    placeholder="Cookie"
                    v-model="cookie.values.cookie"
                    @change="validateCookie"
                    @keyup.enter="buyPremiumCookie"
                    :class="cookie.errors.cookie.status === false && cookie.errors.cookie.show ? 'error': ''"
                  >
                  <div class="premium-choosed__cookie__input__wrapper__error" v-if="cookie.errors.cookie.status === false && cookie.errors.cookie.show">
                    <p>{{ cookie.errors.cookie.message }}</p>
                  </div>
                  <div
                    class="premium-choosed__cookie__input__title"
                    :class="cookie.errors.cookie.status === false && cookie.errors.cookie.show ? 'error': ''"
                  >
                    COOKIE
                  </div>
                </div>
              </div>
              <div class="premium-choosed__cookie__input">
                <div class="premium-choosed__cookie__input__mark">
                  <img src="./../../assets/images/premium/rub_ico.svg" alt="rub">
                </div>
                <div class="premium-choosed__cookie__input__wrapper">
                  <input
                    type="text"
                    :value="`${premium.price} ₽`"
                    readonly
                  >
                  <div
                    class="premium-choosed__cookie__input__title"
                  >
                    СУММА К ОПЛАТЕ
                  </div>
                </div>
              </div>
<!--              <div class="premium-choosed__cookie__input">-->
<!--                <div class="premium-choosed__cookie__input__mark">-->
<!--                  <img src="./../../assets/images/premium/promocode.svg" alt="cookie" style="filter: invert(1);">-->
<!--                </div>-->
<!--                <div class="premium-choosed__cookie__input__wrapper">-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    placeholder="Promocode"-->
<!--                    v-model="cookie.values.promocode"-->
<!--                    @input="checkPromo"-->
<!--                    @keyup.enter="buyPremiumCookie"-->
<!--                    :class="cookie.errors.promocode.status === false && cookie.errors.promocode.show ? 'error': ''"-->
<!--                  >-->
<!--                  <div class="premium-choosed__cookie__input__wrapper__error" v-if="cookie.errors.promocode.status === false && cookie.errors.promocode.show">-->
<!--                    <p>{{ cookie.errors.promocode.message }}</p>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="premium-choosed__cookie__input__title"-->
<!--                    :class="cookie.errors.promocode.status === false && cookie.errors.promocode.show ? 'error': ''"-->
<!--                  >-->
<!--                    Promocode-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="premium-choosed__cookie__button" :class="{ inactive: validateBuy() === false }">
                <button @click="buyPremiumCookie">ПОДТВЕРДИТЬ</button>
              </div>
              <div class="premium-choosed__cookie__addition">
                <p>Премиум подписка покупается только на <span>1 месяц без автопродления</span></p>
              </div>
            </div>
          </div>
          <div
            v-if="cookie.status && cookie.stage === 3"
            class="premium-cookie__final"
          >
            <div class="premium-cookie__final__current">
              <div class="premium-cookie__final__current__left">
                <img src="./../../assets/images/premium/final-left.svg" alt="left">
              </div>
              <div class="premium-cookie__final__current__center">
                <div class="premium-cookie__final__current__center__image">
                  <img src="./../../assets/images/premium/cookie-ico.svg" alt="cookie">
                </div>
                <div class="premium-cookie__final__current__center__number">
                  <p>#1</p>
                </div>
                <div class="premium-cookie__final__current__center__header">
                  <img src="./../../assets/images/premium/logo.png" alt="logo">
                </div>
                <div class="premium-cookie__final__current__center__timer">
                  <img src="./../../assets/images/premium/loader.svg" alt="">
                  <p>00:59:59</p>
                </div>
                <div class="premium-cookie__final__current__center__addition">
                  <p>ПОЖАЛУЙСТА, ОЖИДАЙТЕ выполнения заказа</p>
                </div>
                <div class="premium-cookie__final__current__center__table">
                  <div class="premium-cookie__final__current__center__table__header">
                    <p>ИГРОВОЙ НИК</p>
                    <p>ПОДПИСКА</p>
                    <p>ИТОГО, РУБ</p>
                  </div>
                  <div class="premium-cookie__final__current__center__table__content">
                    <p class="username">{{ $store.state.account.username }}</p>
                    <p class="premium-label">PREMIUM {{ premium.type }}</p>
                    <p class="sum">{{ premium.price }} ₽</p>
                  </div>
                </div>
              </div>
              <div class="premium-cookie__final__current__right">
                <img src="./../../assets/images/premium/final-right.svg" alt="right">
              </div>
            </div>
            <div
              @click="goBack"
              class="premium-cookie__final__more"
            >
              <small>+</small>
              <p>сделать еще заказ</p>
            </div>
          </div>
          <div class="premium-nickname" v-if="cookie.stage === 2 || code.stage === 2">
            <LoaderBar class="lp-cookie__wrapper-loader" v-if="buyingLoading"/>
            <div class="lp-cookie__start">
              <div class="lp-cookie__header">
                <img src="./../../assets/images/logpass/logo.png" alt="logo">
              </div>
              <div class="lp-cookie__input">
                <div class="lp-cookie__input__mark premium-mark">
                  <img src="./../../assets/images/logpass/user.svg" alt="user">
                </div>
                <div class="lp-cookie__input__input premium-input">
                  <input
                    placeholder="Введите никнейм"
                    type="text"
                    v-model="searchNickname"
                    @input="searchUsers"
                    :class="errors.username.status === false && errors.username.show ? 'error': ''"
                  >
                  <div class="premium-choosed__cookie__input__wrapper__error" v-if="errors.username.status === false && errors.username.show">
                    <p>{{ errors.username.message }}</p>
                  </div>
                  <div class="lp-cookie__input__input__title">
                    <p>ИГРОВОЙ НИКНЕЙМ</p>
                  </div>
                </div>
              </div>
              <div class="lp-cookie__wrapper">
                <LoaderBar class="lp-cookie__wrapper-loader" v-if="isSearching"/>
                <div class="lp-cookie__wrapper-users" v-else>
                  <div
                    class="lp-cookie__wrapper-users__item"
                    v-for="user in foundUsers"
                    :key="user.user_id"
                    @click="selectUser(user.user_id, user.username, $event)"
                  >
                    <p class="lp-cookie__wrapper-users__item-title">{{ user.username }}</p>
                    <img :src="user.avatar_uri" alt="">
                  </div>
                  <div class="lp-cookie__wrapper-users-not" v-if="foundUsers.length === 0">
                    <p>Ничего не найдено</p>
                  </div>
                </div>
              </div>
              <div class="lp-cookie__input password prem-password" v-if="code.status">
                <div class="lp-cookie__input__mark">
                  <img src="./../../assets/images/logpass/lock.svg" alt="user">
                </div>
                <div class="lp-cookie__input__input">
                  <input
                    placeholder="Введите пароль"
                    type="password"
                    v-model="code.values.password"
                  >
                  <div class="lp-cookie__input__input__title">
                    <p>ПАРОЛЬ</p>
                  </div>
                  <div class="lp-cookie__input__input__error">
                    <p>{{ code.errors.password.message }}</p>
                  </div>
                </div>
              </div>
              <div class="lp-cookie__button" :class="{ inactive: isSelectUser === false }">
                <button @click="createOrder">Продолжить</button>
              </div>
            </div>
          </div>
          <div
            v-if="code.status && code.stage === 3"
            class="premium-cookie__final"
          >
            <div class="premium-cookie__final__current">
              <div class="premium-cookie__final__current__left">
                <img src="./../../assets/images/premium/final-left.svg" alt="left">
              </div>
              <div class="premium-cookie__final__current__center code">
                <div class="premium-cookie__final__current__center__image">
                  <img src="./../../assets/images/premium/code-ico.svg" alt="code">
                </div>
                <div class="premium-cookie__final__current__center__number">
                  <p>#1</p>
                </div>
                <div class="premium-cookie__final__current__center__header">
                  <img src="./../../assets/images/premium/logo.png" alt="logo">
                </div>
                <div class="premium-cookie__final__current__center__timer">
                  <img src="./../../assets/images/premium/loader.svg" alt="">
                  <p>00:59:59</p>
                </div>
                <div class="premium-cookie__final__current__center__addition">
                  <p>ПОЖАЛУЙСТА, ОЖИДАЙТЕ выполнения заказа</p>
                </div>
                <div class="premium-choosed__cookie__input code-final">
                  <div class="premium-choosed__cookie__input__mark order-mark">
                    <img src="./../../assets/images/auth/lock.svg" alt="lock">
                  </div>
                  <div class="premium-choosed__cookie__input__wrapper">
                    <input
                      type="text"
                      placeholder="123456"
                      v-model="code.values.code"
                      @change="validateCode"
                      @keyup.enter="sendCode"
                      :class="code.errors.code.status === false && code.errors.code.show ? 'error': ''"
                    >
                    <div class="premium-choosed__cookie__input__wrapper__error" v-if="code.errors.code.status === false && code.errors.code.show">
                      <p>{{ code.errors.code.message }}</p>
                    </div>
                    <div
                      class="premium-choosed__cookie__input__title"
                      :class="code.errors.code.status === false && code.errors.code.show ? 'error': ''"
                    >
                      Введите код
                    </div>
                  </div>
                </div>
                <div class="premium-cookie__final__current__center__table">
                  <div class="premium-cookie__final__current__center__table__header">
                    <p>ИГРОВОЙ НИК</p>
                    <p>ПОДПИСКА</p>
                    <p>ИТОГО, РУБ</p>
                  </div>
                  <div class="premium-cookie__final__current__center__table__content">
                    <p class="username">{{ $store.state.account.username }}</p>
                    <p class="premium-label">PREMIUM {{ premium.type }}</p>
                    <p class="sum">{{ premium.price }} ₽</p>
                  </div>
                </div>
              </div>
              <div class="premium-cookie__final__current__right">
                <img src="./../../assets/images/premium/final-right.svg" alt="right">
              </div>
            </div>
            <div
              @click="goBack"
              class="premium-cookie__final__more"
            >
              <small>+</small>
              <p>сделать еще заказ</p>
            </div>
          </div>
        </div>
      </main>
    </div>
    <audio id="audio-alert" src="./../../assets/audio/alert.mp3" style="display: none;"></audio>
  </div>
</template>
<script>
import './../../components/LogPassCode/logPassCode.scss'
import './../../components/LogPassCookie/logPassCookie.scss'
import './premium.scss'

import Navigation from '@/components/Navigation/Navigation'
import Auth from '@/components/Auth/Auth'
import AsideMenu from '@/components/asideMenu/AsideMenu'
import burgerMenu from '@/components/BurgerMenu/burgerMenu'
import userDropMenu from '@/components/UserDropMenu/userDropMenu'
import LoaderBar from '@/components/LoaderBar/LoaderBar'

import { validateRobloxPassword } from '@/utils/validate.js'

export default {
  name: 'Premium',
  components: {
    Navigation,
    AsideMenu,
    burgerMenu,
    Auth,
    userDropMenu,
    LoaderBar
  },
  metaInfo: {
    meta: [{
      vmid: 'robots',
      name: 'robots',
      content: 'noindex, follow'
    }]
  },
  data () {
    return {
      areSA: false,
      visiblePremiumForm: true,
      visiblePremiumPlans: true,
      promoTimeout: null,
      promoLoading: false,
      isSearching: false,
      buyingLoading: false,
      isSelectUser: false,
      foundUsers: [],
      userId: '',
      username: '',
      searchNickname: '',
      orders: [],
      loading: true,
      currentOrder: 0,
      timer: null,
      intervalId: null,
      orderIId: null,
      searchTimeout: null,
      promoCodeData: {},
      saleActive: false,
      isValidateCode: true,
      saleSize: 0,
      faCode: '',
      faCodeError: '',
      errors: {
        username: {
          status: false,
          show: false,
          message: ''
        }
      },
      cookie: {
        status: false,
        stage: 1,
        values: {
          cookie: '',
          promocode: ''
        },
        timers: {
          cookieFinal: {
            id: null,
            value: ''
          }
        },
        errors: {
          cookie: {
            status: false,
            show: false,
            message: ''
          },
          promocode: {
            status: false,
            show: false,
            message: ''
          }
        }
      },
      code: {
        status: false,
        stage: 1,
        values: {
          promocode: '',
          password: '',
          code: '',
          login: ''
        },
        timers: {
          codeFinal: {
            id: null,
            value: ''
          }
        },
        errors: {
          promocode: {
            status: false,
            show: false,
            message: ''
          },
          login: {
            status: false,
            show: false,
            message: ''
          },
          password: {
            status: false,
            show: false,
            message: ''
          },
          code: {
            status: false,
            show: false,
            message: ''
          }
        }
      },
      premium: {
        type: 1000,
        typeText: 'low',
        price: 340,
        rates: {
          low: null,
          medium: null,
          high: null
        },
        map: {
          low: 'PREMIUM 450',
          medium: 'PREMIUM 1 000',
          high: 'PREMIUM 2 200'
        }
      },
      loaderOn: false
    }
  },
  created () {
    this.getOrders()
    this.getRates()

    if (this.$cookies.get('access_token') === null) {
      this.loading = false

      this.$store.state.additionalSync.push(true)
      fetch('https://logpass.backend-tree.com/api/v1/orders/are_sales_available?method=premium')
        .then((res) => res.json())
        .then((data) => {
          this.areSA = data.are_sales_available
          this.$store.state.additionalSync.pop()

          if (this.areSA === false) {
            this.$router.push('/')
            this.$store.commit('showNotification')
            // this.$store.state.salesNotification = true
            //
            // setTimeout(() => {
            //   this.$store.state.salesNotification = false
            // }, 8000)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.additionalSync.pop()
        })
    }

    this.orderIId = setInterval(() => {
      if (this.cookie.status === false && this.code.status === false) {
        this.getOrders()
      }
    }, 30000)
  },
  mounted () {
    if (window.document.body.clientWidth <= 1020) this.visiblePremiumForm = false

    setInterval(() => {
      const audioAlert = document.querySelector('#audio-alert')

      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].action_required && this.orders[i].is_quick) {
          audioAlert.play()
          this.currentOrder = i
          break
        }
      }
    }, 7000)
  },
  destroyed () {
    clearInterval(this.orderIId)

    if (this.intervalId) {
      clearInterval(this.intervalId)
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }
  },
  methods: {
    getInstructionLink () {
      if (this.cookie.status) {
        return 'https://vk.com/@rbxtree-cookies'
      } else if (this.code.status) {
        return 'https://vk.com/@rbxtree-pquick'
      }

      return 'https://vk.com/@rbxtree-kak-kupit-premium'
    },
    areSalesAvailable (cb, sync = true) {
      if (sync) {
        this.$store.state.additionalSync.push(true)
      }

      fetch('https://logpass.backend-tree.com/api/v1/orders/are_sales_available?method=premium')
        .then((res) => res.json())
        .then((data) => {
          cb(data)

          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
        .catch((err) => {
          console.log(err)
          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
    },
    getRates () {
      fetch('https://rates.backend-tree.com/get_rates/root')
        .then((res) => res.json())
        .then((data) => {
          this.premium.price = data.premium.buyer.level_1
          this.premium.rates.low = data.premium.buyer.level_1
          this.premium.rates.medium = data.premium.buyer.level_2
          this.premium.rates.high = data.premium.buyer.level_3
        })
        .catch((err) => console.log(err))
    },
    getOrders () {
      if (this.$cookies.get('access_token')) {
        this.$store.dispatch('authorization', this.$cookies.get('access_token'))

        const requestHeaders = new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        })

        const requestParams = {
          method: 'GET',
          headers: requestHeaders
        }

        fetch('https://logpass.backend-tree.com/api/v1/orders/fetch_last_orders/premium', requestParams)
          .then((res) => res.json())
          .then((data) => {
            this.areSalesAvailable((sdata) => {
              this.areSA = sdata.are_sales_available
              this.loading = false
              this.orders = data.orders

              if (this.orders.length > 0) {
                this.setTimer()
              } else if (sdata.are_sales_available === false) {
                this.$router.push('/')
                this.$store.state.salesNotification = true

                setTimeout(() => {
                  this.$store.state.salesNotification = false
                }, 5000)
              }
            }, false)
          })
          .catch((err) => console.log(err))
      }
    },
    setTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      const orderDate = new Date(this.orders[this.currentOrder].datetime)
      orderDate.setHours(orderDate.getHours() + 2)
      orderDate.setMinutes(orderDate.getMinutes() + (new Date().getTimezoneOffset() * -1))
      this.timer = orderDate - Date.now()

      this.intervalId = setInterval(() => {
        this.timer -= 1000
      }, 1000)
    },
    displayTimer () {
      if (this.timer <= 0) {
        return '00:00:00'
      }

      const formatedHours = ('0' + String(Math.trunc(this.timer / 1000 / 60 / 60))).slice(-2)
      const formatedMinutes = ('0' + String(Math.trunc(this.timer / 1000 / 60 % 60))).slice(-2)
      const formatedSeconds = ('0' + String(Math.trunc(this.timer / 1000 % 60 % 60))).slice(-2)

      return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
    },
    changeOrder (direction) {
      if (direction === 1) {
        this.currentOrder = this.currentOrder + 1 >= this.orders.length ? 0 : this.currentOrder + 1
      } else if (direction === -1) {
        this.currentOrder = this.currentOrder - 1 < 0 ? this.orders.length - 1 : this.currentOrder - 1
      }

      this.setTimer()
    },
    completeEnteringCode () {
      this.faCodeError = ''

      if (isNaN(this.faCode) || this.faCode.length !== 6) {
        this.faCodeError = 'Код должен быть длиной 6 символов'
        return
      }

      const requestBody = JSON.stringify({
        order_id: this.orders[this.currentOrder]._id,
        quick_code: this.faCode,
        is_premium_order: true
      })

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`,
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'PATCH',
        headers: requestHeaders,
        body: requestBody
      }

      fetch('https://logpass.backend-tree.com/api/v1/orders/set_quick_code', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loading = true
          this.getOrders()
        })
        .catch((err) => console.log(err))
    },
    searchUsers () {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        if (this.searchNickname.length < 3) {
          return
        }

        this.isSearching = true

        this.isSelectUser = false

        fetch(`https://logpass.backend-tree.com/api/v1/roblox/search_users?keyword=${this.searchNickname}`)
          .then((res) => res.json())
          .then((data) => {
            this.isSearching = false
            this.foundUsers = data.found_users
          })
          .catch((err) => {
            this.isSearching = false
            console.log(err)
          })
      }, 1000)
    },
    createOrder () {
      this.code.errors.password.message = ''

      if (this.isSelectUser === false) {
        return
      }

      if (validateRobloxPassword(this.code.values.password) === false && this.code.status) {
        this.code.errors.password.message = 'Пароль неверный'
        return
      }

      if (this.$cookies.get('access_token') === null) {
        this.$store.state.isAuth = false
      }

      if (this.$store.state.isAuth === false) {
        this.$store.commit('toggleAuthShowState')
        return
      }

      this.areSalesAvailable((sdata) => {
        if (sdata.are_sales_available === false) {
          this.$router.push('/')
          this.$store.state.salesNotification = true

          setTimeout(() => {
            this.$store.state.salesNotification = false
          }, 5000)
          return
        }

        fetch(`https://logpass.backend-tree.com/api/v1/roblox/check_premium?user_id=${this.userId}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.is_premium) {
              this.errors.username.status = false
              this.errors.username.show = true
              this.errors.username.message = 'Уже есть премиум'

              return
            }

            this.errors.username.show = false
            this.errors.username.status = true

            let promocode = null

            if (this.cookie.status && this.cookie.values.promocode) {
              promocode = this.cookie.values.promocode
            } else if (this.code.status && this.code.values.promocode) {
              promocode = this.code.values.promocode
            }

            const requestBody = JSON.stringify({
              username: this.username,
              cookie: this.cookie.status ? this.cookie.values.cookie : null,
              is_quick: this.code.status,
              promocode_value: promocode,
              premium_type: this.premium.typeText,
              is_paid: this.$store.state.account.rubBalance >= this.premium.price,
              password: this.code.status ? this.code.values.password : null
            })

            const requestHeaders = new Headers({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.$cookies.get('access_token')}`
            })

            const requestParams = {
              method: 'POST',
              headers: requestHeaders,
              body: requestBody
            }

            this.buyingLoading = true

            fetch('https://logpass.backend-tree.com/api/v1/orders/create_order', requestParams)
              .then((res) => res.json())
              .then((data) => {
                this.buyingLoading = false

                if (data.is_paid === false) {
                  this.$store.commit('setPaymentsData', { order: data, methodType: 2, isPremium: true })
                  return
                }

                if (data._id) {
                  this.loading = true
                  this.getOrders()

                  this.cookie.status = false
                  this.cookie.stage = 1
                  this.code.status = false
                  this.code.stage = 1
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
      }, false)
    },
    checkLogin () {
      return this.code.values.login.length > 3
    },
    checkPassword () {
      return validateRobloxPassword(this.code.values.password)
    },
    checkPromo () {
      if (this.promoTimeout) {
        clearTimeout(this.promoTimeout)
      }

      const methodType = this.cookie.status ? 'cookie' : 'code'
      const code = this.cookie.status ? this.cookie.values.promocode : this.code.values.promocode

      this[methodType].errors.promocode.status = false
      this[methodType].errors.promocode.show = false
      this[methodType].errors.promocode.message = ''
      this.saleActive = false
      this.isValidateCode = false

      if (code === '') {
        this.isValidateCode = true
        return
      }

      this.premium.price = this.premium.rates[this.premium.typeText]

      this.promoTimeout = setTimeout(() => {
        if (this.$cookies.get('access_token') === null) {
          this.$store.state.isAuth = false
        }

        if (this.$store.state.isAuth === false) {
          this.$store.commit('toggleAuthShowState')
          return
        }

        this.promoLoading = true

        fetch(`https://logpass.backend-tree.com/api/v1/promocodes/fetch_one/${code}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('access_token')}`
          }
        })
          .then((res) => res.json())
          .then((data) => {
            this.promoLoading = false

            if (data.message) {
              this[methodType].errors.promocode.status = false
              this[methodType].errors.promocode.show = true
              this[methodType].errors.promocode.message = 'Такого промокода не существует'
              this.saleActive = false
              this.isValidateCode = false
              return
            }

            this.promoCodeData = data

            if (this.promoCodeData.premium_types.includes(this.premium.typeText) === false) {
              this[methodType].errors.promocode.status = false
              this[methodType].errors.promocode.show = true
              this[methodType].errors.promocode.message = 'Не подходит для данного вида премиума'
              this.saleActive = false
              this.isValidateCode = false
              return
            }

            this.saleSize = this.premium.price - this.premium.price * (1 - this.promoCodeData.sale)

            this.premium.price *= (1 - this.promoCodeData.sale)
            this.saleActive = true
            this.isValidateCode = true
          })
          .catch((err) => {
            this.promoLoading = false
            console.log(err)
          })
      }, 1200)
    },
    selectUser: function (userId, username, event) {
      this.userId = userId
      this.username = username

      if (this.isSelectUser) {
        const user = document.querySelector('.lp-cookie__wrapper-users__item.selectUser')
        user.classList.remove('selectUser')
      }

      const target = event.target.closest('.lp-cookie__wrapper-users__item')
      target.classList.add('selectUser')
      this.isSelectUser = true
    },
    changePremium (type, price, typeText, event) {
      this.premium.type = type
      this.premium.typeText = typeText
      this.premium.price = price

      if (this.promoCodeData.premium_types) {
        const methodType = this.cookie.status ? 'cookie' : 'code'

        if (this.promoCodeData.premium_types.includes(this.premium.typeText) === false) {
          this[methodType].errors.promocode.status = false
          this[methodType].errors.promocode.show = true
          this[methodType].errors.promocode.message = 'Не подходит для данного вида премиума'
          this.saleActive = false
          this.isValidateCode = false
        } else {
          this[methodType].errors.promocode.status = true
          this[methodType].errors.promocode.show = false
          this[methodType].errors.promocode.message = ''
          this.saleActive = true
          this.isValidateCode = true

          this.saleSize = this.premium.price - this.premium.price * (1 - this.promoCodeData.sale)
          this.premium.price *= (1 - this.promoCodeData.sale)
        }
      }

      document.querySelector('.premium-choosed__premium__block.active').classList.remove('active')

      const choosedTarget = event.target.closest('.premium-choosed__premium__block')
      choosedTarget.classList.add('active')
    },
    startCode () {
      this.code.status = true
      this.code.stage = 1
      this.$store.state.showPremiumInstruction = true
    },
    startCookie () {
      this.cookie.status = true
      this.cookie.stage = 1
      this.$store.state.showPremiumInstruction = true
    },
    validateBuy () {
      const methodType = this.cookie.status ? 'cookie' : 'code'

      return this.promoLoading === false &&
            this[methodType].errors.promocode.show === false &&
            this.isValidateCode
    },
    buyPremiumCode () {
      if (this.validateBuy() === false) {
        return
      }

      this.loaderOn = true

      this.code.stage = 2
      this.loaderOn = false
    },
    buyPremiumCookie () {
      if (this.validateBuy() === false) {
        return
      }

      this.validateCookie()

      if (this.cookie.errors.cookie.status === false) {
        return
      }

      this.loaderOn = true

      this.cookie.stage = 2
      this.loaderOn = false
    },
    sendCode () {
      this.validateCode()

      if (this.code.errors.code.status === false) {
        return
      }

      console.log('SEND')
    },
    goBack () {
      this.resetData()

      if (window.document.body.clientWidth <= 1020) {
        this.visiblePremiumPlans = true
        this.visiblePremiumForm = false
      }
    },
    resetData () {
      const methodType = this.cookie.status ? 'cookie' : 'code'

      this[methodType].status = false
      this[methodType].values.promocode = ''
      this[methodType].errors.promocode.status = false
      this[methodType].errors.promocode.show = false
      this[methodType].errors.promocode.message = ''
      this.saleActive = false
      this.isValidateCode = true
      this.foundUsers = []
      this.isSelectUser = false
      this.searchNickname = ''

      this.premium.type = 1000
      this.premium.typeText = 'medium'
      this.premium.price = 375
      this.promoCodeData = {}
      this.saleSize = 0
    },
    makeAnother () {
      this.resetData()
      this.orders = []
      clearInterval(this.orderIId)
    },
    validateCodeNickname () {
      if (this.code.values.nickname.length === 0) {
        this.code.errors.nickname.status = false
        this.code.errors.nickname.show = true
        this.code.errors.nickname.message = 'Поле пустое'
      } else {
        this.code.errors.nickname.status = true
        this.code.errors.nickname.show = false
        this.code.errors.nickname.message = ''
      }
    },
    validateCodePassword () {
      if (this.code.values.password.length === 0) {
        this.code.errors.password.status = false
        this.code.errors.password.show = true
        this.code.errors.password.message = 'Поле пустое'
      } else {
        this.code.errors.password.status = true
        this.code.errors.password.show = false
        this.code.errors.password.message = ''
      }
    },
    validateCookie () {
      if (this.cookie.values.cookie.length === 0) {
        this.cookie.errors.cookie.status = false
        this.cookie.errors.cookie.show = true
        this.cookie.errors.cookie.message = 'Поле пустое'
      } else {
        fetch(`https://logpass.backend-tree.com/api/v1/roblox/check_cookie?cookie=${this.cookie.values.cookie}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.is_cookie_valid) {
              this.cookie.errors.cookie.status = true
              this.cookie.errors.cookie.show = false
              this.cookie.errors.cookie.message = ''
            } else {
              this.cookie.errors.cookie.status = false
              this.cookie.errors.cookie.show = true
              this.cookie.errors.cookie.message = 'Invalid cookie'
            }
          })
      }
    },
    validateCode () {
      if (this.code.values.code.length !== 6) {
        this.code.errors.code.status = false
        this.code.errors.code.show = true
        this.code.errors.code.message = 'Длина кода должна быть равна 6'
      } else {
        this.code.errors.code.status = true
        this.code.errors.code.show = false
        this.code.errors.code.message = ''
      }
    }
  }
}
</script>
