<template>
  <div class="transfer__wrapper">
    <div class="transfer-instruction">
      <div class="transfer-instruction__wrapper">
        <div class="transfer-instruction__wrapper__lock"></div>
        <div class="transfer-instruction__wrapper__logo">
          <img src="./../../../assets/images/transfer/logo.svg" alt="logo">
        </div>
        <div class="transfer-instruction__wrapper__addition">
          <p>Установите цену VIP сервера на <span>{{ price }} R$</span></p>
        </div>
        <div class="transfer-instruction__wrapper__content">
          <div class="transfer-instruction__wrapper__content__title">
            <h2>Configure place</h2>
          </div>
          <div class="transfer-instruction__wrapper__content__table">
            <div class="transfer-instruction__wrapper__content__table__nav">
              <p :class="currentMenu === 'basic' ? 'active' : ''">Basic Settings</p>
              <p>Game Icon</p>
              <p>Thumbnails</p>
              <p :class="currentMenu === 'access' ? 'active' : ''">
                Access
                <span v-if="currentMenu === 'basic'"></span>
              </p>
              <p>Permissions</p>
              <p>Version History</p>
              <p>Developer Products</p>
              <p>Games</p>
            </div>
            <div
              v-if="currentMenu === 'basic'"
              class="transfer-instruction__wrapper__content__table__basic"
            >
              <h2>Basic Settings</h2>
              <div class="transfer-instruction__wrapper__content__table__basic__name">
                <small>Name:</small>
                <input type="text" :value="data.placeName" readonly>
              </div>
              <div class="transfer-instruction__wrapper__content__table__basic__description">
                <small>Description:</small>
                <p>If you have build <span>Premium benefits</span> intoy your game, please list those benefits in the descipriton</p>
                <textarea type="text" value="" readonly></textarea>
                <p>Updating the start place's name or description will also apply the game.</p>
              </div>
              <div class="transfer-instruction__wrapper__content__table__basic__genre">
                <small>Genre:</small>
                <select>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
            <div
              v-if="currentMenu === 'access'"
              class="transfer-instruction__wrapper__content__table__access"
            >
              <h2>Access</h2>
              <div class="transfer-instruction__wrapper__content__table__access__device">
                <small>Playable devices:</small>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="checkbox" checked readonly>
                  <p>Computer</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="checkbox" checked readonly>
                  <p>Phone</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="checkbox" checked readonly>
                  <p>Tablet</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="checkbox" readonly>
                  <p>Console</p>
                </div>
              </div>
              <div class="transfer-instruction__wrapper__content__table__access__game">
                <small>Game access</small>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="checkbox" checked readonly>
                  <p>Sell access to this game</p>
                </div>
              </div>
              <div class="transfer-instruction__wrapper__content__table__access__max">
                <small>Maximum Player Count</small>
                <select>
                  <option value="10">10</option>
                </select>
              </div>
              <div class="transfer-instruction__wrapper__content__table__access__fill">
                <small>Server Fill:</small>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="radio" checked readonly>
                  <p>Roblox optimizes server fill for me</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="radio" readonly>
                  <p>Fill each server as full as possible</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__device__input">
                  <input type="radio" readonly>
                  <p>Customize how many server slots to reserve</p>
                </div>
              </div>
              <div class="transfer-instruction__wrapper__content__table__access__access">
                <small>Access:</small>
                <select>
                  <option value="Everyone">Everyone</option>
                </select>
              </div>
              <div class="transfer-instruction__wrapper__content__table__access__private">
                <small>Private Servers:</small>
                <div class="transfer-instruction__wrapper__content__table__access__private__input">
                  <input type="checkbox" checked readonly>
                  <p>Allow Private Servers</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__input">
                  <input type="radio" readonly>
                  <p>Free</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__input">
                  <input type="radio" readonly checked>
                  <p>Paid</p>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__price-input price">
                  <p>Price:</p>
                  <img src="./../../../assets/images/premium/coin.svg" alt="coin">
                  <input type="text" value="100" readonly>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__price-input fee">
                  <p>Marketplace Fee:</p>
                  <img src="./../../../assets/images/premium/coin.svg" alt="coin">
                  <small>30</small>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__price-input earn">
                  <p>You earn:</p>
                  <img src="./../../../assets/images/premium/coin.svg" alt="coin">
                  <small>70</small>
                </div>
                <div class="transfer-instruction__wrapper__content__table__access__private__buttons">
                  <button>Save</button>
                  <button class="cancel">Cancel</button>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="transfer-instruction__wrapper__buttons">
          <a :href="`https://www.roblox.com/places/${this.data.placeId}/update#`" target="_blank">изменить цену</a>
          <button @click="changeModal">Подтвердить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './transferInstruction.scss'

export default {
  name: 'TransferInstruction',
  props: ['data'],
  data () {
    return {
      currentMenu: 'basic',
      price: 0,
      fee: 0,
      earn: 1000,
      intervalId: null,
      timerId: null
    }
  },
  mounted () {
    const ordersCount = Math.ceil(this.data.totalRobux / 350)
    this.price = Math.round((this.data.totalRobux / ordersCount) / 0.7)
    this.fee = this.price - (this.data.totalRobux / ordersCount)

    this.init()

    this.intervalId = setInterval(() => {
      this.init()
    }, 4500 + (500 * String(this.price).length - 1) + 2000)
  },
  methods: {
    init () {
      try {
        this.currentMenu = 'basic'

        document.querySelector('.transfer-instruction__wrapper__content').scrollBy(0, -500)

        setTimeout(() => {
          this.currentMenu = 'access'
        }, 1500)

        setTimeout(() => {
          const scrollBlock = document.querySelector('.transfer-instruction__wrapper__content')
          const lastBlockHeight = document.querySelector('.transfer-instruction__wrapper__content__table__access__private').offsetHeight
          const height = scrollBlock.offsetHeight

          scrollBlock.scrollBy({
            top: height + lastBlockHeight + 100,
            behavior: 'smooth'
          })
        }, 2000)

        setTimeout(() => {
          const priceInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.price input')
          const feeInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.fee small')
          const earnInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.earn small')
          priceInput.value = 10
          feeInput.innerHTML = 3
          earnInput.innerHTML = 7
        }, 3000)

        setTimeout(() => {
          const priceInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.price input')
          const feeInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.fee small')
          const earnInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.earn small')
          priceInput.value = 1
          feeInput.innerHTML = 0
          earnInput.innerHTML = 1
        }, 3500)

        setTimeout(() => {
          const priceInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.price input')
          const feeInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.fee small')
          const earnInput = document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.earn small')
          priceInput.value = null
          feeInput.innerHTML = 0
          earnInput.innerHTML = 0
        }, 4000)

        console.log(this.price, this.fee, this.data.totalRobux)

        let currentDisplayedPrice = ''

        for (let i = 0; i < String(this.price).length; i++) {
          setTimeout(() => {
            currentDisplayedPrice += String(this.price)[i]
            const currentEarn = Math.round(Number(currentDisplayedPrice) * 0.7)
            const currentFee = Number(currentDisplayedPrice) - Number(currentEarn)

            document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.price input').value = currentDisplayedPrice
            document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.fee small').innerHTML = currentFee
            document.querySelector('.transfer-instruction__wrapper__content__table__access__private__price-input.earn small').innerHTML = currentEarn
          }, 4500 + (500 * i))
        }

        setTimeout(() => {
          document.querySelector('.transfer-instruction__wrapper__content__table__access__private__buttons span').style.display = 'block'
        }, 4500 + (500 * String(this.price).length - 1))
      } catch (err) {

      }
    },
    changeModal () {
      clearInterval(this.intervalId)

      this.$emit('changeModal', {
        modal: 'TransferPayment'
      })
    }
  }
}
</script>
