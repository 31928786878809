<template>
  <div class="searchUserModal"></div>
</template>

<script>
import './searchUserModal.scss'

export default {
  name: 'SearchUserModal'
}
</script>
