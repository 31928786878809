<template>
  <div class="qiwi-verification">
    <div class="qiwi-verification__wrapper">
      <img
        src="./../../assets/images/QiwiVerification/close.svg"
        alt="close"
        class="qiwi-verification__wrapper__close"
        @click="close"
      >
      <img
        src="./../../assets/images/QiwiVerification/tree-branch.svg"
        alt=""
        class="qiwi-verification__wrapper__tree-right"
      >
      <img
        src="./../../assets/images/QiwiVerification/tree-branch.svg"
        alt=""
        class="qiwi-verification__wrapper__tree-left"
      >
      <h2 class="qiwi-verification__wrapper__header">
        ДЕТАЛИ ПОПОЛНЕНИЯ
      </h2>
      <div class="qiwi-verification__wrapper__passport">
        <p>У Вас есть Паспорт РФ?</p>
        <div class="qiwi-verification__wrapper__passport__blocks">
          <p :class="{ active: havePassport === false }" @click="havePassport = false">Нет</p>
          <p :class="{ active: havePassport }" @click="havePassport = true">Да</p>
        </div>
      </div>
      <p class="qiwi-verification__wrapper__message" v-if="havePassport === false">
        Так как у Вас нет Паспорта РФ, Вы не сможете пройти верификацию в платежной системе Qiwi.
      </p>
      <p class="qiwi-verification__wrapper__message" v-if="havePassport">
        Вам необходимо пройти верификацию на сайте Qiwi, чтобы комиссия была меньше.
      </p>
      <p class="qiwi-verification__wrapper__message" v-if="havePassport === false">
        Комиссия будет составлять 7.5%
      </p>
      <p class="qiwi-verification__wrapper__message" v-if="havePassport">
        Комиссия будет составлять 3%
      </p>
      <button class="qiwi-verification__wrapper__button" @click="create">
        ПОДТВЕРДИТЬ
      </button>
    </div>
  </div>
</template>

<script>
import './qiwiVerification.scss'

export default {
  name: 'SupportChat',
  props: ['body', 'close'],
  data () {
    return {
      havePassport: false
    }
  },
  methods: {
    create () {
      if (this.havePassport) {
        this.body.payment_type = null
        this.body.payment_system = 'payok'
      } else {
        this.body.payment_type = 'qiwi'
        this.body.payment_system = 'gamemoney'
      }

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: JSON.stringify(this.body),
        headers: requestHeaders
      }

      fetch('https://topups.backend-tree.com/api/v1/topups/create', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.redirect_uri) {
            location.href = data.redirect_uri
            return
          }

          const formUrl = data.m ? 'https://pay.freekassa.ru/' : 'https://pay.gmpays.com/api/terminal/create'
          let formHTML = ''

          Object.keys(data).forEach((key) => {
            formHTML += `<input type="hidden" name="${key}" value="${data[key]}"/>`
          })

          const formBlock = document.createElement('form')
          formBlock.action = formUrl
          formBlock.method = data.m ? 'GET' : 'POST'

          formBlock.innerHTML = formHTML

          document.body.appendChild(formBlock)
          console.log(formBlock, formBlock.innerHTML)
          formBlock.submit()
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>
