module.exports.authCallback = async function () {
  if (!window) return
  const INTERCOM_APP_ID = window.intercomAppId
  const visitorId = window.Intercom('getVisitorId')
  const intercomResponse = await fetch('https://api-iam.intercom.io/messenger/web/ping', {
    credentials: 'include',
    method: 'POST',
    body: `app_id=${INTERCOM_APP_ID}&v=3&user_data={"anonymous_id":"${visitorId}"}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  }).then(res => res.json())
    .catch()

  if (!intercomResponse) return

  const body = {
    external_id: this.$store.state.account.userId,
    intercom_id: intercomResponse.user.id,
    email: this.$store.state.account.email
  }

  await fetch('https://auth.backend-tree/api/v1/user/intercom_login', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(res => res.json())
    .catch()
}
