<template>
  <div class="lp-cookie lp-code">
    <div class="arrow-back" @click="backLogPass">
      <img src="../../assets/images/logpass/arrow-back.svg" alt="" />
    </div>
    <LoaderBar v-if="loading" />
    <div
      class="lp-cookie__exclusive"
      :class="{
        'animate__animated animate__rollIn': exclusiveShow,
        'animate__animated animate__rollOut': exclusiveAnimationLeave,
      }"
      v-if="exclusiveShow && false"
    >
      <LoaderBar v-if="buyingLoading" />
      <div class="lp-cookie__type">
        <img src="./../../assets/images/logpass/ex-icon.png" alt="cookie" />
      </div>
      <div
        class="lp-cookie__ex code second exclus inactive"
        v-if="normalAvailable === false"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/auth.svg" alt="tag" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>ВЕРНУТЬСЯ К МЕТОДУ LOG+PASS</p>
        </div>
      </div>
      <div
        class="lp-cookie__ex code second exclus"
        @click="backLogPass"
        v-if="normalAvailable"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/auth.svg" alt="tag" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>ВЕРНУТЬСЯ К МЕТОДУ LOG+PASS</p>
        </div>
      </div>
      <div
        class="lp-cookie__ex tag first exclus"
        v-if="values.isPremium === false"
        @click="openExclusive"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/exclusive.png" alt="ex" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>ЭКСКЛЮЗИВНОЕ ПРЕДЛОЖЕНИЕ!</p>
          <small>ПОПРОБУЙТЕ!</small>
        </div>
      </div>
      <div class="lp-cookie__header">
        <img src="./../../assets/images/logpass/logo.png" alt="logo" />
      </div>
      <div class="lp-cookie__input">
        <div class="lp-cookie__input__mark">
          <img
            src="./../../assets/images/logpass/ex-icon__small.png"
            alt="cookie"
          />
        </div>
        <div class="lp-cookie__input__input exclusive">
          <input
            placeholder="Введите cookie"
            type="text"
            v-model="values.exclusive"
            readonly
            @click="showExclusiveMenu = !showExclusiveMenu"
          />
          <div
            class="lp-cookie__input__input__arrow"
            @click="showExclusiveMenu = !showExclusiveMenu"
          >
            <img
              src="./../../assets/images/logpass/down-arrow.png"
              alt="down"
            />
          </div>
          <div class="lp-cookie__input__input__menu" v-show="showExclusiveMenu">
            <p
              @click="
                chooseExclusive('4000 R$', values.exclusiveRate['4000robux'])
              "
            >
              4000 R$
            </p>
            <p
              @click="
                chooseExclusive('7500 R$', values.exclusiveRate['7500robux'])
              "
            >
              7500 R$
            </p>
            <p
              @click="
                chooseExclusive('22500 R$', values.exclusiveRate['22500robux'])
              "
            >
              22500 R$
            </p>
          </div>
          <div class="lp-cookie__input__input__title">
            <p>ЭКСКЛЮЗИВ</p>
          </div>
        </div>
      </div>
      <div class="lp-cookie__button ex-btn">
        <button @click="buyExclusive">ПОДТВЕРДИТЬ</button>
        <div class="lp-cookie__form__error">
          {{ buyExCodeError }}
        </div>
        <p>
          К оплате: <span>{{ values.exclusivePrice }} ₽</span>
        </p>
      </div>
    </div>
    <div
      class="lp-cookie__start"
      :class="{ 'adapt-v': mobileStage === 2, 'm-n': mobileVersion }"
      v-if="stage === 1 && showPremium === false && exclusiveShow === false"
    >
      <div class="lp-cookie__type">
        <img src="./../../assets/images/logpass/auth.svg" alt="auth" />
      </div>
      <!-- <div class="lp-cookie__ex" v-if="values.isPremium === false" @click="openExclusive">
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/exclusive.png" alt="ex">
        </div>
        <div class="lp-cookie__ex__text">
          <p>ЭКСКЛЮЗИВНОЕ ПРЕДЛОЖЕНИЕ!</p>
          <small>ПОПРОБУЙТЕ!</small>
        </div>
      </div> -->
      <!-- <div class="lp-cookie__ex tag second">
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/tag.svg" alt="tag">
        </div>
        <div class="lp-cookie__ex__text">
          <p>ОТ 8 000 РОБУКСОВ</p>
          <p>СКИДКА {{ Math.trunc((values.rate - values.discountRate) * 100) }}%!</p>
          <small>ПОПРОБУЙТЕ!</small>
        </div>
      </div> -->
      <div class="lp-cookie__header">
        <img src="./../../assets/images/logpass/logo.png" alt="logo" />
      </div>
      <div class="lp-cookie__input" v-if="mobileStage === 1">
        <div class="lp-cookie__input__mark">
          <img src="./../../assets/images/logpass/user.svg" alt="user" />
        </div>
        <div class="lp-cookie__input__input">
          <input
            placeholder="Введите никнейм"
            type="text"
            v-model="values.searchNickname"
            @input="searchUsers"
          />
          <div class="lp-cookie__input__input__title">
            <p>ИГРОВОЙ НИКНЕЙМ</p>
          </div>
        </div>
      </div>
      <div class="lp-cookie__wrapper" v-if="mobileStage === 1">
        <LoaderBar class="lp-cookie__wrapper-loader" v-if="isSearching" />
        <div class="lp-cookie__wrapper-users" v-else>
          <div
            class="lp-cookie__wrapper-users__item"
            v-for="user in values.foundUsers"
            :key="user.user_id"
            @click="selectUser(user.user_id, user.username, $event)"
          >
            <p class="lp-cookie__wrapper-users__item-title">
              {{ user.username }}
            </p>
            <img :src="user.avatar_uri" alt="" />
          </div>
          <div
            class="lp-cookie__wrapper-users-not"
            v-if="values.foundUsers.length === 0"
          >
            <p>Ничего не найдено</p>
          </div>
        </div>
      </div>
      <div class="cookie__password-wrapper">
        <div
          class="lp-cookie__input login"
          v-if="mobileVersion && mobileStage === 2"
        >
          <div class="lp-cookie__input__mark">
            <img src="./../../assets/images/logpass/user.svg" alt="user" />
          </div>
          <div class="lp-cookie__input__input">
            <input v-model="values.username" :disabled="true" />
            <div class="lp-cookie__input__input__title">
              <p>Игровой никнейм</p>
            </div>
          </div>
        </div>
        <div
          class="lp-cookie__input password"
          v-if="!mobileVersion || mobileStage === 2"
        >
          <div class="lp-cookie__input__mark">
            <img src="./../../assets/images/logpass/lock.svg" alt="user" />
          </div>
          <div class="lp-cookie__input__input">
            <input
              placeholder="Введите пароль"
              type="password"
              v-model="values.password"
            />
            <div class="lp-cookie__input__input__title">
              <p>ПАРОЛЬ</p>
            </div>
            <div class="lp-cookie__input__input__error">
              <p>{{ passwordError }}</p>
            </div>
          </div>
        </div>
        <div
          class="lp-cookie__button"
          v-if="mobileVersion && mobileStage === 1"
        >
          <button
            @click="mobileStage = 2"
            :class="{ inactive: isSelectUser === false }"
          >
            Далее
          </button>
        </div>
        <div
          class="lp-cookie__button"
          v-if="
            (!mobileVersion && mobileStage === 1) ||
            (mobileVersion && mobileStage === 2)
          "
        >
          <button
            @click="enterCode"
            :class="{ inactive: isSelectUser === false }"
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
    <div class="lp-cookie__premium" v-if="stage === 2 && !showPremium">
      <div class="lp-cookie__premium__header">
        <h2><span>+10%</span> К РОБУКСАМ!</h2>
        <p>Пользователь с Премиум подпиской Роблокс получит больше робуксов</p>
      </div>
      <div class="lp-cookie__premium__blocks">
        <router-link
          to="/premium"
          class="lp-cookie__premium__blocks__block"
          style="text-decoration: none"
        >
          <div class="lp-cookie__premium__blocks__block__image">
            <img
              src="./../../assets/images/logpass/premium.png"
              alt="premium"
            />
          </div>
          <div class="lp-cookie__premium__blocks__block__text">
            <p>КУПИТЬ</p>
            <small>PREMIUM ROBLOX</small>
          </div>
        </router-link>
        <div class="lp-cookie__premium__blocks__block" @click="skipPremium">
          <div class="lp-cookie__premium__blocks__block__image">
            <img src="./../../assets/images/logpass/sad.png" alt="sad" />
          </div>
          <div class="lp-cookie__premium__blocks__block__text">
            <p>Пропустить</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="lp-code lp-cookie__form"
      :class="{
        'animate__animated animate__zoomOut': formAnimationLeave,
        'animate__animated animate__zoomIn': formAnimationTo,
      }"
      v-if="stage === 2 && showPremium && exclusiveShow === false"
    >
      <LoaderBar v-if="buyingLoading" />
      <div class="lp-cookie__type">
        <img src="./../../assets/images/logpass/auth.svg" alt="auth" />
      </div>
      <div
        class="lp-cookie__ex tag first"
        :class="{ active: !exclusiveSelected }"
        @click="selectCommon"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/auth.svg" alt="tag" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>МЕТОД LOG+PASS</p>
        </div>
      </div>
      <div
        class="lp-cookie__ex tag second"
        :class="{ active: exclusiveSelected }"
        @click="selectExclusive"
        v-if="false"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/exclusive.png" alt="tag" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>Эксклюзивное предложение</p>
        </div>
      </div>
      <div
        class="lp-cookie__ex code second"
        v-if="values.isPremium === false"
        @click="openExclusive"
      >
        <div class="lp-cookie__ex__image">
          <img src="./../../assets/images/logpass/exclusive.png" alt="ex" />
        </div>
        <div class="lp-cookie__ex__text">
          <p>ЭКСКЛЮЗИВНОЕ ПРЕДЛОЖЕНИЕ!</p>
          <small>ПОПРОБУЙТЕ!</small>
        </div>
      </div>
      <div class="lp-cookie__form__header">
        <img src="./../../assets/images/logpass/logo.png" alt="" />
      </div>
      <div class="lp-cookie__form__input">
        <div class="lp-cookie__form__input__mark">
          <span>R$</span>
        </div>
        <div class="lp-cookie__form__input__wrapper">
          <div class="lp-cookie__form__input__wrapper__title">
            <p>КОЛ-ВО РОБУКСОВ</p>
          </div>
          <div class="lp-cookie__form__input__wrapper__current">
            <input
              type="number"
              v-model="values.currentRbx"
              :readonly="!exclusiveSelected"
            />
          </div>
          <!-- <div class="lp-cookie__form__input__wrapper__last">
            <p>+ {{ values.lastRbx.length === 0 ? 0 : values.lastRbx[values.lastRbx.length - 1] }} R$</p>
          </div>
          <div class="lp-cookie__form__input__wrapper__back">
            <img @click="backAmount" src="./../../assets/images/logpass/back.png" alt="back">
          </div> -->
        </div>
      </div>
      <div class="lp-cookie__form__error">
        {{ rbxError }}
      </div>
      <div class="lp-cookie__form__prices">
        <div class="lp-cookie__form__prices__row" v-if="!exclusiveSelected">
          <p @click="addAmount(1000)">+{{ 1000 }} R$</p>
          <p @click="addAmount(2200)">+{{ 2200 }} R$</p>
          <p @click="addAmount(3200)">+{{ 3200 }} R$</p>
        </div>
        <div class="lp-cookie__form__prices__row" v-if="!exclusiveSelected">
          <div class="lp-cookie__form__prices__row" v-if="!exclusiveSelected">
            <p @click="addAmount(-1000)">-{{ 1000 }} R$</p>
            <p @click="addAmount(-2200)">-{{ 2200 }} R$</p>
            <p @click="addAmount(-3200)">-{{ 3200 }} R$</p>
          </div>

          <div class="lp-cookie__form__prices__row" v-if="exclusiveSelected">
            <p
              @click="chooseExclusiveRange(1)"
              :class="{ active: exclusiveRange === 1 }"
            >
              8000-10000 R$
            </p>
            <p
              @click="chooseExclusiveRange(2)"
              :class="{ active: exclusiveRange === 2 }"
            >
              15000-22500 R$
            </p>
          </div>
        </div>
        <div class="lp-cookie__form__button">
          <button @click="buy">КУПИТЬ</button>
        </div>
        <div class="lp-cookie__form__error">
          {{ buyCodeError }}
        </div>
        <div class="lp-cookie__form__result">
          <!-- <p>Итого: {{ values.currentRbx }} R$ <span v-if="values.isPremium">+ {{ values.currentRbx * 0.1 }} R$</span></p> -->
          <p>Итого: {{ values.currentRbx }} R$</p>
          <p>
            К оплате:
            <span
              >{{
                (
                  values.currentRbx *
                  (values.currentRbx >= 8000
                    ? values.discountRate
                    : values.rate)
                ).toFixed(2)
              }}
              ₽</span
            >
          </p>
        </div>
        <div class="lp-cookie__form__sale">
          <img src="./../../assets/images/logpass/sale.png" alt="" />
          <p>
            ОТ 8 000 РОБУКСОВ СКИДКА
            <span
              >{{
                ((1 - values.discountRate / values.rate) * 100).toFixed(1)
              }}%!</span
            >
          </p>
        </div>
      </div>
      <div class="lp-code lp-cookie__orders" v-if="stage === 3">
        <div class="lp-cookie__orders__left">
          <svg
            width="21"
            height="36"
            viewBox="0 0 21 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z"
              fill="#069C38"
              fill-opacity="0.29"
            />
          </svg>
        </div>
        <div class="lp-cookie__orders__center">
          <div class="lp-cookie__orders__center-tab">
            <img src="../../assets/images/logpass/code.png" alt="" />
          </div>
          <div class="lp-cookie__orders__center__top">
            <p class="lp-cookie__orders__center__top__number">#1</p>
            <div class="lp-cookie__orders__center__top__logo">
              <img src="./../../assets/images/logpass/logo.png" alt="logo" />
            </div>
            <div class="lp-cookie__orders__center__top__timer">
              <img
                src="./../../assets/images/logpass/timer.svg"
                alt="timer"
                class="rotate"
              />
              <p>00:59:59</p>
            </div>
            <div class="lp-cookie__orders__center__top__status">
              <p>
                Введите код на сайте
                <a href="https://www.roblox.com/" target="_blank" class="green"
                  >roblox.com</a
                >
                для продолжения покупки!
              </p>
            </div>
            <div class="lp-cookie__orders__center__top__code">
              <p>J3MYDS</p>
            </div>
            <div class="lp-cookie__orders__center__top__table">
              <div class="lp-cookie__orders__center__top__table__header">
                <p>ИГРОВОЙ НИК</p>
                <p>КОЛ-ВО R$</p>
                <p>ИТОГО, РУБ</p>
              </div>
              <div class="lp-cookie__orders__center__top__table__content">
                <p>speelyan</p>
                <p>480 R$</p>
                <p>1 800 ₽</p>
              </div>
            </div>
          </div>
          <div class="lp-cookie__orders__center__bottom" @click="stage = 1">
            <small>+</small>
            <p>сделать еще заказ</p>
          </div>
        </div>
        <div class="lp-cookie__orders__right">
          <svg
            width="21"
            height="36"
            viewBox="0 0 21 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.09011 16.1165L16.1074 1.12576C16.354 0.877079 16.6475 0.679695 16.9708 0.544994C17.2941 0.410294 17.6409 0.340942 17.9912 0.340942C18.3414 0.340942 18.6882 0.410294 19.0115 0.544994C19.3348 0.679695 19.6283 0.877079 19.8749 1.12576C20.3691 1.62288 20.6465 2.29534 20.6465 2.99629C20.6465 3.69723 20.3691 4.3697 19.8749 4.86681L6.74148 18.1329L19.8749 31.2664C20.3691 31.7635 20.6465 32.436 20.6465 33.1369C20.6465 33.8379 20.3691 34.5103 19.8749 35.0075C19.6292 35.2582 19.3362 35.4576 19.0128 35.5942C18.6895 35.7309 18.3422 35.802 17.9912 35.8034C17.6401 35.802 17.2929 35.7309 16.9695 35.5942C16.6461 35.4576 16.3531 35.2582 16.1074 35.0075L1.09011 20.0167C0.820793 19.7683 0.60586 19.4667 0.458851 19.1311C0.311844 18.7955 0.235949 18.433 0.235949 18.0666C0.235949 17.7002 0.311844 17.3378 0.458851 17.0021C0.60586 16.6665 0.820793 16.3649 1.09011 16.1165Z"
              fill="#069C38"
              fill-opacity="0.29"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './logPassCode.scss'

import LoaderBar from '@/components/LoaderBar/LoaderBar'
import { validateRobloxPassword } from '@/utils/validate.js'

export default {
  name: 'LogPassCode',
  props: ['normalAvailable', 'exclusiveAvailable'],
  components: {
    LoaderBar
  },
  data () {
    return {
      mobileVersion: false,
      mobileStage: 1,
      formAnimationTo: false,
      formAnimationLeave: false,
      exclusiveAnimationLeave: false,
      loading: true,
      stage: 1,
      showPremium: false,
      isSearching: false,
      isSelectUser: false,
      isLogpassNormallAvailable: false,
      exclusiveShow: false,
      exclusiveSelected: false,
      showExclusiveMenu: false,
      buyingLoading: false,
      searchTimeout: null,
      buyCodeError: '',
      buyExCodeError: '',
      passwordError: '',
      rbxError: '',
      amounts: [1000],
      values: {
        searchNickname: '',
        foundUsers: [],
        userId: '',
        username: '',
        isPremium: false,
        currentRbx: 1000,
        premiumBonus: 0,
        lastRbx: [],
        rate: 0.4,
        discountRate: 0.4,
        exclusive: '4000 R$',
        exclusivePrice: 0,
        exclusiveRate: {},
        password: ''
      },
      exclusiveRange: 1,
      purchases: {
        limit: 64000,
        max: 64000,
        min: 1000,
        step: 1100
      }
    }
  },
  watch: {
    'values.currentRbx' (val) {
      if (this.exclusiveSelected) {
        if (this.exclusiveRange === 1 && val < 8000) {
          this.rbxError = 'Кол-во робуксов не может быть меньше 8000'
          return
        }

        if (this.exclusiveRange === 1 && val > 10000) {
          this.rbxError = 'Кол-во робуксов не может быть больше 10000'
          return
        }

        if (this.exclusiveRange === 2 && val < 15000) {
          this.rbxError = 'Кол-во робуксов не может быть меньше 15000'
          return
        }

        if (this.exclusiveRange === 2 && val > 22500) {
          this.rbxError = 'Кол-во робуксов не может быть больше 22500'
          return
        }
      }

      this.rbxError = ''
    }
  },
  created () {
    this.loading = true

    // fetch('https://logpass.backend-tree.com/api/v1/orders/fetch_robux_bounds')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     this.purchases = data.purchases
    //     this.values.currentRbx = data.purchases.min
    //   })
    //   .catch((err) => console.log(err))

    if (this.$route.query.rubTotal && this.$route.query.totalRobux) {
      this.values.currentRbx = Number(this.$route.query.totalRobux)
    }

    fetch('https://rates.backend-tree.com/get_rates/2')
      .then((res) => res.json())
      .then((data) => {
        this.values.exclusiveRate = data.exclusive.buyer
        this.values.exclusivePrice = data.exclusive.buyer['4000robux']
        this.values.rate = data.logpass.rate
        this.values.discountRate = data.logpass.discount_rate
        this.loading = false
        this.$store.state.showLogPassInstruction = true
      })
      .catch((err) => console.log(err))
  },
  destroyed () {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }
  },
  methods: {
    selectExclusive () {
      this.exclusiveSelected = true

      if (this.values.currentRbx < 8000) {
        this.values.currentRbx = 8000
      }

      if (this.values.currentRbx > 10000) {
        this.values.currentRbx = 10000
      }

      this.exclusiveRange = 1
    },
    selectCommon () {
      this.exclusiveSelected = false

      if (+this.values.currentRbx < 1000) {
        this.values.currentRbx = 1000
      }
    },
    chooseExclusiveRange (range) {
      this.exclusiveRange = range

      if (range === 1 && this.values.currentRbx < 8000) {
        this.values.currentRbx = 8000
      }

      if (range === 1 && this.values.currentRbx > 10000) {
        this.values.currentRbx = 10000
      }

      if (range === 2 && this.values.currentRbx < 15000) {
        this.values.currentRbx = 15000
      }

      if (range === 2 && this.values.currentRbx > 22500) {
        this.values.currentRbx = 22500
      }
    },
    areSalesAvailable (cb, sync = true) {
      if (sync) {
        this.$store.state.additionalSync.push(true)
      }

      fetch(
        'https://logpass.backend-tree.com/api/v1/orders/are_sales_available?method=logpass_normal'
      )
        .then((res) => res.json())
        .then((data) => {
          cb(data)

          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
        .catch((err) => {
          console.log(err)
          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
    },
    areExSalesAvailable (cb, sync = true) {
      if (sync) {
        this.$store.state.additionalSync.push(true)
      }

      fetch(
        'https://logpass.backend-tree.com/api/v1/orders/are_sales_available?method=logpass_exclusive'
      )
        .then((res) => res.json())
        .then((data) => {
          cb(data)

          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
        .catch((err) => {
          console.log(err)
          if (sync) {
            this.$store.state.additionalSync.pop()
          }
        })
    },
    backLogPass () {
      this.exclusiveAnimationLeave = true
      setTimeout(() => {
        if (this.normalAvailable === false && this.exclusiveShow) {
          this.stage = 1
          this.exclusiveShow = false
          this.exclusiveAnimationLeave = false
          this.formAnimationTo = true

          return
        }

        if (this.exclusiveShow) {
          this.exclusiveShow = false
          this.exclusiveAnimationLeave = false
          this.formAnimationTo = true
          return
        }

        console.log(this.stage)

        if (this.stage > 2) {
          this.stage = this.stage - 1
          this.exclusiveAnimationLeave = false
          this.formAnimationTo = true
        } else {
          this.$emit('backToMainLogPass')
        }
      }, 500)
    },
    openExclusive () {
      this.exclusiveAnimationLeave = false
      this.formAnimationLeave = true
      setTimeout(() => {
        this.exclusiveShow = true
        this.formAnimationLeave = false
      }, 300)
    },
    chooseExclusive (text, price) {
      this.values.exclusive = text
      this.values.exclusivePrice = price
      this.showExclusiveMenu = false
    },
    buyExclusive () {
      if (this.$cookies.get('access_token') === null) {
        this.$store.state.isAuth = false
      }

      if (this.$store.state.isAuth === false) {
        this.$store.commit('toggleAuthShowState')
        return
      }

      this.areExSalesAvailable((data) => {
        if (data.are_sales_available === false) {
          this.$router.push('/')
          this.$store.state.salesNotification = true

          setTimeout(() => {
            this.$store.state.salesNotification = false
          }, 5000)
          return
        }

        const rbxAmount = Number(this.values.exclusive.split(' ')[0])

        const requestBody = JSON.stringify({
          username: this.values.username,
          cookie: null,
          is_quick: true,
          robux_amount: rbxAmount,
          method: 'logpass_exclusive',
          is_paid:
            this.$store.state.account.rubBalance >= this.values.exclusivePrice
        })

        if (rbxAmount % 80 !== 0) {
          return
        }

        const requestHeaders = new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        })

        const requestParams = {
          method: 'POST',
          headers: requestHeaders,
          body: requestBody
        }

        this.buyingLoading = true

        fetch(
          'https://logpass.backend-tree.com/api/v1/orders/create_order',
          requestParams
        )
          .then((res) => res.json())
          .then((data) => {
            this.buyingLoading = false

            if (data.message === 'Forbidden Error') {
              this.backLogPass()
              return
            }

            if (data.is_paid === false) {
              this.$store.commit('setPaymentsData', {
                order: data,
                methodType: 2
              })
              return
            }

            if (data._id) {
              this.$emit('goToOrders')
            }
          })
          .catch((err) => console.log(err))
      }, false)
    },
    enterCode () {
      this.passwordError = ''

      if (!this.values.userId) {
        return
      }

      if (validateRobloxPassword(this.values.password) === false) {
        this.passwordError = 'Пароль неверный'
        return
      }

      fetch(
        `https://logpass.backend-tree.com/api/v1/roblox/check_premium?user_id=${this.values.userId}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (this.exclusiveAvailable && this.normalAvailable === false) {
            this.exclusiveShow = true
          }

          this.values.isPremium = data.is_premium
          this.stage = 2
          this.showPremium = true

          // const min = data.is_premium ? 880 : 800

          // this.purchases.min = min
          // this.purchases.step = min
          // this.values.currentRbx = min
        })
        .catch((err) => console.log(err))
    },
    skipPremium () {
      this.stage = 2
      this.showPremium = true
    },
    searchUsers () {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        if (this.values.searchNickname.length < 3) {
          return
        }

        this.isSearching = true

        this.isSelectUser = false

        fetch(
          `https://logpass.backend-tree.com/api/v1/roblox/search_users?keyword=${this.values.searchNickname}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.isSearching = false
            this.values.foundUsers = data.found_users
          })
          .catch((err) => console.log(err))
      }, 1000)
    },
    buy () {
      if (this.$cookies.get('access_token') === null) {
        this.$store.state.isAuth = false
      }

      if (this.$store.state.isAuth === false) {
        this.$store.commit('toggleAuthShowState')
        return
      }

      this.areSalesAvailable((data) => {
        if (data.are_sales_available === false) {
          this.$router.push('/')
          this.$store.state.salesNotification = true

          setTimeout(() => {
            this.$store.state.salesNotification = false
          }, 5000)
          return
        }

        // const rbxAmount = this.values.currentRbx + (this.values.isPremium ? this.values.currentRbx * 0.1 : 0)
        const rbxAmount = this.values.currentRbx

        if (rbxAmount > this.purchases.max) {
          return
        }

        const requestBody = JSON.stringify({
          username: this.values.username,
          cookie: null,
          is_quick: true,
          robux_amount: rbxAmount,
          method: 'logpass_normal',
          is_paid:
            this.$store.state.account.rubBalance >=
            parseFloat(
              (
                this.values.currentRbx *
                (this.values.currentRbx >= 8000
                  ? this.values.discountRate
                  : this.values.rate)
              ).toFixed(2)
            ),
          password: this.values.password
        })

        const requestHeaders = new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$cookies.get('access_token')}`
        })

        const requestParams = {
          method: 'POST',
          headers: requestHeaders,
          body: requestBody
        }

        this.buyingLoading = true

        fetch(
          'https://logpass.backend-tree.com/api/v1/orders/create_order',
          requestParams
        )
          .then((res) => res.json())
          .then((data) => {
            this.buyingLoading = false

            if (data.message === 'Forbidden Error') {
              this.buyCodeError =
                'В данный момент невозможно совершить покупку'
              return
            }

            if (data.is_paid === false) {
              this.$store.commit('setPaymentsData', {
                order: data,
                methodType: 2,
                isPremium: false
              })
              return
            }

            if (data._id) {
              this.$emit('goToOrders')
            }
          })
          .catch((err) => console.log(err))
      }, false)
    },
    selectUser: function (userId, username, event) {
      this.values.userId = userId
      this.values.username = username

      if (this.isSelectUser) {
        const user = document.querySelector(
          '.lp-cookie__wrapper-users__item.selectUser'
        )
        user.classList.remove('selectUser')
      }

      const target = event.target.closest('.lp-cookie__wrapper-users__item')
      target.classList.add('selectUser')
      this.isSelectUser = true
    },
    addAmount (amount) {
      if (amount < 0) {
        let indexToDel = -1

        const found = this.amounts.find((a, index) => {
          if (a === Math.abs(amount)) {
            indexToDel = index
          }

          return a === Math.abs(amount)
        })

        if (!found) {
          return
        }

        console.log(found, indexToDel)

        this.amounts.splice(indexToDel, 1)
        console.log(this.amounts)
      } else {
        this.amounts.push(amount)
      }

      if (this.values.currentRbx + amount < this.purchases.min) {
        this.values.currentRbx = this.purchases.min
        this.amounts = [this.purchases.min]
        return
      } else if (this.values.currentRbx + amount > this.purchases.max) {
        this.values.currentRbx = this.purchases.max
        return
      }

      this.values.currentRbx = +this.values.currentRbx

      this.values.currentRbx += amount
      this.values.lastRbx.push(amount)
    },
    backAmount () {
      if (this.values.lastRbx.length !== 0) {
        this.values.currentRbx -= this.values.lastRbx.pop()
      }
    }
  },
  mounted () {
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (this.isLoaded = true), 1000)
    this.mobileVersion =
      document.body.clientWidth <= 961 || document.body.clientHeight <= 695
    window.addEventListener('resize', () => {
      this.mobileVersion =
        document.body.clientWidth <= 961 || document.body.clientHeight <= 695
    })
  }
}
</script>
