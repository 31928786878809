<template>
  <div class="success fastpass__wrapper">
    <div class="fastpass-modal">
      <div class="fastpass-modal__logo">
        <img src="../../../assets/images/transfer/logo.svg" alt="">
      </div>
      <LoaderBar class="fastpassLoader" v-if="!isLoaded"/>
      <div class="fastpass-modal__successful" v-else>
        <img
          v-if="status"
          src="./../../../assets/images/transfer/allow.svg"
          alt="allow"
        >
        <img
          v-if="status === false && isLowBalance === false"
          src="./../../../assets/images/transfer/deny.svg"
          alt="deny"
        >
        <img
          v-if="status === false && isLowBalance === true"
          src="./../../../assets/images/transfer/wait.svg"
          alt="wait"
        >
        <p v-if="status === false" class="error">{{ errorMessage }}</p>
        <button v-if="status === false && isLowBalance === false" class="error-btn" @click="goBack">
          Повторить еще раз
        </button>
        <button v-if="status === false && isLowBalance === true" class="wait-btn" @click="buyOrder">
          Оплатить заказ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import './paymentSuccessful.scss'

import LoaderBar from '@/components/LoaderBar/LoaderBar'

export default {
  name: 'TransferSuccessful',
  props: ['data'],
  components: {
    LoaderBar
  },
  data () {
    return {
      isLoaded: false,
      order: null,
      status: null,
      errorMessage: '',
      isLowBalance: false
    }
  },
  mounted () {
    const requestUrl = `https://pending.backend-tree.com/make_order?plugin=true&asset_id=${this.data.assetId}&roblox_user_id=${this.data.robloxUserId}&robux_amount=${this.data.totalRobux}&username=${this.data.nickname}`
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch(requestUrl, requestParams)
      .then((res) => res.json())
      .then((data) => {
        this.isLoaded = true
        this.status = data.status

        if (data.status) {
          this.order = data.order

          window.dataLayer.push({
            currencyCode: 'RUB',
            event: 'transactionComplete',
            transactionId: '1234',
            transactionAffiliation: 'backend-tree.com',
            transactionTotal: this.data.totalRobux,
            transactionProducts: [{
              sku: 'rbx',
              name: 'Робукс',
              category: 'Метод fastpass',
              price: this.data.totalRobux,
              quantity: 1
            }]
          })

          setTimeout(() => {
            this.$emit('changeModal', {
              modal: 'OrderInfo',
              data: [this.order]
            })
          }, 800)
        } else if (data.data.includes(4)) {
          this.errorMessage = 'Недостаточно средств на балансе'
          this.isLowBalance = true
        } else if (data.data.includes(5)) {
          this.errorMessage = 'Вы не авторизованы'
        } else if (data.data.includes(1)) {
          this.errorMessage = 'Ошибка на сервере'
        } else if (data.data.includes(6)) {
          this.errorMessage = 'Количество робаксов недоступно для покупки'
        } else if (data.data.includes(7)) {
          this.errorMessage = 'Проверьте настройки Plugin!'
        }
      })
      .catch((err) => console.log(err))
  },
  methods: {
    goBack () {
      this.$emit('changeModal', {
        modal: 'MainForm'
      })
    },
    buyOrder () {
      this.isLoaded = false
      const requestUrl = `https://pending.backend-tree.com/make_order?plugin=true&asset_id=${this.data.assetId}&roblox_user_id=${this.data.robloxUserId}&robux_amount=${this.data.totalRobux}&username=${this.data.nickname}&flag=true`
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(requestUrl, requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoaded = true
          if (data.status) {
            this.$store.commit('setPaymentsData', { order: data.order, methodType: 1 })
          }
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>
